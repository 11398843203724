import { FlyToInterpolator, AmbientLight } from '@deck.gl/core';
import { IconLayer } from '@deck.gl/layers';
import { ViewState } from 'react-map-gl';
import { Layer as MapboxGLLayer } from 'mapbox-gl';
import { Coordinates } from 'viewport-mercator-project';
import { DataPoint } from '../models/interfaces/DataPoint';
import SunLight from '../utils/lighting/Sunlight';
import MoonLight from '../utils/lighting/MoonLight';

const WINDOW_ENV = (window as any).env;

export const ionAssetId = WINDOW_ENV?.ION_ASSET ?? 'empty';
export const ionAccessToken = WINDOW_ENV?.ION_ACCESS_TOKEN ?? 'empty';

export interface MapConfig extends MapConstraintsConfig {
  initialViewState: ViewState;
  pitch: number;
  threeDPitch: number;
  token: string;
  mapStyle: {
    light: string;
    dark?: string;
  };
}

export interface MapConstraintsConfig {
  bounds: number[][];
  minZoom: number;
  maxZoom: number;
  geoBoundsPadding: number;
}

export interface NeighborhoodLabel {
  label: string;
  coordinates: Coordinates;
}

export const MAP_CONFIG: MapConfig = {
  token: String(WINDOW_ENV?.MAPBOX_ACCESS_TOKEN || ''),
  initialViewState: {
    longitude: 0,
    latitude: 0,
    zoom: 0,
    pitch: Number(WINDOW_ENV?.MAP_PITCH),
    bearing: 0,
    altitude: 1.5,
  },
  bounds: [
    [0, 0],
    [0, 0],
  ],
  minZoom: 0,
  maxZoom: Number(WINDOW_ENV?.MAP_MAX_ZOOM),
  geoBoundsPadding: Number(WINDOW_ENV?.MAP_GEO_BOUNDS_PADDING),
  pitch: Number(WINDOW_ENV?.MAP_PITCH),
  threeDPitch: Number(WINDOW_ENV?.MAP_THREED_PITCH),
  mapStyle: {
    light: 'mapbox://styles/imec-apt/cknsujl9s0nkn17r8ljmvyjg9',
    dark: 'mapbox://styles/imec-apt/ck6955k7l0juu1ipeqe9qi76m',
  },
};

export const neighborhoodLabels: NeighborhoodLabel[] = [
  {
    label: 'Harmonie',
    coordinates: [4.4064925, 51.205234],
  },
  {
    label: 'Kievietwijk',
    coordinates: [4.424587, 51.213029],
  },
  {
    label: 'Eilandje',
    coordinates: [4.409696, 51.23483],
  },
  {
    label: 'Schipperskwartier',
    coordinates: [4.404447, 51.225641],
  },
  {
    label: 'Universiteitsbuurt',
    coordinates: [4.4053379, 51.2222935],
  },
  {
    label: 'Seef Hoek',
    coordinates: [4.427147, 51.224084],
  },
  {
    label: 'Sint-Andries',
    coordinates: [4.398415, 51.213232],
  },
  {
    label: 'Theaterbuurt',
    coordinates: [4.407846, 51.214344],
  },
  {
    label: 'Het Zuid',
    coordinates: [4.391325, 51.208246],
  },
  {
    label: 'Brederode',
    coordinates: [4.396876, 51.202986],
  },
  {
    label: 'Groen Kwartier',
    coordinates: [4.419503, 51.202059],
  },
  {
    label: 'Berchem',
    coordinates: [4.418693, 51.195375],
  },
  {
    label: 'Diamant',
    coordinates: [4.417456, 51.214173],
  },
  {
    label: 'Zurenborg',
    coordinates: [4.429148, 51.207014],
  },
  {
    label: 'Borgerhout',
    coordinates: [4.436783, 51.222521],
  },
];

export const SMOOTH_TRANSITION_PROPS = {
  transitionDuration: 500,
  transitionEasing: (t: number) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
};

export const LINEAR_TRANSITION_PROPS = {
  transitionDuration: 300,
};

export const NO_TRANSITION_PROPS = {
  transitionDuration: 0,
};

export const FLY_TO_LOCATION_TRANSITION = {
  transitionDuration: 2000,
  transitionInterpolator: new FlyToInterpolator(),
};

export const SEARCH_RESULT_LAYER = {
  stroked: true,
  filled: true,
  lineWidthUnits: 'pixels',
  lineJointRounded: true,
  getFillColor: [112, 146, 190, 180],
  getLineColor: [171, 219, 231],
  getLineWidth: 3,
  _subLayerProps: {
    points: {
      type: IconLayer,
      iconAtlas: 'images/icon-atlas.png',
      iconMapping: 'images/icon-mapping.json',
      getIcon: () => 'locationPin',
      getColor: [171, 219, 231],
      getSize: 64,
    },
  },
};

const getPosition = ({ location: position }: DataPoint): Coordinates => [position[0], position[1]];

export const DATA_POINTS_LAYER = {
  getPosition,
  radiusScale: 2,
  radiusMinPixels: 2,
  getRadius: 1,
};

export const HEATMAP_LAYER = {
  getPosition,
  colorRange: [
    [255, 255, 178],
    [254, 217, 118],
    [254, 178, 76],
    [253, 141, 60],
    [240, 59, 32],
    [189, 0, 38],
  ],
  opacity: 0.5,
};

export const AMBIENT_LIGHT = new AmbientLight({
  color: [255, 255, 255],
});

// Can be replaced with a DeckGL sunlight for positioning/rotation based on the day and time
export const DIRECTIONAL_LIGHT = new SunLight({
  timestamp: Date.now(),
  color: [255, 241, 224],
  intensity: 1,
  // _shadow: true, // DeckGL has basic shadow support
});

// Can be replaced with a DeckGL sunlight for positioning/rotation based on the day and time
export const MOON_LIGHT = new MoonLight({
  timestamp: Date.now(),
  color: [171, 196, 255],
  intensity: 0.4,
  // _shadow: true, // DeckGL has basic shadow support
});

const minimumBuildingHeight = 15;
export const mapboxBuildingsLayer: MapboxGLLayer = {
  id: '3d-buildings',
  source: 'composite',
  'source-layer': 'building',
  filter: ['==', 'extrude', 'true'],
  type: 'fill-extrusion',
  minzoom: 12,
  paint: {
    'fill-extrusion-color': '#b1b5b9',
    // see: https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#paint-fill-extrusion-fill-extrusion-height
    // and: https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#get
    'fill-extrusion-height': ['max', ['get', 'height'], minimumBuildingHeight],
    'fill-extrusion-base': ['get', 'min_height'],
    'fill-extrusion-opacity': 0.6,
  },
};
