import LanguageAction, { LanguageActionType } from 'actions/language/LanguageAction';
import { DEFAULT_LANGUAGE, AVAILABLE_LANGUAGES } from 'config/language';
import { Language } from '../models/interfaces/languages/Language';

export interface LanguageState {
  currentLanguage: Language;
  languages: Language[];
}

const initialState: LanguageState = {
  currentLanguage: DEFAULT_LANGUAGE,
  languages: AVAILABLE_LANGUAGES,
};

const reducer = (state = initialState, action: LanguageActionType): LanguageState => {
  switch (action.type) {
    case LanguageAction.Types.CHANGED: {
      return {
        ...state,
        currentLanguage: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
