const WINDOW_ENV = (window as any).env;

export const debugControlsEnabled = WINDOW_ENV?.DEBUG_CONTROLS_ENABLED === 'true' || false;
export const customCFVisualization = {
  enabled: WINDOW_ENV?.CUSTOM_CITYFLOWS_VISUALIZATION_ENABLED === 'true' || false,
  layerId: 999,
  visualizationId: 99,
};
export const blobStorage = {
  containerName: WINDOW_ENV?.AZURE_CITYFLOWS_BLOB_CONTAINER,
};
