import { Action } from 'redux';
import LayerType from '../../models/types/LayerType';
import { Layer } from '../../models/interfaces/api/Layer';
import { CachedData } from '../../models/interfaces/api/CachedData';

enum Layers {
  // multiple layers
  GET = 'layers/GET',
  GET_COMPLETED = 'layers/GET_COMPLETED',
  GET_FAILED = 'layers/GET_FAILED',
  // single datalayer
  GET_DETAIL = 'layer/GET_DETAIL',
  GET_DETAIL_COMPLETED = 'layer/GET_DETAIL_COMPLETED',
  GET_DETAIL_FAILED = 'layer/GET_DETAIL_FAILED',
  GET_DATA = 'layer/GET_DATA',
  GET_DATA_COMPLETED = 'layer/GET_DATA_COMPLETED',
  GET_DATA_ALREADY_CACHED = 'layer/GET_DATA_ALREADY_CACHED',
  GET_DATA_FAILED = 'layer/GET_DATA_FAILED',
  GET_CUSTOM_DATA = 'custom-layer/GET_CUSTOM_DATA',
}

export interface GetLayersAction {
  type: typeof Layers.GET;
  payload: LayerType;
}

export interface GetLayersCompletedAction {
  type: typeof Layers.GET_COMPLETED;
  payload: { layers: Layer[]; layerType: LayerType };
}

export interface GetLayersFailedAction {
  type: typeof Layers.GET_FAILED;
  payload: Error;
  error: boolean;
}

export interface GetLayerDataAction {
  type: typeof Layers.GET_DATA;
  payload: {
    layerId: number;
    visualizationId: number;
    force?: boolean;
  };
}

export interface GetLayerDataCompletedAction {
  type: typeof Layers.GET_DATA_COMPLETED;
  payload: {
    layerId: number;
    visualizationId: number;
    data: CachedData;
    cacheExpirationDate: Date;
  };
}

export interface GetLayerDataAlreadyCachedAction {
  type: typeof Layers.GET_DATA_ALREADY_CACHED;
  payload: {
    layerId: number;
    visualizationId: number;
  };
}

export interface GetLayerDataFailedAction {
  type: typeof Layers.GET_DATA_FAILED;
  payload: {
    layerId: number;
    visualizationId: number;
    error: Error;
  };
}

export interface GetCustomLayerDataAction {
  type: typeof Layers.GET_CUSTOM_DATA;
  payload: {
    layerId: number;
    visualizationId: number;
    data: any;
  };
}

export type LayersActionType =
  | GetLayersAction
  | GetLayersCompletedAction
  | GetLayersFailedAction
  | GetLayerDataAction
  | GetLayerDataCompletedAction
  | GetLayerDataAlreadyCachedAction
  | GetLayerDataFailedAction
  | GetCustomLayerDataAction;

export class LayersAction implements Action<Layers> {
  type: Layers;

  static Types = Layers;

  constructor(type: Layers) {
    this.type = type;
  }

  static getLayers = (layerType: LayerType): GetLayersAction => ({
    type: Layers.GET,
    payload: layerType,
  });

  static getLayersCompleted = (
    layers: Layer[],
    layerType: LayerType,
    cacheExpirationDate: Date
  ): GetLayersCompletedAction => ({
    type: Layers.GET_COMPLETED,
    payload: { layers, layerType },
  });

  static getLayersFailed = (error: Error): GetLayersFailedAction => ({
    type: Layers.GET_FAILED,
    payload: error,
    error: true,
  });

  static getLayerData = (layerId: number, visualizationId: number, force?: boolean): GetLayerDataAction => ({
    type: Layers.GET_DATA,
    payload: {
      layerId,
      visualizationId,
      force,
    },
  });

  static getLayerDataCompleted = (
    layerId: number,
    visualizationId: number,
    data: CachedData,
    cacheExpirationDate: Date
  ): GetLayerDataCompletedAction => ({
    type: Layers.GET_DATA_COMPLETED,
    payload: {
      layerId,
      visualizationId,
      data,
      cacheExpirationDate,
    },
  });

  static getLayerDataAlreadyCached = (layerId: number, visualizationId: number): GetLayerDataAlreadyCachedAction => ({
    type: Layers.GET_DATA_ALREADY_CACHED,
    payload: {
      layerId,
      visualizationId,
    },
  });

  static getLayerDataFailed = (layerId: number, visualizationId: number, error: Error): GetLayerDataFailedAction => ({
    type: Layers.GET_DATA_FAILED,
    payload: {
      layerId,
      visualizationId,
      error,
    },
  });

  static getCustomLayerData = (layerId: number, visualizationId: number, data: any): GetCustomLayerDataAction => ({
    type: Layers.GET_CUSTOM_DATA,
    payload: {
      layerId,
      visualizationId,
      data,
    },
  });
}

export default LayersAction;
