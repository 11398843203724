import { uniqBy as _uniqBy } from 'lodash';
import TagsAction, { TagsActionType } from '../actions/tags/TagsAction';
import CarouselSearchAction, { CarouselSearchActionType } from '../actions/search/CarouselSearchAction';
import { Tag } from '../models/interfaces/Tag';

export interface TagsState {
  tags: Tag[];
  loading: boolean;
  searchLoading: boolean;
  selectedTag: number | null;
}

const initialState: TagsState = {
  tags: [],
  loading: false,
  searchLoading: false,
  selectedTag: null,
};

const reducer = (state = initialState, action: CarouselSearchActionType | TagsActionType): TagsState => {
  switch (action.type) {
    case TagsAction.Types.GET:
      return {
        ...state,
        loading: true,
      };
    case TagsAction.Types.GET_COMPLETED:
      return {
        ...state,
        tags: action.payload,
        loading: false,
      };
    case TagsAction.Types.GET_FAILED:
      return {
        ...state,
        loading: false,
      };
    case TagsAction.Types.SET_FILTER:
      return {
        ...state,
        selectedTag: action.payload,
      };
    case TagsAction.Types.CLEAR_FILTER:
      return {
        ...state,
        selectedTag: null,
      };
    case CarouselSearchAction.Types.STARTED:
      return { ...state, searchLoading: true };
    case CarouselSearchAction.Types.COMPLETED: {
      const { layers } = action.payload;
      const getTags = layers.map((layer) => layer.tags);
      const tags = _uniqBy(
        getTags.reduce((acc, current) => [...acc, ...current], []),
        'id'
      );
      return {
        ...state,
        tags,
        searchLoading: false,
      };
    }
    case CarouselSearchAction.Types.FAILED:
      return { ...state, searchLoading: false };
    default:
      return state;
  }
};

export default reducer;
