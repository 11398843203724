import { call, put } from 'redux-saga/effects';
import SuggestionsAction, { GetSuggestionsAction } from '../../actions/search/SuggestionsAction';
import { searchSuggestion } from '../../api/search';

export function* searchSuggestionsSaga(action: GetSuggestionsAction) {
  try {
    const response = yield call(searchSuggestion, action.payload);
    yield put(SuggestionsAction.getSuggestionsCompleted(response));
  } catch (error) {
    yield put(SuggestionsAction.getSuggestionsFailed(error));
  }
}

export default searchSuggestionsSaga;
