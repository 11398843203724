import i18n from 'i18n';
import moment from 'moment';
import { parseInterval } from 'utils/timeUtils';
import { createTimeInterval } from './presetTimeIntervals';
import DateRangeType, { DateRangeItem } from '../models/types/DateRangeType';

const WINDOW_ENV = (window as any).env;
const dateRange: string | undefined = WINDOW_ENV?.DEFAULT_DATE_RANGE;

const { length, unit } = parseInterval(dateRange);
export const defaultDateRange = createTimeInterval(unit, length);

const createDateRange = (
  title: string,
  start: Date,
  end: Date,
  type: DateRangeType,
  shortCode: string,
  duration?: number
): DateRangeItem => {
  return {
    title: i18n.t(`globalTimeFilter.dateRange.presets.${title}`),
    start,
    end,
    type,
    shortCode: `${duration}${i18n.t(`globalTimeFilter.dateRange.shortcodes.${shortCode}`)}`,
  };
};

const options = [
  {
    title: 'today',
    start: moment().startOf('day').toDate(),
    end: moment().endOf('day').toDate(),
    duration: 1,
    type: DateRangeType.PRESET,
    shortCode: `day`,
  },
  {
    title: 'yesterday',
    start: moment().startOf('day').subtract(1, 'days').toDate(),
    end: moment().endOf('day').subtract(1, 'days').toDate(),
    duration: 1,
    type: DateRangeType.PRESET,
    shortCode: 'day',
  },
  {
    title: 'currentWeek',
    start: moment().startOf('isoWeek').toDate(),
    end: moment().endOf('isoWeek').toDate(),
    duration: 1,
    type: DateRangeType.PRESET,
    shortCode: 'week',
  },
  {
    title: 'previousWeek',
    start: moment().startOf('isoWeek').subtract(1, 'week').toDate(),
    end: moment().endOf('isoWeek').subtract(1, 'week').toDate(),
    duration: 1,
    type: DateRangeType.PRESET,
    shortCode: 'week',
  },
  {
    title: 'currentMonth',
    start: moment().startOf('month').toDate(),
    end: moment().endOf('month').toDate(),
    duration: 1,
    type: DateRangeType.PRESET,
    shortCode: 'month',
  },
  {
    title: 'previousMonth',
    start: moment().startOf('month').subtract(1, 'month').toDate(),
    end: moment().endOf('month').subtract(1, 'month').toDate(),
    duration: 1,
    type: DateRangeType.PRESET,
    shortCode: 'month',
  },
  {
    title: 'currentSixMonths',
    start: moment().startOf('month').subtract(5, 'month').toDate(),
    end: moment().endOf('month').toDate(),
    duration: 6,
    type: DateRangeType.PRESET,
    shortCode: 'month',
  },
  {
    title: 'currentYear',
    start: moment().startOf('year').toDate(),
    end: moment().endOf('year').toDate(),
    duration: 1,
    type: DateRangeType.PRESET,
    shortCode: 'year',
  },
  {
    title: 'previousYear',
    start: moment().startOf('year').subtract(1, 'year').toDate(),
    end: moment().endOf('year').subtract(1, 'year').toDate(),
    duration: 1,
    type: DateRangeType.PRESET,
    shortCode: 'year',
  },
  {
    title: 'customRange',
    start: moment().add(1, 'days').toDate(),
    end: moment().add(1, 'days').toDate(),
    type: DateRangeType.CUSTOM,
    shortCode: 'custom',
  },
];

const dateRangeData: Array<DateRangeItem> = options.map((value) => {
  return createDateRange(value.title, value.start, value.end, value.type, value.shortCode, value.duration);
});

export default dateRangeData;
