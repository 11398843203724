import i18next from 'i18next';
import { duration } from 'moment';
import { TimeInterval, TimeIntervalUnit } from '../models/types/TimeInterval';

export const presetIntervalOptions = [
  { unitType: 'minute', unitValue: 1 },
  { unitType: 'minute', unitValue: 5 },
  { unitType: 'minute', unitValue: 15 },
  { unitType: 'minute', unitValue: 30 },
  { unitType: 'hour', unitValue: 1 },
  { unitType: 'hour', unitValue: 4 },
  { unitType: 'hour', unitValue: 12 },
  { unitType: 'day', unitValue: 1 },
  { unitType: 'week', unitValue: 1 },
  { unitType: 'month', unitValue: 1 },
  { unitType: 'month', unitValue: 3 },
  { unitType: 'year', unitValue: 1 },
];

export const createTimeInterval = (timeUnit: TimeIntervalUnit, length: number): TimeInterval => {
  const shortCode = i18next.t(`globalTimeFilter.timeIntervals.presets.short_codes.${timeUnit}`);
  return {
    title: duration(length, timeUnit).humanize(),
    timeUnit,
    length,
    shortCode: `${length}${shortCode}`,
  };
};

const presetTimeIntervals: Array<TimeInterval> = presetIntervalOptions.map((opt) => {
  return createTimeInterval(opt.unitType as TimeIntervalUnit, opt.unitValue);
});

export const maxAmountOfIntervals = 1000;

export default presetTimeIntervals;
