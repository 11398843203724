import { Action } from 'redux';
import LayerType from '../../models/types/LayerType';

enum Toolbar {
  SET_SELECTED_LAYER_TYPE = 'toolbar/SET_SELECTED_LAYER_TYPE',
  CLEAR_SELECTED_LAYER_TYPE = 'toolbar/CLEAR_SELECTED_LAYER_TYPE',
}

export interface SetSelectedToolbarLayerTypeAction {
  type: typeof Toolbar.SET_SELECTED_LAYER_TYPE;
  payload: LayerType;
}

export interface ClearSelectedToolbarLayerTypeAction {
  type: typeof Toolbar.CLEAR_SELECTED_LAYER_TYPE;
}

export type ToolbarActionType = SetSelectedToolbarLayerTypeAction | ClearSelectedToolbarLayerTypeAction;

export class ToolbarAction implements Action<Toolbar> {
  type: Toolbar;

  static Types = Toolbar;

  constructor(type: Toolbar) {
    this.type = type;
  }

  static setSelectedToolbarLayerTypeAction = (layerType: LayerType): SetSelectedToolbarLayerTypeAction => ({
    type: Toolbar.SET_SELECTED_LAYER_TYPE,
    payload: layerType,
  });

  static clearSelectedToolbarLayerTypeAction = (): ClearSelectedToolbarLayerTypeAction => ({
    type: Toolbar.CLEAR_SELECTED_LAYER_TYPE,
  });
}

export default ToolbarAction;
