import { css } from 'emotion';
import { Color } from 'csstype';
import { lighten, darken, transparentize } from 'polished';

import { cardStyle, colors, boxShadow, rounded, fontSize, fontFamily, transition, boxShadowBlue } from 'styles';
import { bodyStyle } from './typography';

export const layerListWidth = 70;
const layerListSpacing = 8;
const layerIconWidth = layerListWidth - layerListSpacing * 2;

export const flyoutButtonToggleStyle = css`
  position: relative;
  display: flex;
  padding: 0.25rem 1rem;
  margin: 0.25rem 0.5rem;
  border: none;
  border-radius: 0.5rem;
  font-size: ${fontSize.sm};
  background-color: ${colors.white};
  box-shadow: ${boxShadow.sm};
  transition: ${transition};
  user-select: none;
  cursor: pointer;
  outline: none;
  > .icon {
    border: 1px solid transparent;
    transition: ${transition};
  }
  > .text {
    color: ${colors.gray[900]};
    margin-left: 0.25rem;
    line-height: 2rem;
    text-transform: capitalize;
  }
  &.active {
    background-color: ${colors.primary.default};
    box-shadow: ${boxShadowBlue};
    > .text {
      color: ${colors.white};
    }
  }
  &:not(.active) {
    &:hover {
      background-color: ${colors.gray[300]};
      > .icon {
        box-shadow: ${boxShadowBlue};
      }
      > .text {
        color: ${colors.white};
      }
    }
  }
`;

export const flyoutButtonToggleWrapperStyle = css`
  display: flex;
  flex-wrap: wrap;
  border: none;
  justify-content: justify-left;
  width: 100%;
  padding: 0px 0.25rem;
  margin-bottom: 1rem;
  z-index: 2;
`;

export const dockWrapperStyle = css`
  position: fixed;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 2rem 0;
  flex-basis: 0;
  background: transparent !important;
  z-index: 20;
`;

// negative margin added to keep the total width a round number
export const dockStyle = css`
  height: 100%;
  ${cardStyle};
  border-radius: 0 0.5rem 0.5rem 0;
  border-left-width: 0;
  &.ant-layout-sider {
    background: ${colors.white};
    box-sizing: content-box;
  }
`;

export const triggerStyle = css`
  ${cardStyle};
  width: 10px;
  height: 40px;
  padding: 0;
  margin: 0 0 0 0.125rem;
  &:focus,
  &:hover {
    border-color: ${colors.gray[300]};
  }
`;

export const contentStyle = css`
  font-family: ${fontFamily};
  font-size: ${fontSize.md};
  height: 100%;
  border-left: 1px solid ${colors.gray[300]};
  margin-left: ${layerListWidth}px;
  background: transparent;
`;

export const headerStyle = css`
  margin-bottom: 14px;
  display: flex;
  flex-direction: column;
  height: auto;
  line-height: normal;
  padding: 0;
  background: white;
  border-top-right-radius: 0.5rem;
  h1,
  h2 {
    text-transform: capitalize;
  }
`;

export const titleStyle = css`
  font-family: ${fontFamily};
  margin: 1.75rem 0 0.75rem ${layerIconWidth + layerListSpacing * 2}px;
  font-weight: 500;
  font-size: ${fontSize.xl};
  color: ${colors.gray[800]};
`;

export const subtitleStyle = css`
  margin: 0.25rem 0 0 2rem;
  font-weight: 400;
  font-size: ${fontSize.md};
  color: ${colors.gray[800]};
`;

export const tabStyle = css`
  display: flex;
  height: 100%;
  flex-direction: column;
  z-index: 2;
  .ant-tabs-bar {
    border-bottom-color: ${colors.gray[300]};
    margin: 0;
  }
  .ant-tabs-tab {
    font-family: ${fontFamily};
    color: ${colors.gray[900]};
    text-transform: uppercase;
    text-align: center;
    margin: 0 30px;
    padding: 0;
    line-height: 2em;
    &:hover {
      color: ${colors.gray[900]};
      font-weight: bold;
    }
  }
  .ant-tabs-content {
    flex-grow: 1;
    overflow-y: auto;
  }
  .ant-tabs-nav-wrap {
    margin-bottom: 0;
  }
  .ant-tabs-tab-active {
    font-weight: bold;
    &::after {
      display: block;
      content: '';
      border: 2px solid ${colors.primary.default};
      border-radius: ${rounded.xs};
      margin: 0 -5px;
    }
  }
`;

export const tabBarContent = css`
  padding: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const dropZone = (isExpanded: boolean, color: Color) => css`
  display: flex;
  position: relative;
  outline: none;
  margin: ${layerListSpacing}px;
  margin-top: 0;
  padding-left: ${isExpanded ? `1rem` : `0px`};
  padding: ${isExpanded ? 'original' : '0px'};
  border: 1px solid ${color};
  border-style: dashed;
  justify-content: ${isExpanded ? 'original' : 'center'};
  width: ${isExpanded ? layerIconWidth * 2 + layerListSpacing * 2 : layerIconWidth}px;
  height: ${layerIconWidth}px;
  flex-shrink: 0;
  background-color: ${colors.white};
  border-radius: ${rounded.xxl};
  box-shadow: 0 2px 10px 0px ${transparentize(0.5, color)};
  align-items: center;
  path {
    fill: ${colors.gray[800]};
  }
`;

export const layerButtonToggle = (isExpanded: boolean, color: Color = colors.gray[900]) => css`
  display: flex;
  position: relative;
  outline: none;
  margin: ${layerListSpacing}px;
  margin-top: 0;
  padding: 0;
  padding-right: ${isExpanded ? `1rem` : `0`};
  width: ${isExpanded ? `${layerIconWidth * 2 + layerListSpacing * 2}px` : `${layerIconWidth}px`};
  height: ${layerIconWidth}px;
  flex-shrink: 0;
  justify-content: ${isExpanded ? `flex-end` : `center`};
  background-color: ${colors.white};
  border-radius: ${rounded.xxl};
  border: 2px solid ${color};
  box-shadow: 0 2px 10px 0px ${transparentize(0.5, color)};
  align-items: center;
  cursor: pointer;
  .icon {
    fill: ${color};
  }
  .short-label {
    ${bodyStyle}
    position: absolute;
    color: ${color};
    font-size: 0.5rem;
    font-weight: bold;
    bottom: ${layerListSpacing}px;
    left: 0;
    right: 0;
    line-height: 0.65em;
    margin: 0;
    margin-left: ${isExpanded ? `${layerIconWidth + layerListSpacing + 10}px` : 0};
  }
  &:hover {
    background-color: ${darken(0.08, colors.white)};
    cursor: pointer;
  }
  &.active {
    background-color: ${color};
    border: none;
    .icon {
      fill: ${colors.white};
    }
    .short-label {
      color: ${colors.white};
      bottom: ${layerListSpacing + 2}px;
    }
    svg {
      margin-top: -0.25rem;
    }
    &:hover {
      background-color: ${lighten(0.15, color)};
    }
    &.is-dragging {
      background-color: ${color};
    }
  }
`;

export const layerList = css`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  flex: 1;
  padding-top: ${layerListSpacing}px;
  padding-bottom: ${layerListSpacing}px;

  pointer-events: visible;
  > * {
    pointer-events: auto;
  }
`;

export const dataPointsTabStyle = css`
  padding: 0;
`;

export const cellStyle = css`
  &:not(th) {
    background-color: ${colors.gray[200]};
    border-width: 1px 0;
    border-style: solid;
    border-color: ${colors.gray[800]} !important;
    user-select: none;
    cursor: pointer;

    &:first-of-type {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      border-left-width: 1px;
    }

    &:last-of-type {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      border-right-width: 1px;
    }
  }
`;

export const valueWithDotStyle = css`
  &:not(th) {
    border-width: 1px;
    background-color: transparent;

    > div {
      display: flex;
      flex-basis: 0;
      align-items: center;
      justify-content: space-between;

      p {
        margin: 0;
      }
    }
  }
`;

export const dataPointsRowStyle = css``;

export const dataPointsRowUnselectedRowStyle = css`
  opacity: 0.3;
  filter: grayscale(100%);
`;

export const layerListWrapper = css`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  height: 100%;
  padding-top: ${layerListSpacing}px;
  padding-bottom: ${layerListSpacing}px;
  background: transparent;
`;

export const dropZoneWrapper = css`
  display: flex;
  position: relative;
  background: transparent;
  transition: ${transition};
`;

// ADDITIVE STYLES FOR THE LAYERBUTTONTOGGLE
export const plusButtonStyle = (isExpanded: boolean, color: Color = colors.gray[900]) => {
  const base = layerButtonToggle(isExpanded, color);
  return css`
    ${base}
    border: 1px dashed ${colors.gray[300]};
    transition: ${transition};
    &:active {
      transform: scale(1.1) !important;
    }
    &.active {
      transform: rotate(45deg) !important;
      &:hover {
        background-color: ${colors.gray[300]};
      }
    }
    svg {
      margin-top: 0 !important;
      path {
        fill: ${colors.gray[600]};
      }
    }
  `;
};

export const versionStyle = css`
  height: 1rem;
  line-height: 0.75rem;
  width: calc(${layerListWidth}px - 1rem);
  padding-top: 0.25rem;
  margin: 0 0.5rem;
  font-family: ${fontFamily};
  font-size: ${fontSize.xs};
  text-align: center;
  color: ${colors.gray[600]};
  border-top: 1px solid ${colors.gray[300]};
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
`;
