import { Action } from 'redux';

enum Dock {
  TOGGLE_MAP_VISUALIZATION = 'dock/TOGGLE_VISUALIZATION',
  TOGGLE_LAYER_VISUALIZATION = 'dock/TOGGLE_LAYER_VISUALIZATION',
  REORDER_LAYERS = 'dock/REORDER_LAYERS',
}

export interface ToggleMapVisualizationAction {
  type: typeof Dock.TOGGLE_MAP_VISUALIZATION;
  payload: { id: number; visualizationId: number };
}

export interface ToggleLayerVisualizationAction {
  type: typeof Dock.TOGGLE_LAYER_VISUALIZATION;
  payload: number;
}

export interface ReorderLayersAction {
  type: typeof Dock.REORDER_LAYERS;
  payload: number[];
}

export type DockActionType = ToggleMapVisualizationAction | ToggleLayerVisualizationAction | ReorderLayersAction;

export class DockAction implements Action<Dock> {
  type: Dock;

  static Types = Dock;

  constructor(type: Dock) {
    this.type = type;
  }

  static toggleLayerMapVisualization = (id: number, visualizationId: number): ToggleMapVisualizationAction => ({
    type: Dock.TOGGLE_MAP_VISUALIZATION,
    payload: { id, visualizationId },
  });

  static toggleLayerVisualization = (id: number): ToggleLayerVisualizationAction => ({
    type: Dock.TOGGLE_LAYER_VISUALIZATION,
    payload: id,
  });

  static reorderLayers = (ids: number[]): ReorderLayersAction => ({
    type: Dock.REORDER_LAYERS,
    payload: ids,
  });
}

export default DockAction;
