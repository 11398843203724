import en from './en.json';
import nl from './nl.json';
import ar from './ar.json';

export const resources = {
  en,
  nl,
  ar,
};

export default resources;
