import * as React from 'react';
import OnScreenMapControlsButton, { ButtonConfig } from './OnScreenMapControlButton';

interface CompassButtonProps extends ButtonConfig {
  bearing: number;
}

const CompassButton: React.FunctionComponent<CompassButtonProps> = ({
  icon,
  title,
  className,
  onClick,
  customStyle,
  bearing,
}) => {
  return (
    <OnScreenMapControlsButton
      icon={icon}
      title={title}
      className={className}
      onClick={onClick}
      customStyle={{
        ...customStyle,
        transform: `rotate(${bearing}deg)`,
      }}
    />
  );
};

export default CompassButton;
