import React, { useState } from 'react';
import { ViewState } from 'react-map-gl';
import { Button } from 'antd';
import { css } from 'emotion';

import { colors, controlsH2Style, rounded } from 'styles';
import ExampleCameraScriptButton from './ExampleCameraScriptButton';
import CameraScriptConfig from './CameraScriptConfig';
import { CameraScriptRaw, Easing, Interpolator } from '../../models/interfaces/Camera';

interface Props {
  viewState?: ViewState;
  cameraScript: CameraScriptRaw;
  handleUpdateCameraScript: (cS: CameraScriptRaw) => void;
  handleExampleCameraScript: () => void;
  handleStartCameraScript: () => void;
}

const configurationStyle = css`
  display: flex;
  justify-content: space-between;
  width: 380px;
  margin-bottom: 1rem;
`;

const codeStyle = css`
  display: block;
  margin: 0.5rem 0;
  padding: 1rem;
  color: ${colors.white};
  background-color: ${colors.gray[800]};
  border-radius: ${rounded.md};
  max-height: 50vh;
  overflow: auto;
`;

export interface Config {
  interpolator: Interpolator;
  duration: number;
  easing: Easing;
}

const initialConfig: Config = {
  interpolator: 'flyToInterpolator',
  duration: 5000,
  easing: 'easeCubic',
};

const CameraScriptControls: React.FC<Props> = (props) => {
  const [config, setConfig] = useState<Config>(initialConfig);

  const handleChangeConfig = (cfg: Partial<Config>) => setConfig({ ...config, ...cfg });

  const handleAdd = (): void => {
    if (props.viewState) {
      const length: number = props.cameraScript?.cameraMovements?.length;
      const lastIndex: number = length ? props.cameraScript.cameraMovements[length - 1].index : 0;
      props.handleUpdateCameraScript({
        cameraMovements: [
          ...props.cameraScript.cameraMovements,
          {
            index: Number(lastIndex) + 1,
            viewState: props.viewState,
            transition: config,
          },
        ],
      });
    }
  };
  const handlePop = (): void => {
    if (props.cameraScript.cameraMovements?.length) {
      const newCameraMovements = [...props.cameraScript.cameraMovements];
      newCameraMovements.pop();
      props.handleUpdateCameraScript({ cameraMovements: newCameraMovements });
    }
  };

  return (
    <>
      <h2 className={controlsH2Style}>Example camera script</h2>
      <ExampleCameraScriptButton handleCameraScript={props.handleExampleCameraScript} />

      <h2 className={controlsH2Style}>Play custom camera script</h2>
      <div className={configurationStyle}>
        <CameraScriptConfig handleChangeConfig={handleChangeConfig} initialConfig={initialConfig} />
        <Button
          type="primary"
          shape="circle"
          icon="plus"
          title="Add current view state"
          onClick={handleAdd}
          style={{ marginRight: '0.25rem' }}
        />
        <Button type="danger" shape="circle" icon="delete" title="Remove the last view state" onClick={handlePop} />
      </div>

      {Boolean(props.cameraScript.cameraMovements.length) && (
        <Button type="primary" onClick={props.handleStartCameraScript}>
          Play
        </Button>
      )}
      {props.cameraScript && <code className={codeStyle}>{JSON.stringify(props.cameraScript)}</code>}
    </>
  );
};

export default CameraScriptControls;
