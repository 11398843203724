import moment from 'moment';
import { createTimeInterval } from 'config/presetTimeIntervals';
import { Set } from 'immutable';
import jmespath from 'jmespath';
import { getDurationForInterval } from '../utils/timeUtils';
import GlobalFilterAction, { GlobalFilterActionType } from '../actions/global-filters/GlobalFilterAction';
import { PropertySelection } from '../models/types/PropertySelection';
import { TimeInterval } from '../models/types/TimeInterval';
import { SelectableProperty } from '../models/interfaces/api/DataSource';

const defaultInterval = createTimeInterval('hour', 1);

const rangeStart = new Date(2020, 1, 11);

export interface GlobalFilterState {
  readonly dateRange: {
    readonly start: Date;
    readonly end: Date;
  };
  readonly currentInterval: {
    readonly start: Date;
    readonly end: Date;
  };
  readonly timeInterval: TimeInterval;
  readonly propertySelection: PropertySelection;
}

const initialState: GlobalFilterState = {
  dateRange: {
    start: rangeStart,
    end: new Date(2020, 1, 13),
  },
  currentInterval: {
    start: rangeStart,
    end: moment(rangeStart).add(getDurationForInterval(defaultInterval)).toDate(),
  },
  timeInterval: defaultInterval,
  propertySelection: {},
};

const reducer = (state: GlobalFilterState = initialState, action: GlobalFilterActionType) => {
  switch (action.type) {
    case GlobalFilterAction.Types.SET_TIME_INTERVAL:
      if (action.payload.interval === undefined)
        return {
          ...state,
          currentInterval: {
            start: action.payload.start,
            end: action.payload.end,
          },
        };
      return {
        ...state,
        currentInterval: {
          start: action.payload.start,
          end: action.payload.end,
        },
        timeInterval: action.payload.interval,
      };
    case GlobalFilterAction.Types.SET_DATE_RANGE:
      return {
        ...state,
        dateRange: {
          start: action.payload.start,
          end: action.payload.end,
        },
      };
    case GlobalFilterAction.Types.CLEAR_SELECTION:
      return {
        ...state,
        propertySelection: initialState.propertySelection,
      };
    case GlobalFilterAction.Types.TOGGLE_SELECTED: {
      let propertySelection = { ...state.propertySelection };
      action.payload.selectableProperties.forEach((selectableProperty: SelectableProperty) => {
        const match = jmespath.search(action.payload.pickedInfo.object, selectableProperty.path);

        // If the match is empty aka nothing was found for the selectableProperty path, skip it
        if (!match) return;

        // Get a new copy of the existing selection Set for this visualizationId and selectableProperty key, if it does not exist it creates a new Set
        let selection =
          propertySelection?.[action.payload.visualizationId]?.[selectableProperty.key]?.selection ||
          Set<number | string | object>();

        // Toggle the selection
        if (selection.has(match)) {
          selection = selection.delete(match);
        } else {
          selection = selection.add(match);
        }

        propertySelection = {
          ...propertySelection,
          [action.payload.visualizationId]: {
            ...propertySelection[action.payload.visualizationId],
            [selectableProperty.key]: { selection },
          },
        };
      });

      return {
        ...state,
        propertySelection,
      };
    }
    default:
      return state;
  }
};

export default reducer;
