import React, { useMemo } from 'react';
import moment from 'moment';
import { css } from 'emotion';
import { config, animated, useTransition } from 'react-spring';

import { timelineIntervalSpeedMs } from 'config/app';
import { styleConfig, cardStyle } from 'styles/demo';

const { colors, padding, margin, borderRadius, lineHeight, fontSize } = styleConfig;

type AnimatedImage = ({ style }: { style: React.CSSProperties }) => JSX.Element;

interface Props {
  timestamp: Date;
  progress: number; // number between 1 and 100 as percent
  currentIcon?: string;
  icons?: string[]; // required if currentIcon is set
  paused: boolean;
  onPauseClick?: () => void;
  onScrub: (inc: boolean) => void;
}

const timelineWrapperStyle = css`
  ${cardStyle}
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: space-between;
  width: 300px;
  margin-bottom: ${margin[8]};
  padding: ${padding[4]};
  color: ${colors.white};
  font-size: ${fontSize.xxl};
  font-weight: bold;
  line-height: ${lineHeight.sm};
`;

const actionStyle = css`
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 110px;
  text-align: right;
  text-transform: uppercase;
`;

const timeStyle = css`
  width: 110px;
  text-align: center;
  flex-direction: column;
  .time {
    font-size: ${fontSize.xl};
  }
  .progress-bar {
    height: 5px;
    width: 80px;
    margin: 0 auto;
    background-color: ${colors.gray[600]};
    border-radius: ${borderRadius.sm};
    .progress {
      height: 100%;
      width: 0%;
      background-color: ${colors.gray[300]};
      border-radius: ${borderRadius.sm};
      transition: width ${timelineIntervalSpeedMs}ms ease-out;
    }
  }
`;

const dayStyle = css`
  position: relative;
  width: 40px;
  img {
    display: block;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
`;

const buttonStyle = css`
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  border: none;
  padding-bottom: 5px;
  transition: all 100ms linear;
  &:hover {
    transform: scale(1.1);
  }
`;

const DEFAULT_ICONS = {
  moon: './images/moon.svg',
  sun: './images/sun.svg',
};

const CONTROL_ICONS = {
  play: './icons/play.svg',
  pause: './icons/pause.svg',
  backward: './icons/fast-backward.svg',
  forward: './icons/fast-forward.svg',
};

// eslint-disable-next-line react/display-name
const defaultAnimatedImages: AnimatedImage[] = Object.values(DEFAULT_ICONS).map((icon) => ({ style }) => (
  <animated.img src={icon} alt="day indication" height={32} style={style} />
));

const Timeline: React.FC<Props> = (props) => {
  const memoizedIcon = useMemo((): string => {
    if (props.currentIcon) return props.currentIcon;
    if (props.timestamp.getHours() < 20 && props.timestamp.getHours() >= 8) {
      return DEFAULT_ICONS.sun;
    }
    return DEFAULT_ICONS.moon;
  }, [props.currentIcon, props.timestamp]);

  const animatedIcons: AnimatedImage[] = useMemo(() => {
    if (props.icons) {
      // eslint-disable-next-line react/display-name
      return props.icons.map((icon) => ({ style }) => (
        <animated.img src={icon} alt="day indication" height={32} style={style} />
      ));
    }
    return defaultAnimatedImages;
  }, [props.icons]);

  const memoizedIconIndex = useMemo(() => {
    if (props.icons) {
      const foundIndex = props.icons.findIndex((icon) => icon === memoizedIcon);
      if (foundIndex) return foundIndex;
      return 0;
    }
    const icons = Object.values(DEFAULT_ICONS).map((icon) => icon);
    const foundIndex = icons.findIndex((icon) => icon === memoizedIcon);
    if (foundIndex) return foundIndex;
    return 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedIcon]);

  const pausePlayIcon = useMemo(() => {
    if (props.paused) {
      return <img src={CONTROL_ICONS.play} alt="play time" height={28} title="play" />;
    }
    return <img src={CONTROL_ICONS.pause} alt="pause time" height={28} title="pause" />;
  }, [props.paused]);

  const animationProps = {
    from: { opacity: 0, transform: 'translateY(-25px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(25px)' },
    config: config.wobbly,
  };

  const transitions = useTransition(memoizedIconIndex, (item) => item, animationProps);

  return (
    <div className={timelineWrapperStyle} dir="ltr">
      <div className={actionStyle}>
        <button type="button" className={buttonStyle} onClick={() => props.onScrub(false)}>
          <img src={CONTROL_ICONS.backward} alt="time step backwards" height={20} title="back" />
        </button>
        <button type="button" className={buttonStyle} onClick={props.onPauseClick}>
          {pausePlayIcon}
        </button>
        <button type="button" className={buttonStyle} onClick={() => props.onScrub(true)}>
          <img src={CONTROL_ICONS.forward} alt="time step forward" height={20} title="forward" />
        </button>
      </div>
      <div className={timeStyle}>
        <div>{moment(props.timestamp).format('HH:mm')}</div>
        <div className="progress-bar">
          <div className="progress" style={{ width: `${props.progress}%` }} />
        </div>
      </div>
      <div className={dayStyle}>
        {transitions.map(({ item, key, props: propStyle }) => {
          const AnimatedIcon = animatedIcons[item];
          return <AnimatedIcon key={key} style={propStyle} />;
        })}
      </div>
    </div>
  );
};

export default Timeline;
