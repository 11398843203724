export default `
#define SHADER_NAME color-lookup-geotiff-layer-fragment-shader

#ifdef GL_ES
precision highp float;
#endif

uniform sampler2D geoTiffDataTexture;
uniform sampler2D colorLookUpTexture;

varying vec2 vTexCoord;
uniform float opacity;
uniform float domainMin;
uniform float domainMax;

float remap(float value){
  float retVal = ceil(value-domainMin)/(domainMax-domainMin);
  return clamp(retVal, 0.0, 1.0);
}

void main(void) {
  vec4 dataValues = texture2D(geoTiffDataTexture, vTexCoord);

  gl_FragColor = texture2D(colorLookUpTexture, vec2(remap(dataValues.r), 0));
  gl_FragColor *= opacity;

  geometry.uv = vTexCoord;
  DECKGL_FILTER_COLOR(gl_FragColor, geometry);
}
`;
