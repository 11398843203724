import { debounceTime } from 'config/api';
import { debounce, fork, takeEvery } from 'redux-saga/effects';
import handleAppIdleSaga from 'sagas/handleAppIdleSaga';
import appSaga, { restartAppSaga } from './appSaga';
import getBoundsSaga from './mapSaga';
import getCameraScriptSaga, { startCameraScriptSaga } from './cameraSaga';
import { updateCachedDataOnScenarioTriggerSaga, handleCameraScriptSaga, resetScenarioSaga } from './scenariosSaga';
import getTagsSaga from './tagsSaga';
import getLayersSaga, { activateLayer } from './layersSaga';
import { setDefaultToolbarActionOnCarouselShow } from './toolBarActionsSaga';
import searchSaga from './search/searchSaga';
import searchSuggestionsSaga from './search/searchSuggestionsSaga';
import carouselSearchSaga from './search/carouselSearchSaga';
import getLayerDataSaga, { getCustomLayerDataSaga } from './layersDataSaga';
import lodModeSaga from './lodModeSaga';
import { getChartDataSaga } from './chartDataSaga';
import changeLanguageSaga from './languageSaga';
import { CameraScriptAction } from '../actions/camera/CameraAction';
import ScenariosAction from '../actions/scenarios/ScenariosAction';
import TagsAction from '../actions/tags/TagsAction';
import LayersAction from '../actions/layers/LayersAction';
import AppAction from '../actions/app/AppAction';
import SearchAction from '../actions/search/SearchAction';
import CarouselSearchAction from '../actions/search/CarouselSearchAction';
import SuggestionsAction from '../actions/search/SuggestionsAction';
import LayersUIAction from '../actions/layers/LayersUIAction';
import LanguageAction from '../actions/language/LanguageAction';
import { botClearGems, botClearHistory, botStartScenarios, botStopScenarios, botClearAlertingLayers } from './botSagas';

export default function* rootSaga() {
  yield fork(appSaga);
  yield fork(getBoundsSaga);
  yield fork(lodModeSaga);

  yield takeEvery(AppAction.Types.SET_LOD_MODE, lodModeSaga);

  yield takeEvery(CameraScriptAction.Types.GET_SCRIPT, getCameraScriptSaga);
  yield takeEvery(CameraScriptAction.Types.GET_SCRIPT_COMPLETED, startCameraScriptSaga);

  yield takeEvery(ScenariosAction.Types.TRIGGERED, updateCachedDataOnScenarioTriggerSaga);

  yield takeEvery(TagsAction.Types.GET, getTagsSaga);
  yield takeEvery(LayersAction.Types.GET, getLayersSaga);

  yield takeEvery(AppAction.Types.TOGGLE_CAROUSEL, setDefaultToolbarActionOnCarouselShow);

  yield debounce(debounceTime, SearchAction.Types.STARTED, searchSaga);
  yield debounce(debounceTime, SuggestionsAction.Types.GET, searchSuggestionsSaga);

  yield debounce(debounceTime, CarouselSearchAction.Types.STARTED, carouselSearchSaga);

  yield takeEvery(LayersUIAction.Types.ADD_LAYER, activateLayer);
  yield takeEvery(LayersAction.Types.GET_DATA, getLayerDataSaga);
  yield takeEvery(LayersAction.Types.GET_DATA, getChartDataSaga);
  yield takeEvery(LayersAction.Types.GET_CUSTOM_DATA, getCustomLayerDataSaga);

  yield takeEvery(ScenariosAction.Types.TRIGGERED, handleCameraScriptSaga);

  yield takeEvery(LanguageAction.Types.CHANGE, changeLanguageSaga);

  yield takeEvery(AppAction.Types.IS_IDLE, handleAppIdleSaga);

  yield takeEvery(ScenariosAction.Types.RESET, resetScenarioSaga);

  yield takeEvery(AppAction.Types.RESTART, restartAppSaga);

  yield fork(botStartScenarios);
  yield fork(botClearGems);
  yield fork(botClearAlertingLayers);
  yield fork(botStopScenarios);
  yield fork(botClearHistory);
}
