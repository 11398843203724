import { css } from 'emotion';
import { colors, fontSize, fontFamily } from 'styles';

const baseColor = css`
  color: ${colors.gray[900]};
`;

export const titleStyle = css`
  ${baseColor};
  font-weight: 500;
  font-size: ${fontSize.xl};
`;

export const subTitleStyle = css`
  ${baseColor};
  font-family: ${fontFamily};
  font-weight: 500;
  font-size: ${fontSize.lg};
`;

export const bodyStyle = css`
  ${baseColor};
  font-family: ${fontFamily};
  font-weight: 400;
  font-size: ${fontSize.md};
  line-height: 1.5em;
`;

export const tableStyle = css`
  ${baseColor};
  font-family: ${fontFamily};
  font-weight: 400;
  font-size: ${fontSize.sm};
  line-height: normal;
`;

export const capitalize = css`
  text-transform: capitalize;
`;

export const uppercase = css`
  text-transform: uppercase;
`;
