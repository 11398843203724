import { Action } from 'redux';

enum Scenarios {
  TRIGGERED = 'scenario/TRIGGERED',
  RESET = 'scenario/RESET',
}

export interface ScenarioTriggeredAction {
  type: typeof Scenarios.TRIGGERED;
  payload: {
    scenarioId: number; // Scenario ID
    playCameraMovement: boolean;
  };
}

export interface ScenarioResetAction {
  type: typeof Scenarios.RESET;
}

export type ScenariosActionType = ScenarioTriggeredAction | ScenarioResetAction;

export class ScenariosAction implements Action<Scenarios> {
  type: Scenarios;

  static Types = Scenarios;

  constructor(type: Scenarios) {
    this.type = type;
  }

  static scenarioTriggered = (scenarioId: number, playCameraMovement = false): ScenarioTriggeredAction => ({
    type: Scenarios.TRIGGERED,
    payload: { scenarioId, playCameraMovement },
  });

  static scenarioReset = (): ScenarioResetAction => ({ type: Scenarios.RESET });
}

export default ScenariosAction;
