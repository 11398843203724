import { Action } from 'redux';
import { SeriesGroup } from '../../models/interfaces/Chart';

// TODO: Investigate if we can replace or unify this logic with the layer data actions https://imec.atlassian.net/browse/BUBR-147
enum Charts {
  GET_DATA = 'chart/GET_DATA',
  GET_DATA_COMPLETED = 'chart/GET_DATA_COMPLETED',
  GET_DATA_FAILED = 'chart/GET_DATA_FAILED',
}

export interface GetDataAction {
  type: typeof Charts.GET_DATA;
  payload: { layerId: number };
}

export interface GetDataCompletedAction {
  type: typeof Charts.GET_DATA_COMPLETED;
  payload: {
    layerId: number;
    visualizationId: number;
    seriesGroup: SeriesGroup;
  };
}

export interface GetDataFailedAction {
  type: typeof Charts.GET_DATA_FAILED;
  payload: {
    layerId: number;
    error: Error;
  };
}

export type ChartsActionType = GetDataAction | GetDataCompletedAction | GetDataFailedAction;

export class ChartsAction implements Action<Charts> {
  type: Charts;

  static Types = Charts;

  constructor(type: Charts) {
    this.type = type;
  }

  static getChartData = (layerId: number): GetDataAction => ({
    type: Charts.GET_DATA,
    payload: { layerId },
  });

  static getChartDataCompleted = (
    layerId: number,
    visualizationId: number,
    seriesGroup: SeriesGroup
  ): GetDataCompletedAction => ({
    type: Charts.GET_DATA_COMPLETED,
    payload: {
      layerId,
      visualizationId,
      seriesGroup,
    },
  });

  static getChartDataFailed = (layerId: number, error: Error): GetDataFailedAction => ({
    type: Charts.GET_DATA_FAILED,
    payload: { layerId, error },
  });
}

export default ChartsAction;
