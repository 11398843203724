import { PathLayer, IconLayer } from '@deck.gl/layers';
import { PathStyleExtension } from '@deck.gl/extensions';
import { DeckGLPickedInfo } from 'models/interfaces/deckgl/DeckGLPickedInfo';
import { Feature, LineString } from 'geojson';
import { Coordinates } from 'viewport-mercator-project';

// TODO: styling should be configurable

/**
 * Layer that represents closed street segments
 * @param paths Array of coordinate pairs that form a path
 * @param signs Array of coordinates of where to place sign posts
 * @param segmentIds Optional array of number representing the road segment ids
 * @param onSegmentHover Optional callback that is trigger while hovering over a path
 */
const ClosedRoadSegmentsLayer = (
  paths: Coordinates[][],
  signs: Coordinates[],
  segmentIds?: number[],
  onSegmentHover?: (object: DeckGLPickedInfo<Feature<LineString>>, segmentId: number) => void
) => {
  const extendedSigns = signs.map((coord) => {
    return { info: 'gems.closedStreet.info', coordinates: coord };
  });
  return [
    new PathLayer({
      id: 'white-path-layer',
      data: paths,
      rounded: true,
      widthScale: 4,
      getPath: (d: Coordinates) => d,
      getColor: () => [255, 255, 255, 255],
      getWidth: () => 2,
    }),
    new PathLayer({
      id: 'red-checkered-path-layer',
      data: paths,
      rounded: true,
      widthScale: 3,
      dashJustified: true,
      getDashArray: () => [3, 3],
      getPath: (d: Coordinates) => d,
      getColor: () => [255, 30, 30, 255],
      getWidth: () => 2,
      extensions: [new PathStyleExtension({ dash: true })],
      onHover: (pickInfo: DeckGLPickedInfo<any>) => {
        if (segmentIds) {
          const segmentId = segmentIds[pickInfo.index];
          if (onSegmentHover) onSegmentHover(pickInfo, segmentId);
        }
      },
    }),
    new IconLayer({
      id: 'road-signs-layer',
      data: extendedSigns,
      billboard: true,
      pickable: true,
      // To avoid the icon layer from intersecting with the 3D buildings we apply a polygon offset: https://deck.gl/docs/api-reference/core/layer#getpolygonoffset
      // This will directly modify the depth buffer of WebGL to move it towards the camera a slight bit which avoids the insections in most cases
      // The number -30000 is very much a magic number, it does not have a real unit as it is dependent on WebGL camera settings.
      // It will also not work for every city e.g. New York (skyscrapers) but seems to work well for Antwerp
      getPolygonOffset: () => [0, -30000],
      getIcon: () => ({
        url: '/images/road-closed-marker.svg',
        width: 300,
        height: 330,
        anchorY: 330,
      }),
      sizeScale: 15,
      getPosition: (d: any) => d.coordinates,
      getSize: () => 3,
    }),
  ];
};

export default ClosedRoadSegmentsLayer;
