import './i18n';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
import { Provider } from 'react-redux';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import rootSaga from 'sagas/rootSaga';
import demoSaga from 'sagas/demoSaga';
import rootReducer from 'reducers/rootReducer';
import * as serviceWorker from './serviceWorker';
import TranslatedApp from './App';
import { samplingRate, sentryDsn, tracingRelease } from './config/tracing';

if (sentryDsn && process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [
      new Sentry.Integrations.GlobalHandlers(),
      new Integrations.BrowserTracing(),
      new Sentry.Integrations.Breadcrumbs(),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    release: tracingRelease,
    tracesSampleRate: samplingRate,
  });
} else {
  console.warn(`Skipping Sentry, DSN provided: '${!!sentryDsn}'.`);
}

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = composeWithDevTools({
  serialize: true,
});

const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);
sagaMiddleware.run(demoSaga);

ReactDOM.render(
  <Provider store={store}>
    <DndProvider backend={HTML5Backend}>
      <TranslatedApp />
    </DndProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
