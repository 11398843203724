import React, { useState, useEffect } from 'react';
import { transparentize } from 'polished';
import { css, cx } from 'emotion';
import SVG from 'react-inlinesvg';

import { positionCenterStyle } from 'styles';
import { styleConfig, cardStyle } from 'styles/demo';
import Modal from 'components/modal/Modal';
import DomainInfoScreenContent from 'metadata/DomainInfoScreenContent';

const { colors, margin, padding, fontSize, borderRadius, letterSpacing, boxShadow } = styleConfig;

const tabWrapperStyle = css`
  position: absolute;
  margin: ${margin[4]} ${margin[4]} calc(70px + 3rem) ${margin[4]};
  bottom: 0;
  right: 0;
  color: ${colors.gray[200]};
`;

const tabOptions = css`
  display: flex;
  justify-content: 'center';
  padding: 0 ${padding[8]};
  .tab-item {
    position: relative;
    padding: ${padding[4]};
    margin: 0 ${margin[2]};
    border-radius: ${borderRadius.lg};
    box-shadow: ${boxShadow.sm};
    transition: all 100ms linear;
    img {
      ${positionCenterStyle}
    }
    &.active {
      height: 70px;
      width: 70px;
      background-color: ${transparentize(0.1, colors.black)};
      img {
        width: 30px;
        height: 30px;
      }
    }
    &:not(.active) {
      height: 60px;
      width: 60px;
      margin: ${margin[2]};
      background-color: ${transparentize(0.4, colors.black)};
      img {
        width: 25px;
        height: 25px;
      }
    }
    &:hover {
      box-shadow: ${boxShadow.md};
      cursor: pointer;
      &.active {
        background-color: ${transparentize(0, colors.black)};
      }
      &:not(.active) {
        background-color: ${transparentize(0.2, colors.black)};
      }
    }
    &.alerting:after {
      content: '';
      position: absolute;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid ${styleConfig.colors.black};
      transform: rotate(-45deg);
      left: -15px;
      top: -10px;
      animation: MoveRightArrow 1s ease infinite;
    }
  }

  @keyframes MoveRightArrow {
    0%,
    100% {
      left: -15px;
      top: -10px;
    }
    50% {
      left: -25px;
      top: -20px;
    }
  }
`;

const tabContentStyle = css`
  ${cardStyle}
  margin-top: ${margin[2]};
  overflow: hidden;
  .title {
    padding: ${padding[4]};
    background-color: ${colors.gray[700]};
    h2 {
      font-size: ${fontSize.lg};
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      color: ${colors.gray[200]};
      margin: 0;
      letter-spacing: ${letterSpacing.md};

      .icon {
        width: 22px;
        height: 22px;
        padding: 3px;
        display: inline-block;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        cursor: pointer;
        margin-bottom: -2px;
        border-radius: 50%;
        border: 1px solid white;

        path {
          stroke: ${colors.gray[200]};
        }
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    padding: ${padding[4]};
    min-height: 230px;
  }
`;

export interface TabOption {
  id: number;
  name: string;
  content: JSX.Element;
  icon: string;
  alerting: boolean;
}

interface Props {
  options: TabOption[];
  selected?: number;
  className?: string;
  onClick?: (id: TabOption['id']) => void;
}

const DemoTabs: React.FC<Props> = (props) => {
  const [selected, setSelected] = useState<number | null>(null);
  const [infoVisible, setInfoVisible] = useState<boolean>(false);

  useEffect(() => {
    if (props.selected) {
      setSelected(props.selected);
    }
    return () => setSelected(null);
  }, [props.selected]);

  const title = props.options.find((option) => option.id === selected)?.name || '...';
  const content = props.options.find((option) => option.id === selected)?.content;
  return (
    <div className={cx(tabWrapperStyle, props.className)} style={{ minWidth: 238 }}>
      <div className={tabOptions}>
        {props.options.map((option: TabOption) => {
          const active = selected === option.id;
          return (
            <div
              key={option.id}
              onClick={() => {
                setSelected(option.id);
                props.onClick && props.onClick(option.id);
              }}
              className={cx('tab-item', active && 'active', option.alerting && 'alerting')}
            >
              <img src={option.icon} alt={option.name} />
            </div>
          );
        })}
      </div>
      <div className={tabContentStyle}>
        <div className="title">
          <h2>
            {title}
            <SVG className="icon" src="icons/info.svg" onClick={() => setInfoVisible(true)} />
          </h2>
        </div>
        <div className="content">{content}</div>
      </div>

      <Modal title={title} handleClose={() => setInfoVisible(false)} visible={infoVisible}>
        <DomainInfoScreenContent selected={selected} />
      </Modal>
    </div>
  );
};

export default DemoTabs;
