import TooltipAction, { TooltipActionType } from '../actions/tooltip/TooltipAction';
import DebugAction, { DebugActionType } from '../actions/debug/DebugAction';

export type TooltipState = {
  showing: boolean;
  location?: { x: number; y: number };
  title?: string;
  properties?: { [key: string]: any };
};

const initialState: TooltipState = {
  showing: false,
};

const reducer = (state = initialState, action: TooltipActionType | DebugActionType): TooltipState => {
  switch (action.type) {
    case TooltipAction.Types.TOGGLE: {
      if (!Object.keys(action.payload.properties).length) return initialState;
      if (state.showing) return initialState;
      return {
        showing: true,
        location: action.payload.location,
        title: action.payload.title,
        properties: action.payload.properties,
      };
    }
    case TooltipAction.Types.SHOW: {
      if (!Object.keys(action.payload.properties).length) return initialState;
      return {
        showing: true,
        location: action.payload.location,
        title: action.payload.title,
        properties: action.payload.properties,
      };
    }
    case TooltipAction.Types.HIDE: {
      return initialState;
    }
    case DebugAction.Types.TOGGLE_CONTROL_PANEL: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
