/* eslint-disable @typescript-eslint/camelcase */
import { message as notification } from 'antd';
import wkt from 'terraformer-wkt-parser';
import Ajv from 'ajv';
import { Feature, LineString, BBox } from 'geojson';
import { Coordinates } from 'viewport-mercator-project';

interface InputCFData {
  date: number;
  dateObserved: number;
  dateObservedFrom: number;
  dateObservedTo: number;
  source: string;
  intensity: number;
  refRoadSegment: number;
  vehicleType: string;
  area_covered: string;
}

interface OutputCFData {
  from: string; // isostring
  to: string; // isostring
  stepSize: number; // in ms
  modality: string;
  scenarioId: string;
  geometries: Array<Feature<LineString>>;
  intensities: Array<number[]>;
  segmentIds: string[];
}

const validateSchema = (data: any) => {
  const schema = {
    type: 'object',
    properties: {
      date: { type: 'number' },
      dateObserved: { type: 'number' },
      dateObservedTo: { type: 'number' },
      dateObservedFrom: { type: 'number' },
      refRoadSegment: { type: 'number' },
      intensity: { type: 'number' },
      area_covered: { type: 'string' },
      vehicleType: { type: 'string' },
      source: { type: 'string', pattern: '^(CityFlows|Cityflows)$' },
    },
    additionalProperties: false,
  };
  const ajv = new Ajv();
  const validate = ajv.compile(schema);
  if (!validate(data)) {
    console.error(validate.errors?.map(({ message }) => message).join(), 'Right format: ', schema);
    notification.error(`Wrong file format. Check the console for the right format.`);
  }
};
const parseCoordinates = (coordinate: string): Feature<LineString> => {
  try {
    const linestring = wkt.parse(coordinate);
    const feature: any = {
      type: 'Feature',
      geometry: linestring,
    };
    return feature;
  } catch (error) {
    notification.error('Invalid coordinate, unable to parse.');
    throw new Error(error);
  }
};

const coordinateIsInBounds = (coordinate: Coordinates, bbox: BBox): boolean => {
  if (bbox[0] <= coordinate[0] && coordinate[0] <= bbox[1] && bbox[2] <= coordinate[1] && coordinate[1] <= bbox[3]) {
    return true;
  }
  return false;
};

export default (input: any, bbox?: BBox) => {
  const dates = new Set<number>();
  const dataLength = input.length;
  const output: OutputCFData = {
    scenarioId: '0',
    from: new Date(input[0].dateObservedFrom).toISOString(),
    to: new Date(input[dataLength - 1].dateObservedTo).toISOString(),
    modality: input[0].vehicleType,
    stepSize: input[0].dateObservedTo - input[0].dateObservedFrom,
    geometries: [],
    intensities: [],
    segmentIds: [],
  };

  validateSchema(input[0]);

  input.forEach((item: InputCFData) => {
    if ((item.dateObserved / 1000) % 3600 !== 0) return;
    dates.add(item.dateObserved);
  });

  if (dates.size > 1) {
    notification.destroy();
    notification.warn('Multiple dates detected');
    console.warn('Multiple dates detected', dates);
  }
  console.info('Parsing data. Please wait...');

  const dataLengthPerStep = dataLength / dates.size;

  const values = (input as InputCFData[]).reduce<{
    geometries: OutputCFData['geometries'];
    intensities: OutputCFData['intensities'];
    segmentIds: OutputCFData['segmentIds'];
  }>(
    (acc, message, i) => {
      // Timestamp iteration
      const dataStepIndex = i % dataLengthPerStep;

      // Only push geometries and segmentIds of the first timestamp iteration
      if (i < dataLengthPerStep) {
        const geojson = parseCoordinates(message.area_covered);
        acc.geometries.push(geojson);
        acc.segmentIds.push(String(message.refRoadSegment));
      }

      // Push intensity values (if the timestamp iteration index doesn't exist, add to the array)
      if (acc.intensities[dataStepIndex] === undefined) {
        acc.intensities[dataStepIndex] = [Number(message.intensity)];
      } else {
        acc.intensities[dataStepIndex].push(Number(message.intensity));
      }

      return {
        geometries: acc.geometries,
        segmentIds: acc.segmentIds,
        intensities: acc.intensities,
      };
    },
    {
      geometries: [],
      intensities: [],
      segmentIds: [],
    }
  );

  let outOfBoundsCoords = 0;
  if (bbox) {
    values.geometries.forEach((item) => {
      if (!item) return;
      const firstCoord = item.geometry.coordinates[0];
      const isInBounds = coordinateIsInBounds([firstCoord[0], firstCoord[1]], bbox);
      if (!isInBounds) outOfBoundsCoords++;
    });
    if (outOfBoundsCoords > 0) {
      const warningMessage = `${outOfBoundsCoords} geometries are possibly out of bounds.`;
      notification.warn(warningMessage);
      console.warn(warningMessage);
    }
  }

  notification.success('Done processing data. The layer is enabled.');
  console.info('Done processing data.');
  return { ...output, ...values };
};
