import { SteppedColorRangeLegend } from 'models/interfaces/legends/SteppedColorRangeLegend';
import { LinearGradientLegend } from 'models/interfaces/legends/LinearGradientLegend';
import chroma, { Scale } from 'chroma-js';

// Add linearly calculated opacity val, in function of index
function addLinearOpacity(
  hexColor: string,
  currentIndex: number,
  amountOfColors: number,
  maxOpacity = 255,
  baseOpacity = 55
) {
  let alphaVal = (currentIndex / amountOfColors) * (maxOpacity - baseOpacity);
  alphaVal = Math.floor(alphaVal);
  alphaVal += baseOpacity;
  let alphaHex = alphaVal.toString(16);
  alphaHex = alphaHex.padStart(2, '0');
  return hexColor + alphaHex;
}

// Returns the chroma scale for a stepped color range legend
export const getColorScaleFromSteppedColorRangeLegend = (
  colorRangeLegend: SteppedColorRangeLegend,
  useOpacity?: boolean
) => {
  const colors: string[] = [];
  const classes: number[] = [];
  colorRangeLegend.ranges.forEach((range, index) => {
    let { color } = range;
    if (useOpacity) {
      color = addLinearOpacity(color, index, colorRangeLegend.ranges.length);
    }
    colors.push(color);
    classes.push(range.minValue);
  });

  classes.push(colorRangeLegend.ranges[colorRangeLegend.ranges.length - 1].maxValue);

  return chroma.scale(colors).classes(classes);
};

export const getColorScaleFromLinearGradientLegend = (gradientLegend: LinearGradientLegend): Scale => {
  const colors: string[] = [];
  const domain: number[] = [];
  // chroma;
  gradientLegend.stops.forEach((stop) => {
    colors.push(stop.color);
    domain.push(stop.value);
  });

  return chroma.scale(colors).domain(domain).mode('lab');
};
