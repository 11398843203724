import { createSelector } from 'reselect';
import { memoize as _memoize } from 'lodash';
import { RootState } from '../reducers/rootReducer';

export const getDockState = (state: RootState) => state.dock;

export const getCurrentLayer = createSelector(getDockState, (dock) => dock.layers.get(dock.sortedLayers[0]));

export const getLayerOrder = createSelector(getDockState, (dock) => dock.sortedLayers);
export const getDockedLayersMap = createSelector(getDockState, (dock) => dock.layers);
export const getDockedLayers = createSelector(getDockedLayersMap, (dockedLayers) => Array.from(dockedLayers.values()));
export const getActiveDockLayers = createSelector(getDockedLayers, (layers) => layers.filter((layer) => layer.active));

export const getDockLayerById = (layerId: number) =>
  createSelector(
    getDockedLayersMap,
    _memoize((layersMap) => layersMap.get(layerId))
  );

export const getCurrentLayerTabVis = createSelector(getCurrentLayer, (dockLayer) => dockLayer?.tabs[0].visualizations);
