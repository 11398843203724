import { Color } from 'chroma-js';
import { Layer as DeckGLLayer } from '@deck.gl/core';
import { GridLayer } from '@deck.gl/aggregation-layers';
import { getTideFactorForTimeStep } from 'config/tide';
import { GridWrapper } from '../../models/interfaces/api/GridWrapper';
import { CachedData } from '../../models/interfaces/api/CachedData';
import { GeoJsonCoordinate, GeoJsonCoordinates, GeoJsonFeature } from '../../models/types/GeoJsonFeature';
import { getColorScaleFromLinearGradientLegend } from './legendColorUtils';
import { FeatureCollection } from '../../models/interfaces/FeatureCollection';

const LAYER_ALPHA = 150;

const TIDE_SPLIT_FACTOR = 0.75;
const IGNORE_TIDE_FACTOR = 1 - TIDE_SPLIT_FACTOR;

const get0 = () => 0;
const getFloodingRiskPointLocation = (d: FloodingRiskPoint) => d.location;

export default function createFloodingRiskGridLayer(
  visualizationWrapper: GridWrapper,
  cachedData: CachedData,
  currentStepIndex: number
): DeckGLLayer {
  const scale = getColorScaleFromLinearGradientLegend(visualizationWrapper.legend);
  const colors = scale.colors(10, null).map((color: Color) => {
    const rgba = color.rgba();
    rgba[3] *= LAYER_ALPHA;
    return rgba;
  });

  const domain = [0, 1];

  const featureCollection: FeatureCollection = (cachedData.rawData as unknown) as FeatureCollection;
  const data = findAllPoints(featureCollection.features);

  return new GridLayer({
    id: 'floodingRiskLayer',
    data,
    pickable: false,
    extruded: true,
    cellSize: 200,
    colorRange: colors,
    colorDomain: domain,
    elevationDomain: domain,
    getPosition: getFloodingRiskPointLocation,
    getColorValue: getValueForPoints,
    getElevationValue: get0,
    material: false,
    updateTriggers: {
      getColorValue: [currentStepIndex],
    },
    transitions: {
      getColorValue: {
        duration: 1200,
      },
    },
  });

  function getValueForPoints(points: FloodingRiskPoint[]) {
    const tideFactor = getTideFactorForTimeStep(currentStepIndex);
    const floodingRiskAverage = points.reduce((total, { floodingRisk }) => total + floodingRisk, 0) / points.length;
    return floodingRiskAverage * IGNORE_TIDE_FACTOR + floodingRiskAverage * tideFactor * TIDE_SPLIT_FACTOR;
  }
}

interface FloodingRiskPoint {
  location: GeoJsonCoordinate;
  floodingRisk: number;
}

function findAllPoints(features: GeoJsonFeature[]): FloodingRiskPoint[] {
  return features.reduce((allCoordinates, feature) => {
    fillAllCoordinates(allCoordinates, feature.geometry.coordinates, feature);
    return allCoordinates;
  }, [] as FloodingRiskPoint[]);
}

function fillAllCoordinates(
  coordinates: FloodingRiskPoint[],
  featureCoordinates: GeoJsonCoordinates,
  feature: GeoJsonFeature
): void {
  if (!featureCoordinates || !featureCoordinates.length) {
    return;
  }

  featureCoordinates.forEach((featureCoordinate: GeoJsonCoordinate | GeoJsonCoordinates) => {
    if (
      featureCoordinate.length === 2 &&
      Number.isFinite(featureCoordinate[0]) &&
      Number.isFinite(featureCoordinate[1])
    ) {
      coordinates.push({
        location: featureCoordinate as GeoJsonCoordinate,
        floodingRisk: feature.properties.floodingRisk,
      });
    } else {
      fillAllCoordinates(coordinates, featureCoordinate as GeoJsonCoordinates, feature);
    }
  });
}
