import { css, cx } from 'emotion';
import { last as _last } from 'lodash';
import React from 'react';
import styleConfig from 'styles/demo/config';
import i18next from 'i18next';
import { SteppedColorRangeLegend as APISteppedColorRangeLegend } from '../../models/interfaces/legends/SteppedColorRangeLegend';

const { colors, fontSize } = styleConfig;

const legendWrapperStyle = css`
  .labels-top,
  .labels-bottom {
    display: flex;
    justify-content: space-between;
    color: ${colors.gray[200]};
    font-size: ${fontSize.xs};
    opacity: 0.8;
  }
  .labels-top {
    text-transform: uppercase;
    font-weight: bold;
  }
  .blocks {
    display: flex;
    justify-content: space-between;
    .block {
      margin: 1.5px;
      border-radius: 2px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

interface Props {
  SteppedColorRangeLegend: APISteppedColorRangeLegend;
  blockSize: number;
  className?: string;
  labelLeft?: string;
  labelRight?: string;
  t: i18next.TFunction;
}

const SteppedColorRangeLegend: React.FC<Props> = (props) => {
  const { t } = props;
  const { ranges } = props.SteppedColorRangeLegend;
  const firstLabel = t(`legend.label.${ranges[0].label}`);
  const lastLabel = t(`legend.label.${_last(ranges)?.label}`);
  return (
    <div className={cx(legendWrapperStyle, props.className)}>
      <div className="labels-top">
        {props.labelLeft && <div>{props.labelLeft}</div>}
        {props.labelRight && <div>{props.labelRight}</div>}
      </div>
      <div className="blocks">
        {ranges.map((range) => (
          <div
            key={range.label}
            className="block"
            style={{ backgroundColor: range.color, height: props.blockSize, width: props.blockSize }}
          />
        ))}
      </div>
      <div className="labels-bottom">
        <div>{firstLabel}</div>
        {lastLabel && <div>{lastLabel}</div>}
      </div>
    </div>
  );
};

export default SteppedColorRangeLegend;
