import { all, select, put, call } from 'redux-saga/effects';

import { Mapping } from 'models/interfaces/visualizations/Mapping';
import { StaticSeries, DynamicSeries } from 'models/interfaces/visualizations/Series';
import { mapValues } from '../utils/mappingUtils';
import ChartsAction, { ChartsActionType } from '../actions/charts/ChartsAction';
import { get, composeRequestURL } from '../api/common';
import { getDateRangeFilter } from '../selectors/globalFiltersSelectors';
import { getDockLayerById } from '../selectors/dockSelectors';
import { getCurrentScenarioId } from '../selectors/scenariosSelectors';
import { DockLayer } from '../models/interfaces/DockLayer';
import { BarChart } from '../models/interfaces/visualizations/BarChart';
import { LineChart } from '../models/interfaces/visualizations/LineChart';
import { MappedField } from '../models/interfaces/visualizations/MappedField';
import { ChartSeries, SeriesGroup } from '../models/interfaces/Chart';

export function* getChartDataSaga(action: ChartsActionType) {
  const { layerId } = action.payload;
  yield put(ChartsAction.getChartData(layerId));
  const dateRange = yield select(getDateRangeFilter);
  const currentScenarioId = yield select(getCurrentScenarioId);

  const dockLayer: DockLayer = yield select(getDockLayerById(layerId));

  if (!dockLayer) {
    yield put(ChartsAction.getChartDataFailed(layerId, new Error(`Layer with id: ${layerId} does not exist in dock`)));
    return;
  }

  // get the tab visualizations of the first tab
  const tabVisualizations = dockLayer.tabs[0]?.visualizations;
  if (!tabVisualizations?.length) return;

  // loops over all the tabVisualizations
  yield all(
    tabVisualizations.map(({ visualization }) =>
      getDataFromDatasources(layerId, visualization, dateRange, currentScenarioId)
    )
  );
}

/** loops over all the dataSources */
function* getDataFromDatasources(
  layerId: number,
  visualization: LineChart | BarChart,
  dateRange: any,
  currentScenarioId: number
) {
  yield all(
    visualization.dataSources.map(({ url, mapping }) => {
      const params = {
        from: dateRange.start.toISOString(),
        to: dateRange.end.toISOString(),
        scenarioId: String(currentScenarioId),
      };
      return call(fetchAndMapData, url, params, layerId, visualization.id, mapping);
    })
  );
}

export function mapDataForSeries(series: StaticSeries, rawData: object[], groupBy?: MappedField): ChartSeries {
  // return the values for a single series
  const chartSeriesItem: ChartSeries = {
    showLegend: !groupBy,
    typeX: series.x.type,
    typeY: series.y.type,
    data: [],
    name: series.seriesName,
  };
  // TODO: map all values at once.
  for (let i = 0; i < rawData.length; i++) {
    const measurement = rawData[i];
    if (groupBy) {
      const groupNames = (mapValues([measurement], groupBy.name) ?? [])[0];
      if (groupNames !== series.seriesName) {
        // eslint-disable-next-line
        continue;
      }
    }
    const x = (mapValues([measurement], series.x.name) ?? [])[0];
    const y = (mapValues([measurement], series.y.name) ?? [])[0];
    chartSeriesItem.data.push({ x, y });
  }
  return chartSeriesItem;
}

/**
 * Transforms a potentially dynamic series definition (one that includes a groupBy statement iterating over measurements) to an array of static series.
 */
export function transformSeries(series: StaticSeries | DynamicSeries, rawData: object[]): StaticSeries[] {
  // do the group by and extract all the series to determine all the series
  if ('groupBy' in series) {
    // dynamic series
    const seriesNames: string[] = Array.from(new Set(mapValues(rawData, series.groupBy.name)));
    return seriesNames.map((name) => {
      const { groupBy, ...dynamicSeries } = series;
      return { ...dynamicSeries, seriesName: name };
    });
  }
  // static series name
  return [series];
}

export function parseSeries(mapping: Mapping, rawData: object[]) {
  const seriesGroup: SeriesGroup = {};
  for (let i = 0; i < mapping.series.length; i++) {
    const series = mapping.series[i];
    // we group by values
    const groups = transformSeries(series, rawData);
    for (let j = 0; j < groups.length; j++) {
      const group = groups[j];
      const groupBy = 'groupBy' in series ? series.groupBy : undefined;
      seriesGroup[group.seriesName] = mapDataForSeries(group, rawData, groupBy);
    }
  }
  return seriesGroup;
}

/** fetch data from each dataSource and map them correctly to chart series */
function* fetchAndMapData(
  dataSourceUrl: string,
  params: Record<string, string>,
  layerId: number,
  visualizationId: number,
  mapping: Mapping
) {
  // API Call
  try {
    const url = composeRequestURL(dataSourceUrl, params);
    const rawData: object[] = yield call(get, url);

    const seriesGroup = parseSeries(mapping, rawData);

    yield put(ChartsAction.getChartDataCompleted(layerId, visualizationId, seriesGroup));
  } catch (error) {
    console.error(error);
    yield put(ChartsAction.getChartDataFailed(layerId, error));
  }
}
