import LODMode from '../models/types/LODMode';
import { AppMode } from '../models/types/AppMode';

const WINDOW_ENV = (window as any).env;

export const isProd = WINDOW_ENV?.NODE_ENV === 'production';
export const appMode = WINDOW_ENV?.APP_MODE ?? AppMode.DEFAULT;
export const defaultLODMode = WINDOW_ENV?.LODMODE_DEFAULT || LODMode.LOD2;
export const transitionDurationMs = 714;
export const timelineIntervalSpeedMs = WINDOW_ENV?.TIMELINE_INTERVAL_SPEED_MS || 1200;
export const idleTime = Number(WINDOW_ENV?.IDLE_TIME_SECONDS) || 60;
export const botIdleTime = idleTime / 2;
