import { Action } from 'redux';
import { TimeInterval } from '../../models/types/TimeInterval';
import { DeckGLPickedInfo } from '../../models/interfaces/deckgl/DeckGLPickedInfo';
import { SelectableProperty } from '../../models/interfaces/api/DataSource';

enum GlobalFilters {
  SET_TIME_INTERVAL = 'date-filter/SET_TIME_INTERVAL',
  SET_DATE_RANGE = 'date-filter/SET_DATE_RANGE',

  // TODO: implement proper multi selection
  TOGGLE_SELECTED = 'property-filter/TOGGLE_SELECTED',
  CLEAR_SELECTION = 'property-filter/CLEAR_SELECTION',
}

export interface SetIntervalAction {
  type: typeof GlobalFilters.SET_TIME_INTERVAL;
  payload: {
    start: Date;
    end: Date;
    interval?: TimeInterval;
  };
}

export interface SetDateRangeAction {
  type: typeof GlobalFilters.SET_DATE_RANGE;
  payload: {
    start: Date;
    end: Date;
  };
}

export interface ToggleSelectedAction {
  type: typeof GlobalFilters.TOGGLE_SELECTED;
  payload: {
    pickedInfo: DeckGLPickedInfo;
    selectableProperties: SelectableProperty[];
    visualizationId: number;
  };
}

export interface ClearSelectionAction {
  type: typeof GlobalFilters.CLEAR_SELECTION;
}

export type GlobalFilterActionType =
  | SetIntervalAction
  | SetDateRangeAction
  | ToggleSelectedAction
  | ClearSelectionAction;

class GlobalFilterAction implements Action<GlobalFilters> {
  type: GlobalFilters;

  static Types = GlobalFilters;

  constructor(type: GlobalFilters) {
    this.type = type;
  }

  static setTimeIntervalAction = (start: Date, end: Date, interval?: TimeInterval): SetIntervalAction => ({
    type: GlobalFilters.SET_TIME_INTERVAL,
    payload: { start, end, interval },
  });

  static setDateRangeAction = (start: Date, end: Date): SetDateRangeAction => ({
    type: GlobalFilters.SET_DATE_RANGE,
    payload: { start, end },
  });

  static toggleSelectedAction = (
    pickedInfo: DeckGLPickedInfo,
    selectableProperties: SelectableProperty[],
    visualizationId: number
  ): ToggleSelectedAction => ({
    type: GlobalFilters.TOGGLE_SELECTED,
    payload: { pickedInfo, selectableProperties, visualizationId },
  });

  static clearSelectionAction = (): ClearSelectionAction => ({
    type: GlobalFilters.CLEAR_SELECTION,
  });
}

export default GlobalFilterAction;
