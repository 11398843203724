import { select, fork, put } from 'redux-saga/effects';
import moment from 'moment';

import { defaultDateRange } from 'config/time';
import { addIntervalToDate } from '../utils/timeUtils';
import GlobalFilterAction from '../actions/global-filters/GlobalFilterAction';
import { getAppMode } from '../selectors/appSelectors';
import { getTimeInterval } from '../selectors/globalFiltersSelectors';
import { AppMode } from '../models/types/AppMode';
import ScenariosAction from '../actions/scenarios/ScenariosAction';
import { getDefaultScenarioId } from '../selectors/scenariosSelectors';

function* setToRealtime() {
  const { length, timeUnit } = defaultDateRange;
  const end = moment().toDate();
  const start = moment().subtract(length, timeUnit).toDate();
  yield put(GlobalFilterAction.setDateRangeAction(start, end));
  const currentTimeInterval = yield select(getTimeInterval);
  const intervalEnd = addIntervalToDate(start, currentTimeInterval);
  yield put(GlobalFilterAction.setTimeIntervalAction(start, intervalEnd));
}

export default function* () {
  const appMode = yield select(getAppMode);
  if (appMode !== AppMode.DEMO) {
    yield fork(setToRealtime);
  }
}

export function* restartAppSaga() {
  yield put(ScenariosAction.scenarioTriggered(yield select(getDefaultScenarioId), true));
}
