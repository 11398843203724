import { Action } from 'redux';
import TutorialType from '../../models/types/TutorialType';

enum Tutorial {
  TOGGLE = 'tutorial/TOGGLE',
}

export interface ToggleTutorialAction {
  type: typeof Tutorial.TOGGLE;
  payload: TutorialType;
}

export type TutorialActionType = ToggleTutorialAction;

export class TutorialAction implements Action<Tutorial> {
  type: Tutorial;

  static Types = Tutorial;

  constructor(type: Tutorial) {
    this.type = type;
  }

  static toggleTutorialAction = (tutorial: TutorialType): ToggleTutorialAction => ({
    type: Tutorial.TOGGLE,
    payload: tutorial,
  });
}

export default TutorialAction;
