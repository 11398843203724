import React from 'react';
import { css, cx } from 'emotion';
import { Icon } from 'antd';

import { colors, positionCenterStyle } from 'styles';

interface Props {
  color?: string;
  className?: string;
  fullScreen?: boolean;
  size?: number;
}

const Spinner: React.FC<Props> = ({ color = colors.primary.default, size = '2em', className, fullScreen = false }) => {
  const fullScreenStyle = css`
    ${positionCenterStyle};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  `;
  return (
    <div className={cx('spinner', fullScreen && fullScreenStyle, className)}>
      <Icon type="loading" style={{ fontSize: size, color }} />
    </div>
  );
};

export default Spinner;
