import { put, select } from 'redux-saga/effects';

import { Scenario, CameraMovementEvent } from 'models/interfaces/scenarios/scenario';
import ScenariosAction, { ScenarioTriggeredAction } from 'actions/scenarios/ScenariosAction';

import LayersAction from '../actions/layers/LayersAction';
import { CameraScriptAction } from '../actions/camera/CameraAction';
import { getCurrentScenario, getCurrentScenarioId, getDefaultScenarioId } from '../selectors/scenariosSelectors';
import { getCachedDataMap } from '../selectors/cachedDataSelectors';
import { CameraScriptRaw } from '../models/interfaces/Camera';
import { CachedData } from '../models/interfaces/api/CachedData';

// eslint-disable-next-line import/prefer-default-export
export function* handleCameraScriptSaga(action: ScenarioTriggeredAction) {
  if (action.payload.playCameraMovement) {
    const currentScenario: Scenario | undefined = yield select(getCurrentScenario);
    if (!currentScenario) {
      console.error(`Scenario: Scenario couldn't be found`);
      return;
    }
    const script = currentScenario.scripts.find((s) => s.type === 'cameraMovement') as CameraMovementEvent | undefined;
    if (!script) {
      console.warn(`Scenario: No cameraMovements found for scenarioId ${currentScenario.id}`);
      return;
    }
    const cameraScript: CameraScriptRaw = { cameraMovements: script.movement };

    yield put(CameraScriptAction.updateCameraScript(cameraScript));
    yield put(CameraScriptAction.startCameraScript());
  }
}

export function* updateCachedDataOnScenarioTriggerSaga(action: ScenarioTriggeredAction) {
  // Get current data map, we need to refresh everything that is stored
  const dataMap: Map<number, Map<number, CachedData>> = yield select(getCachedDataMap);
  const dataToFetch: Array<[number, number]> = [];
  dataMap.forEach((value: Map<number, CachedData>, layerId: number) => {
    value.forEach((_: CachedData, visId: number) => {
      dataToFetch.push([layerId, visId]);
    });
  });
  // Force fetch new data for each entry
  for (let i = 0; i < dataToFetch.length; i++) {
    const [layerId, visId] = dataToFetch[i];
    console.debug(`fetching layer: ${layerId} - visualization: ${visId}`);
    yield put(LayersAction.getLayerData(dataToFetch[i][0], dataToFetch[i][1], true));
  }
}

export function* resetScenarioSaga() {
  const currentScenarioId = yield select(getCurrentScenarioId);
  const defaultScenarioId = yield select(getDefaultScenarioId);
  if (currentScenarioId !== defaultScenarioId) {
    yield put(ScenariosAction.scenarioTriggered(defaultScenarioId, false));
  }
}
