export default `
#define SHADER_NAME color-look-up-fragment-shader

#ifdef GL_ES
precision highp float;
#endif

uniform sampler2D bitmapTexture;
uniform sampler2D colorLookUpTexture;

varying vec2 vTexCoord;

uniform float desaturate;
uniform vec4 transparentColor;
uniform vec3 tintColor;
uniform float opacity;

// apply desaturation
vec3 color_desaturate(vec3 color) {
  float luminance = (color.r + color.g + color.b) * 0.333333333;
  return mix(color, vec3(luminance), desaturate);
}

// apply tint
vec3 color_tint(vec3 color) {
  return color * tintColor;
}

void main(void) {
	vec4 bitmapColor = texture2D(bitmapTexture, vTexCoord);
	vec4 finalColor = texture2D(colorLookUpTexture, vec2(bitmapColor.r, 0));

	gl_FragColor = vec4(color_tint(color_desaturate(finalColor.rgb)), finalColor.a * opacity);

	if (gl_FragColor.a < 0.1)
		discard;

  geometry.uv = vTexCoord;
  DECKGL_FILTER_COLOR(gl_FragColor, geometry);
}
`;
