import { select, put, fork, takeEvery, call } from 'redux-saga/effects';

import { addIntervalToDate } from '../utils/timeUtils';
import LayersAction from '../actions/layers/LayersAction';
import LayersUIAction from '../actions/layers/LayersUIAction';
import DockAction from '../actions/dock/DockAction';
import AppAction from '../actions/app/AppAction';
import GlobalFilterAction from '../actions/global-filters/GlobalFilterAction';
import { getCurrentScenario } from '../selectors/scenariosSelectors';
import { getTimeInterval } from '../selectors/globalFiltersSelectors';
import { getAppMode } from '../selectors/appSelectors';
import { getLayerById } from '../api/layers';
import { Scenario } from '../models/interfaces/scenarios/scenario';
import LayerType from '../models/types/LayerType';
import { Layer } from '../models/interfaces/api/Layer';
import { AppMode } from '../models/types/AppMode';

function* fetchDemoLayersSaga() {
  const demoLayerIds = [2, 3, 9];
  try {
    // Set the time range
    const currentScenario: Scenario | undefined = yield select(getCurrentScenario);
    if (currentScenario !== undefined) {
      const newStart = new Date(currentScenario.timePeriod.start);
      const newEnd = new Date(currentScenario.timePeriod.end);
      const currentTimeInterval = yield select(getTimeInterval);
      // Dispatch time setting
      yield put(GlobalFilterAction.setDateRangeAction(newStart, newEnd));
      // Reset interval to the start
      const intervalEnd = addIntervalToDate(newStart, currentTimeInterval);
      yield put(GlobalFilterAction.setTimeIntervalAction(newStart, intervalEnd));
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const id of demoLayerIds) {
      const layer: Layer = yield call(getLayerById, id, LayerType.data);
      yield put(LayersUIAction.addLayer(layer));
      // *** Fetch data for all the layer's visualizations ***
      // eslint-disable-next-line no-restricted-syntax
      for (const visWrapper of layer.visualizations) {
        yield put(LayersAction.getLayerData(id, visWrapper.visualization.id));
      }
    }
  } catch (error) {
    console.error('[DEMOSAGA] Error: Something went wrong while fetching a demo layer.', error);
    yield put(LayersAction.getLayersFailed({ name: 'Error', message: error }));
  }
  // Enable default layer and map vis
  try {
    const cfLayerId = 2;
    const visualizationId = 5; // modality: motorized
    yield put(DockAction.toggleLayerVisualization(cfLayerId));
    yield put(DockAction.toggleLayerMapVisualization(cfLayerId, visualizationId));
  } catch (error) {
    console.error('[DEMOSAGA] Error: Something went wrong while enabling default layer', error);
  }
  // Enable pollutants vis
  try {
    const aqLayerId = 3; // aq
    const polVisualizationId = 7; // pollutants
    yield put(DockAction.toggleLayerMapVisualization(aqLayerId, polVisualizationId));
  } catch (error) {
    console.error('[DEMOSAGA] Error: Something went wrong while enabling default layer', error);
  }
  // Enable flooding risk vis
  try {
    const wLayerId = 9;
    const polVisualizationId = 11;
    yield put(DockAction.toggleLayerMapVisualization(wLayerId, polVisualizationId));
  } catch (error) {
    console.error('[DEMOSAGA] Error: Something went wrong while enabling default layer', error);
  }
}

function* handleGetDemoLayers() {
  const appMode = yield select(getAppMode);
  if (appMode === AppMode.DEMO) {
    yield fork(fetchDemoLayersSaga);
  }
}

export default function* demoSaga() {
  // DEMO MODE: will fetch CF/AQ data at init
  yield fork(handleGetDemoLayers);
  yield takeEvery(AppAction.Types.TOGGLE_MODE, handleGetDemoLayers);
}
