import { put, take } from 'redux-saga/effects';
import ScenariosAction from 'actions/scenarios/ScenariosAction';
import AppAction from 'actions/app/AppAction';

export function* botStartScenarios() {
  while (true) {
    const action = yield take(ScenariosAction.Types.TRIGGERED);
    if (action.payload.scenarioId > 0) {
      yield put(AppAction.startBotTrigger(action.payload.scenarioId));
    }
  }
}

export function* botStopScenarios() {
  while (true) {
    yield take([AppAction.Types.RESTART, AppAction.Types.IS_IDLE]);
    yield put(AppAction.startBotTrigger(1));
  }
}

export function* botClearGems() {
  while (true) {
    yield take([ScenariosAction.Types.TRIGGERED, AppAction.Types.RESTART, AppAction.Types.IS_IDLE]);
    yield put(AppAction.setActiveGems([]));
  }
}

export function* botClearAlertingLayers() {
  while (true) {
    yield take([ScenariosAction.Types.TRIGGERED, AppAction.Types.RESTART, AppAction.Types.IS_IDLE]);
    yield put(AppAction.setBotAlertingLayers([]));
  }
}

export function* botClearHistory() {
  while (true) {
    yield take([AppAction.Types.RESTART]);
    yield put(AppAction.clearBotHistory());
  }
}
