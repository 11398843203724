import { Action } from 'redux';
import LODMode from '../../models/types/LODMode';

enum App {
  TOGGLE_MODE = 'app/TOGGLE_MODE',
  SET_LOD_MODE = 'app/SET_LOD_MODE',
  TOGGLE_CAROUSEL = 'app/TOGGLE_CAROUSEL',
  IS_IDLE = 'app/IS_IDLE',
  RESTART = 'app/RESTART',
  START_BOT_TRIGGER = 'app/START_BOT_TRIGGER',
  SET_BOT_ALERTING_LAYERS = 'app/SET_BOT_ALERTING_LAYERS',
  RESUME_BOT_TRIGGER = 'app/RESUME_BOT_TRIGGER',
  TOGGLE_BOT = 'app/TOGGLE_BOT',
  STOP_BOT_TRIGGER = 'app/STOP_BOT_TRIGGER',
  CLEAR_BOT_HISTORY = 'app/CLEAR_BOT_HISTORY',
  SET_ACTIVE_GEMS = 'app/SET_ACTIVE_GEMS',
}

export interface ToggleModeAction {
  type: typeof App.TOGGLE_MODE;
}

export interface SetLODModeAction {
  type: typeof App.SET_LOD_MODE;
  payload: LODMode;
}

export interface ToggleCarousel {
  type: typeof App.TOGGLE_CAROUSEL;
}

export interface IsIdleAction {
  type: typeof App.IS_IDLE;
  payload: boolean;
}

export interface StartBotTriggerAction {
  type: typeof App.START_BOT_TRIGGER;
  payload: number;
}

export interface ResumeBotTriggerAction {
  type: typeof App.RESUME_BOT_TRIGGER;
  payload: number;
}

export interface SetBotAlertingLayersAction {
  type: typeof App.SET_BOT_ALERTING_LAYERS;
  payload: number[];
}

export interface StopBotTriggerAction {
  type: typeof App.STOP_BOT_TRIGGER;
}

export interface RestartAction {
  type: typeof App.RESTART;
}

export interface ToggleBotAction {
  type: typeof App.TOGGLE_BOT;
  payload: number;
}

export interface SetActiveGems {
  type: typeof App.SET_ACTIVE_GEMS;
  payload: any[];
}
export interface ClearBotHistoryAction {
  type: typeof App.CLEAR_BOT_HISTORY;
}

export type AppActionType =
  | ToggleModeAction
  | SetLODModeAction
  | ToggleCarousel
  | IsIdleAction
  | RestartAction
  | StartBotTriggerAction
  | StopBotTriggerAction
  | ResumeBotTriggerAction
  | ToggleBotAction
  | ClearBotHistoryAction
  | SetActiveGems
  | SetBotAlertingLayersAction;

export class AppAction implements Action<App> {
  type: App;

  static Types = App;

  constructor(type: App) {
    this.type = type;
  }

  static toggleMode = (): ToggleModeAction => ({
    type: App.TOGGLE_MODE,
  });

  static setLODMode = (lodMode: LODMode): SetLODModeAction => ({
    type: App.SET_LOD_MODE,
    payload: lodMode,
  });

  static resumeBotTrigger = (id: number): ResumeBotTriggerAction => ({
    type: App.RESUME_BOT_TRIGGER,
    payload: id,
  });

  static setBotAlertingLayers = (layerIds: number[]): SetBotAlertingLayersAction => ({
    type: App.SET_BOT_ALERTING_LAYERS,
    payload: layerIds,
  });

  static toggleBot = (id: number): ToggleBotAction => ({
    type: App.TOGGLE_BOT,
    payload: id,
  });

  static startBotTrigger = (id: number): StartBotTriggerAction => ({
    type: App.START_BOT_TRIGGER,
    payload: id,
  });

  static stopBotTrigger = (): StopBotTriggerAction => ({
    type: App.STOP_BOT_TRIGGER,
  });

  static clearBotHistory = (): ClearBotHistoryAction => ({
    type: App.CLEAR_BOT_HISTORY,
  });

  static setActiveGems = (data: any[]): SetActiveGems => ({
    type: App.SET_ACTIVE_GEMS,
    payload: data,
  });

  static toggleCarousel = (): ToggleCarousel => ({
    type: App.TOGGLE_CAROUSEL,
  });

  static isIdle = (isIdle: boolean): IsIdleAction => ({ type: App.IS_IDLE, payload: isIdle });

  static restart = (): RestartAction => ({ type: App.RESTART });
}

export default AppAction;
