import { Action } from 'redux';
import { FeatureCollection } from 'geojson';
import { ViewState } from 'react-map-gl';

enum Map {
  GET_BOUNDS = 'map/GET_BOUNDS',
  GET_BOUNDS_COMPLETED = 'map/GET_BOUNDS_COMPLETED',
  GET_BOUNDS_FAILED = 'map/GET_BOUNDS_FAILED',
  SET_VIEWSTATE = 'map/SET_VIEWSTATE',
}

export interface SetViewStateAction {
  type: typeof Map.SET_VIEWSTATE;
  payload: ViewState;
}

export interface GetBoundsAction {
  type: typeof Map.GET_BOUNDS;
}

export interface GetBoundsCompletedAction {
  type: typeof Map.GET_BOUNDS_COMPLETED;
  payload: FeatureCollection;
}

export interface GetBoundsFailedAction {
  type: typeof Map.GET_BOUNDS_FAILED;
  payload: Error;
  error: boolean;
}

export type MapActionType = SetViewStateAction | GetBoundsAction | GetBoundsCompletedAction | GetBoundsFailedAction;

export class MapAction implements Action<Map> {
  type: Map;

  static Types = Map;

  constructor(type: Map) {
    this.type = type;
  }

  static setViewState = (viewState: ViewState): SetViewStateAction => ({
    type: Map.SET_VIEWSTATE,
    payload: viewState,
  });

  static getBounds = (): GetBoundsAction => ({
    type: Map.GET_BOUNDS,
  });

  static getBoundsCompleted = (featureCollection: FeatureCollection): GetBoundsCompletedAction => ({
    type: Map.GET_BOUNDS_COMPLETED,
    payload: featureCollection,
  });

  static getBoundsFailed = (error: Error): GetBoundsFailedAction => ({
    type: Map.GET_BOUNDS_FAILED,
    payload: error,
    error: true,
  });
}

export default MapAction;
