import { css } from 'emotion';
import { colors, boxShadow, fontSize, rounded, fontFamily, transition, boxShadowBlue } from 'styles';

export const heightPrimaryToolbar = 56;
// TODO: move this to timeline component once it's implemented
export const heightTimeline = 80;

export const primaryToolbarWrapperStyle = css`
  position: absolute;
  bottom: calc(2rem + ${heightTimeline}px + 1rem);
  z-index: 1;
  display: flex;
  align-content: space-around;
  align-items: baseline;
  justify-content: center;
  font-family: ${fontFamily};
  width: 100%;
`;

export const primaryToolbarButtonStyle = css`
  height: auto;
  position: relative;
  display: flex;
  padding: 0.75rem 1rem;
  margin: 0 0.25rem;
  border: none;
  border-radius: ${rounded.md};
  font-size: ${fontSize.sm};
  background-color: ${colors.white};
  box-shadow: ${boxShadow.sm};
  transition: ${transition};
  user-select: none;
  transition: ${transition};
  cursor: pointer;
  outline: none;
  > .icon {
    /* border: 1px solid transparent; */
  }
  > .text {
    color: ${colors.gray[900]};
    margin-left: 0.5rem;
    line-height: 2rem;
    text-transform: capitalize;
  }
  &.active {
    background-color: ${colors.primary.default};
    box-shadow: ${boxShadowBlue};
    > .text {
      color: ${colors.white};
    }
    &:active {
      background-color: ${colors.primary.dark};
    }
  }
  &.not-active {
    > .text {
      color: ${colors.gray[400]};
    }
    &:hover {
      > .icon {
        box-shadow: none;
      }
    }
    &:active {
      background-color: ${colors.gray[300]};
    }
  }
  &:not(.active) {
    &:hover {
      background-color: ${colors.gray[200]};
    }
    &:active {
      background-color: ${colors.gray[300]};
    }
  }
`;
