/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
// / *********************************************************
// / COPY OF DECKGLS BITMAPLAYER, ADJUSTED FOR FLOATING POINT TEXTURES
// / *********************************************************
import GL from '@luma.gl/constants';
import { Layer, project32, picking } from '@deck.gl/core';
import { Model, Geometry, Texture2D } from '@luma.gl/core';

import vs from './color-lookup-geotiff-layer-vertex';
import fs from './color-lookup-geotiff-layer-fragment';

const DEFAULT_TEXTURE_PARAMETERS = {
  [GL.TEXTURE_MIN_FILTER]: GL.LINEAR_MIPMAP_LINEAR,
  [GL.TEXTURE_MAG_FILTER]: GL.LINEAR,
  [GL.TEXTURE_WRAP_S]: GL.CLAMP_TO_EDGE,
  [GL.TEXTURE_WRAP_T]: GL.CLAMP_TO_EDGE,
};

// It's recommended to use GL_NEAREST sampling for data textures
// However, we wanted  a blurrier effect. Leaving this snippet here in case you would like
// to load with these parameters
// const FLOATING_POINT_TEXTURE_PARAMETERS = {
//   ...DEFAULT_TEXTURE_PARAMETERS,
//   [GL.TEXTURE_MIN_FILTER]: GL.NEAREST,
//   [GL.TEXTURE_MAG_FILTER]: GL.NEAREST
// };

const defaultProps = {
  geotiffData: { type: 'object', value: null, async: true },
  // A texture of Width N: height: 1, to use for color look up
  colorLookUpImage: { type: 'object', value: null, async: true },
  bounds: { type: 'array', value: [1, 0, 0, 1], compare: true },
  domainMin: { type: 'number', value: 0 },
  domainMax: { type: 'number', value: 100 },
};

/*
 * @class
 * @param {object} props
 */
export default class ColorLookupGeoTiffLayer extends Layer {
  getShaders() {
    return super.getShaders({ vs, fs, modules: [project32, picking] });
  }

  initializeState() {
    const attributeManager = this.getAttributeManager();

    attributeManager.add({
      positions: {
        size: 3,
        type: GL.DOUBLE,
        fp64: this.use64bitPositions(),
        update: this.calculatePositions,
        noAlloc: true,
      },
    });

    this.setState({
      numInstances: 1,
      positions: new Float64Array(12),
    });
  }

  shouldUpdateState({ props, oldProps, context, oldContext, changeFlags }) {
    if (props.geotiffData !== oldProps.geotiffData || props.colorLookUpImage !== oldProps.colorLookUpImage) {
      return true;
    }
    const superResult = super.shouldUpdateState({ props, oldProps, context, oldContext, changeFlags });
    return superResult;
  }

  updateState({ props, oldProps, changeFlags }) {
    // setup model first
    if (changeFlags.extensionsChanged) {
      const { gl } = this.context;
      if (this.state.model) {
        this.state.model.delete();
      }
      this.setState({ model: this._getModel(gl) });
      this.getAttributeManager().invalidateAll();
    }

    // Load textures
    if (props.geotiffData !== oldProps.geotiffData) {
      this.loadGeoTiffData(props.geotiffData);
    }
    if (props.colorLookUpImage !== oldProps.colorLookUpImage) {
      this.loadColorLookUpTexture(props.colorLookUpImage);
    }

    const attributeManager = this.getAttributeManager();
    if (props.bounds !== oldProps.bounds) {
      attributeManager.invalidate('positions');
    }
  }

  finalizeState() {
    super.finalizeState();

    // Clean up the textures
    if (this.state.geoTiffDataTexture) {
      this.state.geoTiffDataTexture.delete();
    }
    if (this.state.colorLookUpTexture) {
      this.state.colorLookUpTexture.delete();
    }
  }

  calculatePositions(attributes) {
    const { positions } = this.state;
    const { bounds } = this.props;
    // bounds as [minX, minY, maxX, maxY]
    if (Number.isFinite(bounds[0])) {
      /*
        (minX0, maxY3) ---- (maxX2, maxY3)
               |                  |
               |                  |
               |                  |
        (minX0, minY1) ---- (maxX2, minY1)
     */
      positions[0] = bounds[0];
      positions[1] = bounds[1];
      positions[2] = 0;

      positions[3] = bounds[0];
      positions[4] = bounds[3];
      positions[5] = 0;

      positions[6] = bounds[2];
      positions[7] = bounds[3];
      positions[8] = 0;

      positions[9] = bounds[2];
      positions[10] = bounds[1];
      positions[11] = 0;
    } else {
      // [[minX, minY], [minX, maxY], [maxX, maxY], [maxX, minY]]
      for (let i = 0; i < bounds.length; i++) {
        positions[i * 3 + 0] = bounds[i][0];
        positions[i * 3 + 1] = bounds[i][1];
        positions[i * 3 + 2] = bounds[i][2] || 0;
      }
    }

    attributes.value = positions;
  }

  _getModel(gl) {
    if (!gl) {
      return null;
    }

    /*
      0,0 --- 1,0
       |       |
      0,1 --- 1,1
    */
    return new Model(gl, {
      ...this.getShaders(),
      id: this.props.id,
      geometry: new Geometry({
        drawMode: GL.TRIANGLE_FAN,
        vertexCount: 4,
        attributes: {
          texCoords: new Float32Array([0, 1, 0, 0, 1, 0, 1, 1]),
        },
      }),
      isInstanced: false,
    });
  }

  draw(opts) {
    const { uniforms } = opts;
    const { geoTiffDataTexture, model, colorLookUpTexture, domain } = this.state;

    // Render the image
    if (geoTiffDataTexture && model) {
      model
        .setUniforms({
          ...uniforms,
          geoTiffDataTexture,
          colorLookUpTexture,
          domainMin: domain[0],
          domainMax: domain[1],
        })
        .draw();
    }
  }

  loadGeoTiffData(geotiffData) {
    const { gl } = this.context;

    if (this.state.geoTiffDataTexture) {
      this.state.geoTiffDataTexture.delete();
    }
    // Load geo tiff data
    // It's single Float32Array, with one channel of data
    if (geotiffData) {
      this.setState({
        geoTiffDataTexture: new Texture2D(gl, {
          data: geotiffData[0],
          format: GL.R32F,
          width: geotiffData.width,
          height: geotiffData.height,
          parameters: DEFAULT_TEXTURE_PARAMETERS,
          mipmaps: true,
        }),
      });
      this.setState({ domain: geotiffData.domain });
    }
  }

  loadColorLookUpTexture(image) {
    const { gl } = this.context;

    if (this.state.colorLookUpTexture) {
      this.state.colorLookUpTexture.delete();
    }
    if (image instanceof Texture2D) {
      this.setState({ colorLookUpTexture: image });
    } else if (image) {
      // Browser object: Image, ImageData, HTMLCanvasElement, ImageBitmap
      this.setState({
        colorLookUpTexture: new Texture2D(gl, {
          data: image,
          parameters: DEFAULT_TEXTURE_PARAMETERS,
        }),
      });
    }
  }
}

ColorLookupGeoTiffLayer.layerName = 'ColorLookUpGeoTiffLayer';
ColorLookupGeoTiffLayer.defaultProps = defaultProps;
