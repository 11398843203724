import { Action } from 'redux';
import { Tag } from '../../models/interfaces/Tag';

enum Tags {
  GET = 'tags/GET',
  GET_COMPLETED = 'tags/GET_COMPLETED',
  GET_FAILED = 'tags/GET_FAILED',
  SET_FILTER = 'tag/SET_FILTER',
  CLEAR_FILTER = 'tag/CLEAR_FILTER',
}

export interface GetTagsAction {
  type: typeof Tags.GET;
}

export interface GetTagsCompletedAction {
  type: typeof Tags.GET_COMPLETED;
  payload: Tag[];
}

export interface GetTagsFailedAction {
  type: typeof Tags.GET_FAILED;
  payload: Error;
  error: boolean;
}

export interface SetTagFilterAction {
  type: typeof Tags.SET_FILTER;
  payload: number | null;
}

export interface ClearTagFilterAction {
  type: typeof Tags.CLEAR_FILTER;
}

export type TagsActionType =
  | GetTagsAction
  | GetTagsCompletedAction
  | GetTagsFailedAction
  | SetTagFilterAction
  | ClearTagFilterAction;

export class TagsAction implements Action<Tags> {
  type: Tags;

  static Types = Tags;

  constructor(type: Tags) {
    this.type = type;
  }

  static getTags = (): GetTagsAction => ({
    type: Tags.GET,
  });

  static getTagsCompleted = (tags: Tag[]): GetTagsCompletedAction => ({
    type: Tags.GET_COMPLETED,
    payload: tags,
  });

  static getTagsFailed = (error: Error): GetTagsFailedAction => ({
    type: Tags.GET_FAILED,
    payload: error,
    error: true,
  });

  static selectedTag = (selectedTagId: number): SetTagFilterAction => ({
    type: Tags.SET_FILTER,
    payload: selectedTagId,
  });

  static clearSelectedTag = (): ClearTagFilterAction => ({
    type: Tags.CLEAR_FILTER,
  });
}

export default TagsAction;
