import { createSelector } from 'reselect';
import { RootState } from '../reducers/rootReducer';

export const getApp = (state: RootState) => state.app;

export const getAppMode = createSelector(getApp, (app) => app.mode);
export const getLODMode = createSelector(getApp, (app) => app.lodMode);
export const getShowCarousel = createSelector(getApp, (app) => app.showCarousel);
export const isIdle = createSelector(getApp, (app) => app.isIdle);
export const activeBotTriggerId = createSelector(getApp, (app) => app.activeBotTriggerId);
export const botIsActive = createSelector(getApp, (app) => !!app.activeBotTriggerId);
export const getActiveGems = createSelector(getApp, (app) => app.activeGems);
export const getBotAlertingLayers = createSelector(getApp, (app) => app.alertingLayers);
