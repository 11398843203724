import React from 'react';
import { AutoComplete } from 'antd';
import { css, cx } from 'emotion';
import { SelectValue } from 'antd/lib/select';

import { colors, fontSize, fontFamily, rounded } from 'styles';
import { SearchResult, ResultItem } from './SearchResult';

const defaultStyle = css`
  pointer-events: auto;
  width: 100%;
  border-radius: ${rounded.md};
  height: 50px;
  .ant-select-selection {
    font-family: ${fontFamily};
    font-size: ${fontSize.lg};
    color: ${colors.gray[900]};
  }
`;

const dropdownStyle = css`
  .ant-select-dropdown-menu-item:first-of-type {
    > div {
      border-top: transparent;
    }
  }
`;

interface SearchFieldProps {
  placeholder: string;
  data: Array<ResultItem>;
  style?: React.CSSProperties;
  className?: string;
  onSearch: (query: string) => void;
  onSelect: (value: SelectValue) => void;
  onChange: (value: string | undefined) => void;
}

export const SearchField = (props: SearchFieldProps) => {
  const { data, onSearch, onSelect, onChange, placeholder } = props;
  return (
    <AutoComplete
      className={cx(defaultStyle, props.className)}
      style={props.style}
      dataSource={data.map(SearchResult)}
      allowClear
      defaultActiveFirstOption={false}
      dropdownClassName={dropdownStyle}
      autoFocus
      optionLabelProp="title"
      placeholder={placeholder}
      onSearch={onSearch}
      onSelect={onSelect}
      onChange={(value: SelectValue) => onChange(value === undefined ? undefined : `${value}`)}
    />
  );
};

export default SearchField;
