export const rounded = {
  xs: '0.125rem',
  sm: '0.25rem',
  md: '0.5rem',
  lg: '1rem',
  xl: '1.5rem',
  xxl: '2rem',
  full: '50%',
};

export const boxShadow = {
  sm: '0 2px 3px 0 rgba(136, 137, 160, 0.75)',
  md: '0 5px 5px 0 rgba(136, 137, 160, 0.75)',
  lg: '0 8px 8px 0 rgba(136, 137, 160, 0.75)',
};

export const boxShadowBlue = '0 0 10px 0 rgba(74, 144, 226, 0.75)';
export const transition = 'all 200ms ease';
