import { Layer as DeckGLLayer } from '@deck.gl/core';
import { GridWrapper } from '../../models/interfaces/api/GridWrapper';
import { CachedData } from '../../models/interfaces/api/CachedData';
import createFloodingRiskGridLayer from './createFloodingRiskGridLayer';

export default function createGridLayer(
  visualizationWrapper: GridWrapper,
  data: CachedData,
  currentStepIndex: number
): DeckGLLayer | undefined {
  switch (visualizationWrapper.visualization.id) {
    case 11:
      return createFloodingRiskGridLayer(visualizationWrapper, data, currentStepIndex);
    default:
      console.error(`ERROR: visualization id: ${visualizationWrapper.visualization.id} is not currently supported`);
  }
}
