import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import { SelectValue } from 'antd/lib/select';
import { css } from 'emotion';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { FeatureCollection } from 'geojson';
import i18n from 'i18n';

import SearchField from '../components/search/SearchField';
import OnScreenMapControlButton from '../components/map/OnScreenMapControlButton';
import { ResultItem, SearchResultCategories } from '../components/search/SearchResult';
import SearchAction from '../actions/search/SearchAction';
import SuggestionsAction from '../actions/search/SuggestionsAction';
import { getSearchSuggestionResult } from '../selectors/searchSelectors';

const searchStyle = css`
  display: flex;
  width: 400px;
  flex-direction: row;
  justify-content: flex-end;
  .search-wrapper {
    width: 100%;
    height: 100%;
  }
  .ant-select-auto-complete.ant-select .ant-select-search--inline {
    position: absolute;
  }
  .ant-select-auto-complete.ant-select .ant-select-selection--single {
    height: 100%;
  }
  .ant-select-auto-complete.ant-select .ant-input {
    height: 100%;
  }
  .search-button-wrapper {
    margin-left: 0.5rem;
    button.search-button {
      padding: 0.75rem;
    }
  }
`;

const parseResult = (data: Array<FeatureCollection>): Array<ResultItem> => {
  if (data.length === 0) {
    return [
      {
        key: 0,
        value: 'No Data Found',
        category: SearchResultCategories.none,
      },
    ];
  }

  const parsedData: Array<ResultItem> = data.reduce(
    (acc: Array<ResultItem>, current: FeatureCollection, idx: number) => {
      if (current.features[0].properties != null) {
        acc.push({
          key: idx,
          value: current.features[0].properties.name,
          category: current.features[0].properties.type,
          poi: current.features[0].properties.poi,
        });
      }
      return acc;
    },
    []
  );

  return parsedData;
};

const SearchContainer: React.FC = () => {
  const [searchBoxVisible, setSearchBoxVisible] = useState(false);
  const [inputValue, setInputValue] = useState<number | string>();
  const dispatch = useDispatch();
  const data = useSelector(getSearchSuggestionResult);

  const directSearch = (query: string) => {
    dispatch(SearchAction.startSearch(query));
  };

  const fetchSuggestions = (query: string) => {
    if (query.length === 0) return;
    dispatch(SuggestionsAction.getSuggestions(query));
  };

  const selectSuggestion = (value: SelectValue) => {
    const selectedSearchResult = data[value as number];
    dispatch(SuggestionsAction.selectSuggestion(selectedSearchResult));
  };

  const handleChange = (value: string | undefined) => {
    if (value === undefined) {
      dispatch(SuggestionsAction.clearSuggestion());
    } else {
      setInputValue(String(value));
    }
  };

  const handleSearchBoxToggle = () => {
    if (searchBoxVisible) {
      dispatch(SuggestionsAction.clearSuggestion());
    }
    setSearchBoxVisible(!searchBoxVisible);
  };

  return (
    <div className={searchStyle}>
      {searchBoxVisible && (
        <KeyboardEventHandler
          className="search-wrapper"
          handleKeys={['enter']}
          // when we enter an option it is still registered but returns a number, parsed as a string
          onKeyEvent={() => Number.isNaN(inputValue as number) && directSearch(String(inputValue))}
        >
          <SearchField
            placeholder={i18n.t('toolbars.controls.search.placeholder')}
            data={parseResult(data)}
            onSearch={fetchSuggestions}
            onSelect={selectSuggestion}
            onChange={handleChange}
          />
        </KeyboardEventHandler>
      )}
      <div className="search-button-wrapper">
        <OnScreenMapControlButton
          icon={<SVG src="icons/search.svg" />}
          title={i18n.t('toolbars.controls.search.alt')}
          onClick={handleSearchBoxToggle}
          className="search-button"
        />
      </div>
    </div>
  );
};

export default SearchContainer;
