import { parseInterval } from 'utils/timeUtils';
import { createTimeInterval } from './presetTimeIntervals';

const WINDOW_ENV = (window as any).env;

const retention: string | undefined = WINDOW_ENV?.CACHED_DATA_RETENTION;

const { length, unit } = parseInterval(retention);

// eslint-disable-next-line import/prefer-default-export
export const cachedDataRetention = createTimeInterval(unit, length);
