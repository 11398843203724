import { FeatureCollection } from 'geojson';
import { apiUrl } from 'config/api';
import { get } from './common';
import { Layer } from '../models/interfaces/api/Layer';
import { Tag } from '../models/interfaces/Tag';
import LayerType from '../models/types/LayerType';

export const search = async (term: string) => {
  return get<FeatureCollection[]>(`${apiUrl}/v1/maps/search?q=${term}`);
};

export const searchSuggestion = async (term: string) => {
  return get<FeatureCollection[]>(`${apiUrl}/v1/maps/search/suggestions?q=${term}&limit=5`);
};

export const searchCarouselLayers = async (layerType: LayerType, term: string) => {
  return get<Layer[]>(`${apiUrl}/v1/layers/${layerType}?q=${term}`);
};
export const searchCarouselTags = async (term: string) => {
  return get<Tag[]>(`${apiUrl}/v1/tags?q=${term}`);
};
