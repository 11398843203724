import { RootState } from '../reducers/rootReducer';
import { getCurrentLayer } from './dockSelectors';
import { CachedData } from '../models/interfaces/api/CachedData';

export const getCachedDataState = (state: RootState) => state.cachedData;

export const getCachedDataMap = (state: RootState) => getCachedDataState(state).dataMap;

export const getCurrentLayerCachedData = (state: RootState): Map<number, CachedData> => {
  const currentLayer = getCurrentLayer(state);
  if (!currentLayer) return new Map<number, CachedData>();
  return getCachedDataState(state).dataMap.get(currentLayer.id) ?? new Map<number, CachedData>();
};
