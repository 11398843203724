import React from 'react';
import SVG from 'react-inlinesvg';
import { css, cx } from 'emotion';

import styleConfig from 'styles/demo/config';
import Spinner from 'components/spinner/Spinner';

const { colors, margin, padding, borderRadius, lineHeight } = styleConfig;

const spinnerStyle = css`
  position: absolute;
  right: 0;
  top: 0;
  margin: ${margin[3]} ${margin[4]};
`;

const activeLayerButtonStyle = css`
  position: relative;
  display: flex;
  padding: ${padding[3]} ${padding[4]};
  border-radius: ${borderRadius.full};
  border: none;
  background-color: transparent;
  transition: all 100ms linear;
  overflow: hidden;
  cursor: pointer;
  outline: none;
  .icon {
    height: ${lineHeight.md};
    svg {
      height: ${lineHeight.md};
      width: 40px;
      padding: 0 ${padding[1]};
      path {
        ${colors.gray[200]};
      }
    }
  }
  .text {
    color: ${colors.gray[200]};
    line-height: ${lineHeight.md};
    font-weight: bold;
  }
  &.active {
    background-color: ${colors.gray[200]};
  }
  &:hover {
    background-color: ${colors.gray[300]};
  }
  &.active,
  &:hover {
    .icon svg path {
      fill: ${colors.black};
    }
    .text {
      color: ${colors.black};
    }
  }
  &:active {
    background-color: ${colors.gray[400]};
  }
`;
interface Props {
  icon: string;
  text: string;
  loading?: boolean;
  active?: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const ActiveLayerButton: React.FC<Props> = (props) => {
  return (
    <button
      type="button"
      disabled={props.disabled}
      className={cx(activeLayerButtonStyle, props.className, props.active && 'active')}
      onClick={() => {
        if (!props.disabled && props.onClick) props.onClick();
      }}
    >
      <div className="icon">
        <SVG src={props.icon} />
      </div>
      {props.loading && <Spinner color={colors.black} className={spinnerStyle} size={20} />}
      <div className="text">{props.text}</div>
    </button>
  );
};

export default ActiveLayerButton;
