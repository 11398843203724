import { Bounds } from 'viewport-mercator-project';
import { ViewState } from 'react-map-gl';
import { bboxToBounds } from '../utils/mapUtils';
import MapAction, { MapActionType } from '../actions/map/MapAction';

export interface MapState {
  boundingBox?: Bounds;
  initialViewBox?: Bounds;
  viewState?: ViewState;
}

const initialState: MapState = {};

const reducer = (state = initialState, action: MapActionType): MapState => {
  switch (action.type) {
    case MapAction.Types.SET_VIEWSTATE: {
      return {
        ...state,
        viewState: action.payload,
      };
    }
    case MapAction.Types.GET_BOUNDS_COMPLETED: {
      // from: https://github.com/uber-common/viewport-mercator-project/blob/master/src/fit-bounds.js
      const geojsonBounds = action.payload.bbox !== undefined ? bboxToBounds(action.payload.bbox) : undefined;
      const initialViewBox = action.payload.features.find((feature) => feature.id === 'InitialViewBox')?.bbox;
      return {
        ...state,
        boundingBox: geojsonBounds,
        // Try to find a InitialViewBox in the geojson, when not found use the bbox of the entire geojson featurecollection
        initialViewBox: initialViewBox !== undefined ? bboxToBounds(initialViewBox) : geojsonBounds,
      };
    }
    default:
      return state;
  }
};

export default reducer;
