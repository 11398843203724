import { ViewState } from 'react-map-gl';
import { FlyToInterpolator } from '@deck.gl/core';
import { Bounds, Coordinates } from 'viewport-mercator-project';
import { BBox } from 'geojson';
import { cubicInOut } from './easing';
import { MapConstraintsConfig } from '../config/map';

// clampViewState checks if the view is out of bounds and clamps if true - the clamping is essential btw
export const clampViewState = (viewState: ViewState, boundingBox: Bounds): ViewState => {
  const clampedCoordinates = clampCoordinates([viewState.latitude, viewState.longitude], boundingBox);

  return { ...viewState, latitude: clampedCoordinates[0], longitude: clampedCoordinates[1] };
};

// clampCoordinates clamps if the Coordinates are out of bounds and clamps if true
export const clampCoordinates = (coordinate: Coordinates, boundingBox: Bounds): Coordinates => {
  const latitude = clamp(coordinate[0], boundingBox[0][1], boundingBox[1][1]);
  const longitude = clamp(coordinate[1], boundingBox[0][0], boundingBox[1][0]);

  return [latitude, longitude];
};

export const zoomBoundariesReached = (zoom: number, minZoom: number, maxZoom: number) => {
  if (zoom < minZoom || zoom > maxZoom) return true;
  return false;
};

export const defaultTransitionEffect = (duration = 200) => ({
  transitionDuration: duration,
  transitionInterpolator: new FlyToInterpolator(),
  transitionEasing: cubicInOut,
});

// bboxToBounds converts a geojson bounding box to 'viewport-mercator' bounds
export const bboxToBounds = (bbox: BBox): Bounds => {
  if (bbox.length === 4) {
    return [
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]],
    ];
  }
  return [
    [bbox[0], bbox[1]],
    [bbox[3], bbox[4]],
  ];
};

export function constrainViewState(mapConstraints: MapConstraintsConfig, viewState: ViewState): ViewState {
  return {
    ...viewState,
    zoom: clamp(viewState.zoom, mapConstraints.minZoom, mapConstraints.maxZoom),
  };
}

export const clamp = (val: number, min: number, max: number) => Math.min(Math.max(val, min), max);
