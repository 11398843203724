import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LANGUAGE } from 'config/language';
import { resources } from './resources/translate';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    keySeparator: false,
    lng: DEFAULT_LANGUAGE.locale,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
