import React, { useRef, useEffect, useState, RefObject } from 'react';
import { Layer, MapController, LightingEffect } from '@deck.gl/core';
import { MVTLayer, Tile3DLayer } from '@deck.gl/geo-layers';
import { DeckGL } from '@deck.gl/react';
import { CesiumIonLoader } from '@loaders.gl/3d-tiles';
import WebMercatorViewport, { Bounds, fitBounds } from 'viewport-mercator-project';
import { StaticMap } from 'react-map-gl';
import { registerLoaders } from '@loaders.gl/core';
import { DracoWorkerLoader } from '@loaders.gl/draco';
import { FeatureCollection, Feature } from 'geojson';
import { useTranslation } from 'react-i18next';

import {
  MAP_CONFIG,
  AMBIENT_LIGHT,
  DIRECTIONAL_LIGHT,
  MOON_LIGHT,
  ionAssetId,
  ionAccessToken,
  mapboxBuildingsLayer,
} from 'config/map';
import { ViewStateWithTransition } from 'containers/MapContainer';
import { css } from 'emotion';
import { colors } from 'styles';
import { styleConfig } from 'styles/demo';
import { transparentize } from 'polished';
import { useSelector } from 'react-redux';
import { zoomBoundariesReached, clampViewState, defaultTransitionEffect, bboxToBounds } from '../../utils/mapUtils';
import Spinner from '../spinner/Spinner';
import OnScreenMapControls from './OnScreenMapControls';
import useCameraScript from '../../hooks/useCameraScript';
import LODMode from '../../models/types/LODMode';
import { DeckGLPickedInfo } from '../../models/interfaces/deckgl/DeckGLPickedInfo';
import { CameraScript } from '../../models/interfaces/Camera';
import { getActiveGems } from '../../selectors/appSelectors';

registerLoaders([DracoWorkerLoader]);

// create lighting effect with light sources
const lightingEffect = new LightingEffect({ AMBIENT_LIGHT, DIRECTIONAL_LIGHT, MOON_LIGHT });

const popupStyle = css`
  background-color: ${transparentize(0.9, styleConfig.colors.black)};
  border-radius: ${styleConfig.borderRadius.xl};
  box-shadow: ${styleConfig.boxShadow.md};
  position: absolute;
  width: 298px;
  margin: ${styleConfig.margin[2]};

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    padding: ${styleConfig.margin[4]};
  }
  article {
    width: 100%;
    transition: all 0.5s ease-out;
  }

  .content > article:not(:last-of-type) {
    margin-bottom: ${styleConfig.margin[4]};
  }

  article > p {
    color: ${colors.text};
    white-space: pre-line;
    background-color: white;
    border-radius: ${styleConfig.borderRadius.xl};
    box-shadow: ${styleConfig.boxShadow.md};
    padding: ${styleConfig.padding[4]};
    margin: 0;
  }
`;
interface OnViewStateChange {
  viewState: ViewStateWithTransition;
  interactionState: {
    inTransition: boolean;
    isDragging: boolean;
    isPanning: boolean;
    isRotating: boolean;
    isZooming: boolean;
  };
  oldViewState: ViewStateWithTransition;
}

interface MapState {
  viewState: ViewStateWithTransition | null;
  threeD: boolean;
  boundingBox?: Bounds;
}

interface Props {
  layers: Layer[];
  lodMode: LODMode;
  showScreenControls: boolean;
  boundingBox?: Bounds;
  initialViewBox: Bounds;
  focusGeoJson?: FeatureCollection;
  cameraScript?: CameraScript;
  handleCameraScriptFinished?: () => void;
  handleCameraScriptInterrupted?: () => void;
  handleViewState?: (viewState: ViewStateWithTransition) => void;
  handleChangeLODMode?: () => void;
  onClick?: (pickedInfo: DeckGLPickedInfo<Record<string, object | string | number>>, event: object) => boolean;
}

const Map: React.FC<Props> = (props) => {
  const viewportRef: RefObject<WebMercatorViewport> = useRef<DeckGL>(null);
  const mapRef = useRef<StaticMap>(null);

  const { boundingBox, initialViewBox, focusGeoJson, showScreenControls } = props;

  const [layers, setLayers] = useState(props.layers);
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [LOD1, setLOD1] = useState<boolean>(true);
  const [LOD2, setLOD2] = useState<boolean>(false);
  const [internalViewState, setInternalViewState] = useState<MapState>({
    viewState: null,
    boundingBox,
    threeD: Number(MAP_CONFIG.pitch) > 0,
  });
  const isLodModeAuto = props.lodMode === LODMode.AUTOMATIC;
  const { t } = useTranslation();
  const activeGems = useSelector(getActiveGems);

  const [infoPopup, setInfoPopup] = useState<any>(undefined);
  /**
   * Send the current view state outside this component to handleViewState prop
   * @param viewState (optional)
   * */
  const sendViewStateToProps = (viewState?: ViewStateWithTransition) => {
    if (!props.handleViewState) return;
    if (viewState) {
      props.handleViewState(viewState);
    } else if (internalViewState.viewState) {
      props.handleViewState(internalViewState.viewState);
    }
  };

  const setViewState = (viewState: ViewStateWithTransition) => {
    setInternalViewState({
      ...internalViewState,
      viewState: {
        ...viewState,
        onTransitionEnd: () => {
          sendViewStateToProps();
          viewState.onTransitionEnd && viewState.onTransitionEnd();
        },
      },
      threeD,
    });
  };

  // map event handlers
  const onViewStateChange = ({ viewState, interactionState, oldViewState }: OnViewStateChange) => {
    // checks if one of the map interactions is ended (unfortunately, doesnt not include scrolling with mouse)
    const mapInteractionsEnd = Object.values(interactionState).some((interaction) => !interaction);
    if (mapInteractionsEnd) {
      sendViewStateToProps(viewState);
      if (isLodModeAuto) {
        setLOD1(viewState.zoom <= 16);
        setLOD2(viewState.zoom > 16);
      }
    }
    if (viewState.zoom.toFixed(1) !== oldViewState.zoom.toFixed(1)) {
      sendViewStateToProps(viewState);
      if (isLodModeAuto) {
        setLOD1(viewState.zoom <= 16);
        setLOD2(viewState.zoom > 16);
      }
    }
    if (!internalViewState.boundingBox || !internalViewState.viewState) {
      setViewState(viewState);
      return;
    }
    // check if minZoom is reached, if so, we just want to escape the change values unless a camera script is doing it
    // a camera script with a flyTo interpolator can go beyond the boundaries for a short while
    if (
      zoomBoundariesReached(viewState.zoom, MAP_CONFIG.minZoom, MAP_CONFIG.maxZoom) &&
      !isCameraScriptRunning(props.cameraScript)
    ) {
      setInternalViewState({
        ...internalViewState,
        viewState: { ...internalViewState.viewState, zoom: MAP_CONFIG.minZoom },
      });
      return;
    }
    // check out of bounds and clamp if needed
    setInternalViewState({
      ...internalViewState,
      viewState: clampViewState(viewState, internalViewState.boundingBox),
    });
  };

  const { viewState, threeD } = internalViewState;

  const onFinished = () => props.handleCameraScriptFinished && props.handleCameraScriptFinished();
  const onInterrupt = () => props.handleCameraScriptInterrupted && props.handleCameraScriptInterrupted();
  useCameraScript({
    viewState,
    setViewState,
    script: props.cameraScript,
    mapLoaded,
    mapConfig: MAP_CONFIG,
    onInterrupt,
    onFinished,
  });

  const onZoom = (value: number) => {
    if (!viewState) return;
    setInternalViewState({
      ...internalViewState,
      viewState: {
        ...viewState,
        zoom: zoomBoundariesReached(viewState.zoom + value, MAP_CONFIG.minZoom, MAP_CONFIG.maxZoom)
          ? viewState.zoom
          : viewState.zoom + value,
        ...defaultTransitionEffect(100),
      },
    });
  };

  const onResetBearing = () => {
    if (!viewState) return;
    setInternalViewState({
      ...internalViewState,
      viewState: {
        ...viewState,
        bearing: 0,
        ...defaultTransitionEffect(400),
      },
    });
  };

  const onBuildingsToggle = () => {
    if (!viewState) return;
    const new3DVal = !threeD;
    setInternalViewState({
      ...internalViewState,
      threeD: new3DVal,
      viewState: {
        ...viewState,
        pitch: new3DVal ? MAP_CONFIG.threeDPitch : 0,
        ...defaultTransitionEffect(400),
      },
    });
    if (props.handleChangeLODMode) props.handleChangeLODMode();
  };

  const handleMapOnLoad = () => {
    setMapLoaded(true);
  };

  const onMapInteraction = (info: any) => {
    setInfoPopup(undefined);

    if (info.picked && info.object) {
      setInfoPopup(info);
    }
  };

  useEffect(() => {
    setInfoPopup(undefined);
  }, [activeGems]);

  const renderTooltip = (info: any) => {
    const { object, x, y } = info;
    if (!object) return null;

    return (
      <div className={popupStyle} style={{ left: x, top: y }}>
        <div className="content">
          <article>
            <p>{t(object.info)}</p>
          </article>
        </div>
      </div>
    );
  };

  useEffect(() => {
    // from: https://github.com/uber-common/viewport-mercator-project/blob/master/src/fit-bounds.js
    const fitView = fitBounds({
      bounds: initialViewBox,
      height: window.innerHeight,
      width: window.innerWidth,
      padding: MAP_CONFIG.geoBoundsPadding,
    });
    // Set maximum zoom out distance based on fitting bounding box
    MAP_CONFIG.minZoom = fitView.zoom;
    MAP_CONFIG.bounds = initialViewBox;
    MAP_CONFIG.initialViewState.latitude = fitView.latitude;
    MAP_CONFIG.initialViewState.longitude = fitView.longitude;
    MAP_CONFIG.initialViewState.zoom = fitView.zoom;
    setInternalViewState((currentInternalViewState: MapState) => {
      const newViewState = {
        ...currentInternalViewState.viewState,
        latitude: fitView.latitude,
        longitude: fitView.longitude,
        zoom: Math.max(fitView.zoom, MAP_CONFIG.minZoom),
        bearing: MAP_CONFIG.initialViewState.bearing,
        pitch: MAP_CONFIG.initialViewState.pitch,
      };
      sendViewStateToProps(newViewState);
      return { ...currentInternalViewState, boundingBox, viewState: newViewState };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boundingBox, setInternalViewState]);

  useEffect(() => {
    if (!focusGeoJson || !focusGeoJson.bbox) return;
    const fitView = fitBounds({
      bounds: bboxToBounds(focusGeoJson.bbox),
      height: window.innerHeight,
      width: window.innerWidth,
    });
    setInternalViewState((currentInternalViewState: MapState) => {
      return {
        ...currentInternalViewState,
        viewState: {
          ...fitView,
          ...defaultTransitionEffect(1500),
        },
      };
    });
  }, [focusGeoJson, setInternalViewState, viewportRef]);

  /**
   * Set LOD layers
   */
  useEffect(() => {
    const LOD2Layer = new Tile3DLayer({
      id: 'lod2-mesh-layer',
      _subLayerProps: {
        scenegraph: {
          _lighting: 'pbr',
        },
      },
      opacity: 0.7,
      loader: CesiumIonLoader,
      data: `https://assets.cesium.com/${ionAssetId}/tileset.json`,
      loadOptions: {
        'cesium-ion': {
          accessToken: ionAccessToken,
        },
      },
      onTileError: (tileHeader: unknown, url: string, message: string) => {
        console.error(`[LOD2Layer] Failed to load ${url} with message: ${message}`);
      },
    });

    const LOD1Layer = new MVTLayer({
      id: 'lod1-vector-layer',
      data: `https://cdne-cities-assets.azureedge.net/buildings/grb-lod1-tiled/{z}/{x}/{y}.pbf`,
      minZoom: 0,
      maxZoom: 15,
      binary: true,

      extruded: true,
      getElevation: (d: Feature) => d?.properties?.HN_P99 ?? 10,
      // This matches with the material color and opacity of LOD2
      getFillColor: [255, 255, 255, 178],
      // The MVTLayer uses a totally different material shader model compared to the 3D tiles
      // Therefore the material was handtuned to keep the look consistent
      material: {
        ambient: 0.5,
        diffuse: 0.3,
        shininess: 128,
        specularColor: [0, 0, 0],
      },
    });

    if (!mapLoaded) return;

    const map = mapRef.current?.getMap();
    if (isLodModeAuto) {
      if (LOD1) {
        setLayers([...props.layers, LOD1Layer]);
      } else if (LOD2) {
        setLayers([...props.layers, LOD2Layer]);
      } else {
        setLayers(props.layers);
      }
    } else {
      if (props.lodMode !== LODMode.MAPBOX && map?.getLayer('3d-buildings')) {
        map.removeLayer('3d-buildings');
      }
      switch (props.lodMode) {
        case LODMode.LOD0:
          setLayers(props.layers);
          return;
        case LODMode.LOD1:
          setLayers([...props.layers, LOD1Layer]);
          return;
        case LODMode.LOD2:
          setLayers([...props.layers, LOD2Layer]);
          return;
        case LODMode.MAPBOX: {
          setLayers(props.layers);
          if (map === undefined || map.getLayer('3d-buildings')) return;
          map.addLayer(mapboxBuildingsLayer);
          return;
        }
        default:
          setLayers(props.layers);
          break;
      }
    }
  }, [mapLoaded, LOD1, LOD2, threeD, props.layers, props.lodMode, isLodModeAuto]);

  const [controller] = React.useState({
    type: MapController,
    touchRotate: true,
  });

  if (boundingBox === null) return null;
  return (
    <DeckGL
      ref={viewportRef}
      controller={controller}
      viewState={viewState}
      onViewStateChange={onViewStateChange}
      layers={layers}
      effects={[lightingEffect]}
      onClick={onMapInteraction}
      onDragStart={onMapInteraction}
      touchRotate
    >
      {props.children}
      <StaticMap
        key="mapboxStaticMap"
        ref={mapRef}
        onLoad={handleMapOnLoad}
        width={window.innerWidth}
        height={window.innerHeight}
        mapStyle={MAP_CONFIG.mapStyle.light}
        mapboxApiAccessToken={MAP_CONFIG.token}
      >
        {!mapLoaded && <Spinner fullScreen />}
      </StaticMap>
      {showScreenControls && (
        <OnScreenMapControls
          onZoom={onZoom}
          onResetBearing={onResetBearing}
          on3DToggle={onBuildingsToggle}
          threeD={threeD}
          bearing={viewState?.bearing}
        />
      )}
      {infoPopup && renderTooltip(infoPopup)}
    </DeckGL>
  );
};

function isCameraScriptRunning(cameraScript: CameraScript | undefined): boolean {
  return !!cameraScript && cameraScript.cameraMovements && cameraScript.cameraMovements.length > 0;
}

export default Map;
