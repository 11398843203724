import { project } from '@deck.gl/core';
import inject from './Water-shader.glsl';

// getUniforms maps JS parameter keys to uniforms
// Should return a JS object with keys representing shader uniform names and with values representing uniform names
function getUniforms(opts, context) {
  if (!opts) {
    return {};
  }

  if ('normapMapTexture' in opts) {
    return {
      normalTexture: opts.normapMapTexture,
      time: opts.time,
      primaryWavesScales: opts.primaryWavesScales,
      secondaryWavesScales: opts.secondaryWavesScales,
      primaryWavesScrollSpeeds: opts.primaryWavesScrollSpeeds,
      secondaryWavesScrollSpeeds: opts.secondaryWavesScrollSpeeds,
    };
  }
  if (opts.viewport) {
    return {
      sunDirection: context['lighting_uDirectionalLight[0].direction'],
      sunColor: context['lighting_uDirectionalLight[0].color'],
      ambientColor: context['lighting_uAmbientLight.color'].map((val) => val * context.lighting_uAmbient),
    };
  }
  return {};
}

// Water module declaration
const Water = {
  name: 'water',
  inject,
  dependencies: [project],
  getUniforms,
};

export default Water;
