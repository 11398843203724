import React from 'react';
import { Avatar } from 'antd';
import { UserOutline } from '@ant-design/icons';
import AntdIcon from '@ant-design/icons-react';
import { css } from 'emotion';
import { BotAction } from 'models/interfaces/bot/BotAction';
import { styleConfig } from 'styles/demo';
import BotTextFragment from './BotTextFragment';
import BotActionsFragment from './BotActionsFragment';

const { margin } = styleConfig;

AntdIcon.add(UserOutline);

interface Props {
  text: string;
  actions: BotAction[];
}

const containerStyle = css`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: ${margin[2]};
  svg {
    display: inline !important;
  }
`;
const BotScreenFragment: React.FC<Props> = (props) => {
  return (
    <div className={containerStyle}>
      <Avatar size="large" icon={<AntdIcon type="user-o" />} />
      <BotTextFragment text={props.text} />
      <BotActionsFragment actions={props.actions} />
    </div>
  );
};

export default BotScreenFragment;
