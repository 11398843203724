import React from 'react';
import SVG from 'react-inlinesvg';
import { AutoComplete } from 'antd';
import { css } from 'emotion';
import { colors, fontSize, fontFamily } from 'styles';

const { Option } = AutoComplete;
export interface ResultItem {
  key: number;
  value: string;
  category: SearchResultCategories;
  poi?: {
    name: string;
  };
}

export enum SearchResultCategories {
  none = 'none',
  geography = 'geography',
  street = 'street',
  poi = 'poi',
}

const optionsStyle = css`
  font-family: ${fontFamily};
  font-size: ${fontSize.lg};
  color: ${colors.gray[900]};
  margin: 0;
  padding: 0 1rem;
  > div {
    > p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0;
    }
    border-top: 1px solid ${colors.gray[200]};
  }
  &.ant-select-dropdown-menu-item:hover {
    color: ${colors.primary.default};
    background-color: transparent;
    svg path {
      fill: 'red';
    }
  }
  .option-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.75rem 0;
    .icon-wrapper {
      width: 18px;
      height: 18px;
      margin-right: 0.5rem;
      display: flex;
      svg.icon path {
        fill: ${colors.gray[800]};
        height: 14px;
      }
    }
    .title-wrapper {
      display: flex;
      flex-direction: column;
      .subtitle {
        font-size: ${fontSize.sm};
        color: ${colors.gray[400]};
      }
    }
  }
`;

const categoryIcon = (category: SearchResultCategories): JSX.Element | undefined => {
  switch (category) {
    case SearchResultCategories.geography:
      return <SVG className="icon" src="icons/district.svg" />;
    case SearchResultCategories.street:
      return <SVG className="icon" src="icons/street.svg" />;
    case SearchResultCategories.poi:
      return <SVG className="icon" src="icons/poi.svg" />;
    default:
      break;
  }
};

export const SearchResult = (item: ResultItem) => {
  const poiName = item.poi && item.poi.name;
  return (
    <Option
      key={item.key}
      title={item.value}
      className={optionsStyle}
      disabled={item.category === SearchResultCategories.none}
    >
      <div className="option-wrapper">
        <div className="icon-wrapper">{categoryIcon(item.category)}</div>
        <div className="title-wrapper">
          <span>{poiName || item.value}</span>
          {poiName && <span className="subtitle">{item.value}</span>}
        </div>
      </div>
    </Option>
  );
};
