import { Action } from 'redux';
import { Layer } from '../../models/interfaces/api/Layer';
import LayerType from '../../models/types/LayerType';

enum LayerUI {
  ADD_LAYER = 'dock/ADD_LAYER',
  REMOVE_LAYER = 'dock/REMOVE_LAYER',
}

export interface AddLayerAction {
  type: typeof LayerUI.ADD_LAYER;
  payload: Layer;
}

export interface RemoveLayerAction {
  type: typeof LayerUI.REMOVE_LAYER;
  payload: { id: number; layerType: LayerType };
}

export type LayersUIActionType = AddLayerAction | RemoveLayerAction;

export class LayersUIAction implements Action<LayerUI> {
  type: LayerUI;

  static Types = LayerUI;

  constructor(type: LayerUI) {
    this.type = type;
  }

  static addLayer = (layer: Layer): AddLayerAction => ({
    type: LayerUI.ADD_LAYER,
    payload: layer,
  });

  static removeLayer = (id: number, layerType: LayerType): RemoveLayerAction => ({
    type: LayerUI.REMOVE_LAYER,
    payload: { id, layerType },
  });
}

export default LayersUIAction;
