import React from 'react';
import { Modal as AntdModal } from 'antd';
import { css } from 'emotion';

import { styleConfig, cardStyle } from 'styles/demo';

const { colors, padding, fontSize, letterSpacing, borderRadius, margin } = styleConfig;

interface Props {
  title: string;
  visible: boolean;
  handleClose: () => void;
  footer?: JSX.Element;
}

const modalStyle = css`
  width: 100% !important;
  border-radius: ${borderRadius.xl};
  margin: 100px auto;
  overflow: hidden;
  .ant-modal-content {
    ${cardStyle}
    height: 100% !important;
    .ant-modal-close-x {
      color: ${colors.gray[200]};
      height: 3rem;
      line-height: 3rem;
      &:hover {
        color: ${colors.gray[400]};
      }
    }
  }
  .ant-modal-body {
    ${cardStyle}
    border-radius: 0px;
    font-size: ${fontSize.sm};
    color: ${colors.gray[200]};
    margin: 0;
    line-height: 1.5rem;
    h2 {
      margin: ${margin[4]} 0 ${margin[2]} 0;
      font-weight: bold;
      font-size: ${fontSize.md};
      color: ${colors.gray[200]};
      letter-spacing: ${letterSpacing.md};
      text-transform: uppercase;
    }
    padding: ${padding[8]} ${padding[16]} ${padding[4]};
  }
  .ant-modal-header {
    ${cardStyle}
    border-radius: 0;
    border: none;
    background-color: ${colors.gray[700]};
    .ant-modal-title {
      font-size: ${fontSize.md};
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      color: ${colors.gray[200]};
      margin: 0;
      letter-spacing: ${letterSpacing.md};
      line-height: 1rem;
    }
  }
  .ant-modal-footer {
    ${cardStyle}
    border: none;
    padding: ${padding[4]};
    .ant-btn {
      border: none;
      border-radius: ${borderRadius.full};
      background-color: ${colors.gray[200]};
      transition: all 100ms linear;
      overflow: hidden;
      cursor: pointer;
      width: 100px;
      color: ${colors.black};
      font-weight: bold;
      font-size: ${fontSize.xs};
      &:hover {
        background-color: ${colors.gray[300]};
      }
    }
  }
  @media (min-width: 768px) {
    width: 720px !important;
  }
`;

const Modal: React.FC<Props> = (props) => {
  return (
    <AntdModal
      centered
      className={modalStyle}
      title={props.title}
      footer={props.footer}
      visible={props.visible}
      onCancel={props.handleClose}
      closeIcon="×"
    >
      {props.children}
    </AntdModal>
  );
};

export default Modal;
