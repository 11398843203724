import 'antd/dist/antd.css';
import { HashRouter as Router, Switch, Route, useParams } from 'react-router-dom';
import { injectGlobal } from 'emotion';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import * as Sentry from '@sentry/react';

import { isProd, idleTime, botIdleTime } from 'config/app';
import { DEFAULT_LANGUAGE } from 'config/language';

import Languages from 'models/types/Languages';
import LanguageAction from 'actions/language/LanguageAction';
import AppAction from 'actions/app/AppAction';

import LocationReference from 'components/location-reference/LocationReference';

import TimelineDemoContainer from 'containers/TimelineDemoContainer';
import RestartContainer from 'containers/RestartContainer';
import ActiveDomainContainer from 'containers/ActiveDomainContainer';
import PartnerLogosContainer from 'containers/PartnerLogosContainer';
import ScenarioTriggerContainer from 'containers/ScenarioTriggerContainer';
import MapContainer from 'containers/MapContainer';
import BotWidgetContainer from 'containers/BotWidgetContainer';
import ControlsContainer from 'containers/debug/ControlsContainer';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const { lng } = useParams<{ lng: string }>();
  let language = DEFAULT_LANGUAGE;
  if (lng) {
    switch (lng.toLowerCase()) {
      case Languages.EN.locale:
        language = Languages.EN;
        break;
      case Languages.NL.locale:
        language = Languages.NL;
        break;
      case Languages.AR.locale:
        language = Languages.AR;
        break;
      default:
        language = DEFAULT_LANGUAGE;
    }
  }

  dispatch(LanguageAction.changeLanguage(language));
  dispatch(AppAction.resumeBotTrigger(1));
  const { t } = useTranslation();

  const onIdle = () => dispatch(AppAction.isIdle(true));

  const onActive = () => dispatch(AppAction.isIdle(false));

  const restart = useCallback(() => {
    dispatch(AppAction.restart());
  }, [dispatch]);

  useIdleTimer({
    timeout: idleTime * 1000,
    onIdle,
    onActive,
    debounce: 500,
  });

  useIdleTimer({
    timeout: botIdleTime * 1000,
    onIdle: () => {
      dispatch(AppAction.resumeBotTrigger(6));
    },
  });
  // eslint-disable-next-line no-unused-expressions
  injectGlobal`
    * {
      user-select: none;
    }
  `;
  return (
    <>
      <MapContainer />
      <RestartContainer onClick={restart} />
      <ActiveDomainContainer />
      <TimelineDemoContainer />
      <PartnerLogosContainer />
      <LocationReference city={t('title.label.antwerp')} country={t('title.label.belgium')} />
      <ScenarioTriggerContainer />
      <BotWidgetContainer />
      {!isProd && <ControlsContainer />}
    </>
  );
};

const TranslatedApp = () => {
  return (
    <Router>
      <Switch>
        <Route path="/:lng" component={App} />
        <Route path="/" exact component={App} />
      </Switch>
    </Router>
  );
};

export default Sentry.withProfiler(TranslatedApp);
