import { Action } from 'redux';
import { Language as LanguageModel } from '../../models/interfaces/languages/Language';

enum Language {
  CHANGE = 'language/CHANGE',
  CHANGED = 'language/CHANGED',
}

export interface ChangeLanguageAction {
  type: typeof Language.CHANGE;
  payload: LanguageModel;
}
export interface LanguageChangedAction {
  type: typeof Language.CHANGED;
  payload: LanguageModel;
}

export type LanguageActionType = ChangeLanguageAction | LanguageChangedAction;

export class LanguageAction implements Action<string> {
  type: Language;

  static Types = Language;

  constructor(type: Language) {
    this.type = type;
  }

  static changeLanguage = (lng: LanguageModel): ChangeLanguageAction => ({
    type: Language.CHANGE,
    payload: lng,
  });

  static languageChanged = (lng: LanguageModel): LanguageChangedAction => ({
    type: Language.CHANGED,
    payload: lng,
  });
}

export default LanguageAction;
