export const fontFamily = `Raleway, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, Arial, sans-serif`;
export const fontSize = {
  xs: '10px',
  sm: '14px',
  md: '16px',
  lg: '18px',
  xl: '24px',
};
export const lineHeight = {
  sm: '1.2em',
  md: '1.5em',
  lg: '1.7em',
  xl: '2em',
};
export const letterSpacing = {
  sm: '0.03em',
  md: '0.05em',
  lg: '0.07em',
};
