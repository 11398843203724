import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { isIdle } from 'selectors/appSelectors';

import ImecInfoScreenContent from 'metadata/ImecInfoScreenContent';

import PartnerLogos from 'components/logo/PartnerLogos';
import Modal from 'components/modal/Modal';

import DisclaimerContainer from 'containers/DisclaimerContainer';

const logos = ['./images/logos/vito.svg'];
const imecButton = 'images/logos/imec.svg';

const PartnerLogosContainer: React.FC = () => {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(useLocation().search); // useLocation can only be used inside a component declaration
  const kioskMode = urlParams.has('kiosk') && urlParams.get('kiosk') !== 'false';

  const [imecInfoVisible, setImecInfoVisible] = useState<boolean>(false);
  const idle = useSelector(isIdle);
  useEffect(() => {
    if (idle) {
      setImecInfoVisible(false);
    }
  }, [idle]);
  return (
    <>
      <PartnerLogos
        logos={logos}
        button={imecButton}
        onClick={() => setImecInfoVisible(true)}
        footer={<DisclaimerContainer />}
      />
      <Modal
        title={t('infoscreen.imec.header')}
        visible={imecInfoVisible}
        handleClose={() => setImecInfoVisible(false)}
      >
        <ImecInfoScreenContent kioskMode={kioskMode} />
      </Modal>
    </>
  );
};

export default PartnerLogosContainer;
