import { FeatureCollection } from 'geojson';
import SearchAction, { SearchActionType } from '../actions/search/SearchAction';
import SuggestionsAction, { SuggestionsActionType } from '../actions/search/SuggestionsAction';

export interface SearchState {
  loading: boolean;
  query: string;
  suggestions: FeatureCollection[];
  result?: FeatureCollection;
}

const initialState: SearchState = {
  loading: false,
  query: '',
  suggestions: [],
};

const reducer = (state = initialState, action: SearchActionType | SuggestionsActionType): SearchState => {
  switch (action.type) {
    case SearchAction.Types.STARTED:
      return { ...state, loading: true, query: action.payload };
    case SearchAction.Types.COMPLETED:
      return { ...state, loading: false, result: action.payload[0] };
    case SearchAction.Types.FAILED:
      return { ...state, loading: false };
    case SuggestionsAction.Types.GET:
      return { ...state, loading: true, query: action.payload };
    case SuggestionsAction.Types.GET_COMPLETED:
      return { ...state, loading: false, suggestions: action.payload };
    case SuggestionsAction.Types.GET_FAILED:
      return { ...state, loading: false };
    case SuggestionsAction.Types.SELECT_SUGGESTION:
      return { ...state, loading: false, result: action.payload };
    case SuggestionsAction.Types.CLEAR_SUGGESTION:
      return { ...state, result: undefined, suggestions: [] };
    default:
      return state;
  }
};

export default reducer;
