import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  selected: any;
}

const DomainInfoScreenContent: React.FC<Props> = ({ selected }) => {
  const { t } = useTranslation();
  let translationKey = '';
  switch (selected) {
    case 2: // traffic
      translationKey = 'active_domain.info.cityflows';
      break;
    case 3: // air quality
      translationKey = 'active_domain.info.airquality';
      break;
    case 9: // water
      translationKey = 'active_domain.info.water';
      break;
    default:
      translationKey = '';
  }
  return (
    <>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: t(translationKey) }} />
    </>
  );
};

export default DomainInfoScreenContent;
