import { Action } from 'redux';
import { FeatureCollection } from 'geojson';

enum Search {
  STARTED = 'search/STARTED',
  COMPLETED = 'search/COMPLETED',
  FAILED = 'search/FAILED',
}

export interface SearchStartAction {
  type: typeof Search.STARTED;
  payload: string;
}

export interface SearchCompletedAction {
  type: typeof Search.COMPLETED;
  payload: FeatureCollection[];
}

export interface SearchFailedAction {
  type: typeof Search.FAILED;
  payload: Error;
  error: boolean;
}

export type SearchActionType = SearchStartAction | SearchCompletedAction | SearchFailedAction;

export class SearchAction implements Action<Search> {
  type: Search;

  static Types = Search;

  constructor(type: Search) {
    this.type = type;
  }

  static startSearch = (query: string): SearchStartAction => ({
    type: Search.STARTED,
    payload: query,
  });

  static searchCompleted = (searchResults: FeatureCollection[]): SearchCompletedAction => ({
    type: Search.COMPLETED,
    payload: searchResults,
  });

  static searchFailed = (error: Error): SearchFailedAction => ({
    type: Search.FAILED,
    payload: error,
    error: true,
  });
}

export default SearchAction;
