import React, { useEffect, useState } from 'react';
import { FlyToInterpolator, LinearInterpolator } from '@deck.gl/core';
import { isEqual as _isEqual, pick as _pick } from 'lodash';

import { ViewStateWithTransition } from 'containers/MapContainer';
import { ViewState } from 'react-map-gl';
import { CameraScript, Interpolator } from '../models/interfaces/Camera';
import { MapConfig } from '../config/map';
import { constrainViewState } from '../utils/mapUtils';

interface CameraScriptProps {
  script?: CameraScript;
  mapLoaded: boolean;
  viewState: ViewStateWithTransition | null;
  setViewState: (viewState: ViewStateWithTransition) => void;
  onInterrupt?: () => void;
  onFinished?: () => void;
  mapConfig: MapConfig;
}

/**
 * Get transition interpolator based on type Interpolator.
 * @Default `LinearInterpolator`
 * @see `FlyToInterpolator options` https://github.com/uber/deck.gl/blob/master/docs/api-reference/view-state-transitions.md#flytointerpolator
 * */
const getTransitionInterpolator = (interpolator: Interpolator, options?: object) => {
  if (interpolator === 'linearInterpolator') return new LinearInterpolator();
  if (interpolator === 'flyToInterpolator') return new FlyToInterpolator(options);
  return new FlyToInterpolator(options);
};

/**
 * Camera script component
 * @param script (required): CameraScript;
 * @param mapLoaded (required): boolean;
 * @param viewState (required): ViewStateWithTransition;
 * @param setViewState (required): (viewState: ViewStateWithTransition) => void;
 */
const useCameraScript: React.FC<CameraScriptProps> = (props) => {
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [finished, setFinished] = useState<boolean | null>(null);
  const shouldStop = currentIndex && props.script && currentIndex >= props.script.cameraMovements.length;

  const handleCameraMovement = () => {
    // exit early
    if (currentIndex === null) return;
    if (!props.script?.cameraMovements.length) return;
    const cameraScriptKeys = Object.keys(props.script.cameraMovements[0]?.viewState);
    const viewState = _pick(props.viewState, cameraScriptKeys);
    if (_isEqual(viewState, props.script.cameraMovements[0].viewState)) {
      // console.info('[CameraScript]: already at the same location');
      return;
    }

    const { interpolator, interpolatorOptions } = props.script.cameraMovements[currentIndex].transition;
    const newViewState: ViewStateWithTransition = {
      ...props.viewState,
      ...determineCameraMovementTargetViewState(props.mapConfig, props.script.cameraMovements[currentIndex].viewState),
      transitionDuration: props.script.cameraMovements[currentIndex].transition?.duration,
      transitionInterpolator: getTransitionInterpolator(interpolator as Interpolator, interpolatorOptions),
      transitionEasing: props.script.cameraMovements[currentIndex].transition?.easing,
      onTransitionStart: () => {
        // console.info(`[CameraScript]: playing index: ${currentIndex}`);
      },
      onTransitionInterrupt: () => {
        props.onInterrupt && props.onInterrupt();
        setCurrentIndex(null);
        // console.info('[CameraScript]: interrupted');
      },
      onTransitionEnd: () => {
        setCurrentIndex(currentIndex + 1);
      },
    };
    props.setViewState(newViewState);
  };

  useEffect(() => {
    if (props.script?.cameraMovements.length) {
      if (props.mapLoaded && currentIndex === null) {
        // animate the first movement
        // console.info('[CameraScript]: no currentIndex found.\n    Starting...');
        setFinished(false);
        setCurrentIndex(0);
      }
      if (currentIndex !== null && shouldStop) {
        // console.info('[CameraScript]: finished');
        props.onFinished && props.onFinished();
        setFinished(true);
        setCurrentIndex(null);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.script, props.mapLoaded, currentIndex]);

  useEffect(() => {
    if (props.script?.cameraMovements.length) {
      if (props.mapLoaded && currentIndex !== null && !shouldStop && !finished) {
        const waitTime = props.script.cameraMovements[currentIndex]?.waitTime || 0;
        // console.info(`[CameraScript]: should begin animating after ${waitTime} ms`, { animating, currentIndex });
        setTimeout(() => handleCameraMovement(), waitTime);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mapLoaded, currentIndex, shouldStop, finished, props.script]);

  return null;
};

const determineCameraMovementTargetViewState = (mapConfig: MapConfig, viewState: ViewState): ViewState => {
  return {
    ...constrainViewState(mapConfig, viewState),
    latitude: isNumber(viewState.latitude) ? viewState.latitude : mapConfig.initialViewState.latitude,
    longitude: isNumber(viewState.longitude) ? viewState.longitude : mapConfig.initialViewState.longitude,
    zoom: isNumber(viewState.zoom) ? viewState.zoom : mapConfig.initialViewState.zoom,
  };
};

function isNumber(x: number): boolean {
  return !!x || x === 0;
}

export default useCameraScript;
