import { css } from 'emotion';
import { colors, boxShadow, rounded, transition, boxShadowBlue } from 'styles';
import { heightTimeline, heightPrimaryToolbar } from 'styles/primaryToolbar';

export const layerToolBarWrapperStyle = css`
  flex-direction: column;
  position: absolute;
  bottom: calc(2rem + ${heightTimeline}px + 1rem + ${heightPrimaryToolbar}px + 1rem);
  z-index: 1;
  display: flex;
  align-content: space-around;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  flex-direction: row;
`;

export const timelineFilterControlStyle = css`
  z-index: 1;
  height: auto;
  background-color: ${colors.white};
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 2rem 40px;
  border-radius: ${rounded.md};
  box-shadow: ${boxShadow.sm};
  transition: ${transition};
`;

export const layerToolBarButtonStyle = css`
  display: flex;
  padding: 0.75rem;
  margin: 0px 0.5rem;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: ${rounded.sm};
  background-color: ${colors.white};
  box-shadow: ${boxShadow.sm};
  transition: ${transition};
  user-select: none;
  cursor: pointer;
  outline: none;
  > .icon {
    border: 1px solid transparent;
    transition: ${transition};
  }
  > .text {
    color: ${colors.gray[900]};
    margin-left: 0.25rem;
    line-height: 2rem;
    text-transform: capitalize;
  }
  &.active {
    background-color: ${colors.primary.default};
    box-shadow: ${boxShadowBlue};
    > .text,
    > svg .icon,
    > svg path {
      color: ${colors.white};
      fill: ${colors.white};
    }
  }
  &:not(.active) {
    &:hover {
      background-color: ${colors.gray[300]};
      > .icon {
        box-shadow: ${boxShadowBlue};
      }
      > .text {
        color: ${colors.white};
      }
    }
  }
`;
