export type DateRangeItem = {
  title: string;
  start: Date;
  end: Date;
  type: DateRangeType;
  shortCode: string;
};

enum DateRangeType {
  PRESET = 'PRESET',
  CUSTOM = 'CUSTOM',
}

export default DateRangeType;
