import moment, { duration } from 'moment';

import presetTimeIntervals, { maxAmountOfIntervals } from 'config/presetTimeIntervals';
import { TimeInterval, TimeIntervalUnit } from '../models/types/TimeInterval';
import { DateRangeItem } from '../models/types/DateRangeType';

export const getApplicableIntervals = (rangeStart: Date, rangeEnd: Date): Array<TimeInterval> => {
  return presetTimeIntervals.filter((interval) => intervalAppliesToRange(interval, rangeStart, rangeEnd));
};

// Transform a time interval descriptor into a duration
export const getDurationForInterval = (interval: TimeInterval) => duration(interval.length, interval.timeUnit);

const intervalDurationAppliesToRange = (
  durationToCheck: moment.Duration,
  rangeStart: moment.Moment,
  rangeEnd: moment.Moment
): boolean => {
  const checker = rangeStart.clone().add(durationToCheck);
  return checker.isSameOrBefore(rangeEnd);
};

// Checks whether an interval's duration is applicable to a range and whether it won't result in too many intervals
// e.g. an interval of a year will NOT fit in a timerange of a month
// e.g. an interval of 30 minutes will fit in a timerange of a day
export const intervalAppliesToRange = (interval: TimeInterval, rangeStart: Date, rangeEnd: Date): boolean => {
  return (
    intervalDurationAppliesToRange(getDurationForInterval(interval), moment(rangeStart), moment(rangeEnd)) &&
    amountOfIntervalsInRange(interval, rangeStart, rangeEnd) < maxAmountOfIntervals
  );
};

// Checks whether a specific interval (slice of time) lies within a range
// e.g. interval: 4-5 december doesn't lie within range 10-15 Jan
// e.g. interval: 4-5 december lies within range 1-17 Dec
export const intervalWithinRange = (
  intervalStart: Date,
  intervalEnd: Date,
  rangeStart: Date,
  rangeEnd: Date
): boolean => {
  return moment(intervalStart).isSameOrAfter(rangeStart) && moment(intervalEnd).isSameOrBefore(rangeEnd);
};

export const firstIntervalInRange = (interval: TimeInterval, rangeStart: Date): [Date, Date] => {
  const start = rangeStart;
  const end = moment(rangeStart).add(getDurationForInterval(interval)).toDate();
  return [start, end];
};

export const amountOfIntervalsInRange = (interval: TimeInterval, rangeStart: Date, rangeEnd: Date): number => {
  const result = Math.ceil(
    Math.abs(moment.duration(moment(rangeStart).diff(rangeEnd)).as(interval.timeUnit) / interval.length)
  );
  return result;
};

export const dateRangePresetExists = (data: DateRangeItem[], start: Date, end: Date) =>
  data.findIndex(
    (value: DateRangeItem) =>
      start.toLocaleDateString('en-US') === value.start.toLocaleDateString('en-US') &&
      end.toLocaleDateString('en-US') === value.end.toLocaleDateString('en-US')
  );

export const indexForIntervalInRange = (rangeStart: Date, currentStart: Date, interval: TimeInterval) => {
  return moment(currentStart).diff(rangeStart, interval.timeUnit) / interval.length;
};

export const addIntervalToDate = (start: Date, interval: TimeInterval) => {
  return moment(start).add(getDurationForInterval(interval)).toDate();
};

/**
 * Parses time notation to an usable object
 * @param interval string e.g. 1d, 3w, 2m
 */
export const parseInterval = (interval: string | undefined): { length: number; unit: TimeIntervalUnit } => {
  const defaultInterval = { length: 1, unit: 'day' as const };

  // checks if the Interval matches the format
  const validInterval = interval?.match(/\d{1,3}(s|m|h|d)/g);
  if (!validInterval || !validInterval[0]) {
    console.debug(`Invalid interval format. Defaults to ${defaultInterval.length} ${defaultInterval.unit}`);
    return defaultInterval;
  }

  // splits the valid string into an array e.g. 1d into ["1", "d"]
  const [length, unit] = validInterval[0].split(/(\d+)/).filter(Boolean);

  switch (unit) {
    case 's':
      return { length: Number(length), unit: 'second' };
    case 'm':
      return { length: Number(length), unit: 'minute' };
    case 'h':
      return { length: Number(length), unit: 'hour' };
    case 'd':
      return { length: Number(length), unit: 'day' };
    default:
      console.debug(`Defaults interval to ${defaultInterval.length} ${defaultInterval.unit}`);
      return defaultInterval;
  }
};
