import React from 'react';

interface Props {
  text: string;
}

const BotTextFragment: React.FC<Props> = (props) => {
  return (
    <article>
      <p>{props.text}</p>
    </article>
  );
};

export default BotTextFragment;
