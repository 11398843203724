import { appMode, defaultLODMode } from 'config/app';
import { MAP_CONFIG } from 'config/map';
import AppAction, { AppActionType } from '../actions/app/AppAction';
import LODMode from '../models/types/LODMode';
import { AppMode } from '../models/types/AppMode';

export interface AppState {
  mode: AppMode;
  lodMode: LODMode;
  showCarousel: boolean;
  isIdle: boolean;
  activeBotTriggerId?: number;
  botHistory: number[];
  activeGems: any[];
  alertingLayers: number[];
}

const initialState: AppState = {
  mode: appMode,
  lodMode: MAP_CONFIG.pitch > 0 ? defaultLODMode : LODMode.LOD0,
  showCarousel: false,
  isIdle: false,
  botHistory: [],
  activeGems: [],
  alertingLayers: [],
};

// TODO: type action
const reducer = (state = initialState, action: AppActionType): AppState => {
  switch (action.type) {
    case AppAction.Types.TOGGLE_MODE: {
      if (state.mode === AppMode.DEFAULT) {
        return {
          ...state,
          mode: AppMode.DEMO,
        };
      }
      return {
        ...state,
        mode: AppMode.DEFAULT,
      };
    }
    case AppAction.Types.SET_LOD_MODE: {
      if (state.lodMode === action.payload) return state;

      return {
        ...state,
        lodMode: action.payload,
      };
    }
    case AppAction.Types.TOGGLE_CAROUSEL:
      return {
        ...state,
        showCarousel: !state.showCarousel,
      };
    case AppAction.Types.IS_IDLE:
      return {
        ...state,
        isIdle: action.payload,
      };
    case AppAction.Types.START_BOT_TRIGGER:
      return {
        ...state,
        activeBotTriggerId: action.payload,
        botHistory: state.botHistory.concat([action.payload]),
      };
    case AppAction.Types.RESUME_BOT_TRIGGER:
      return {
        ...state,
        activeBotTriggerId: state.activeBotTriggerId || action.payload,
      };
    case AppAction.Types.TOGGLE_BOT:
      return {
        ...state,
        activeBotTriggerId: state.activeBotTriggerId ? undefined : action.payload,
      };
    case AppAction.Types.STOP_BOT_TRIGGER:
      return {
        ...state,
        activeBotTriggerId: undefined,
      };
    case AppAction.Types.CLEAR_BOT_HISTORY:
      return {
        ...state,
        botHistory: [],
      };
    case AppAction.Types.SET_ACTIVE_GEMS:
      return {
        ...state,
        activeGems: action.payload,
      };
    case AppAction.Types.SET_BOT_ALERTING_LAYERS:
      return {
        ...state,
        alertingLayers: action.payload,
      };
    case AppAction.Types.RESTART:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default reducer;
