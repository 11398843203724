import { call, put } from 'redux-saga/effects';
import { FeatureCollection } from 'geojson';
import MapAction from '../actions/map/MapAction';
import { getBounds } from '../api/map';

export function* getBoundsSaga() {
  try {
    const response: FeatureCollection = yield call(getBounds);

    yield put(MapAction.getBoundsCompleted(response));
  } catch (error) {
    yield put(MapAction.getBoundsFailed(error));
  }
}

export default getBoundsSaga;
