import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { DIRECTIONAL_LIGHT, MOON_LIGHT, AMBIENT_LIGHT } from 'config/map';
import Timeline from 'components/timeline/Timeline';
import { createTimeInterval } from 'config/presetTimeIntervals';
import { transitionDurationMs } from 'config/app';
import { lerp } from 'utils/easing';

import { isIdle } from 'selectors/appSelectors';
import GlobalFilterAction from '../actions/global-filters/GlobalFilterAction';
import { getDateRangeFilter } from '../selectors/globalFiltersSelectors';
import { TimeInterval } from '../models/types/TimeInterval';
import { getCurrentScenario } from '../selectors/scenariosSelectors';

const timeInterval: TimeInterval = createTimeInterval('hour', 1);
const timeIntervalInMs = moment.duration(timeInterval.length, timeInterval.timeUnit).asMilliseconds();

const ICONS = {
  sun: './images/sun.svg',
  moon: './images/moon.svg',
  stormySun: './images/stormy_sun.svg',
  stormyMoon: './images/stormy_moon.svg',
};

const icons = Object.values(ICONS).map((icon) => icon);

const TimelineDemoContainer: React.FC = () => {
  const dispatch = useDispatch();

  const timeRange = useSelector(getDateRangeFilter);
  const currentScenario = useSelector(getCurrentScenario);
  const idle = useSelector(isIdle);

  const [progress, setProgress] = useState<number>(0);
  const [timePaused, setPaused] = useState<boolean>(false);

  const timestamp = useMemo((): Date => {
    return new Date(lerp(timeRange.start.getTime(), timeRange.end.getTime(), progress / 100));
  }, [progress, timeRange.end, timeRange.start]);

  const memoizedCurrentIcon = useMemo((): string => {
    const rainScenario = 'heavyrain';
    if (timestamp.getHours() < 20 && timestamp.getHours() >= 8) {
      if (currentScenario?.name === rainScenario) {
        return ICONS.stormySun;
      }
      return ICONS.sun;
    }

    if (currentScenario?.name === rainScenario) {
      return ICONS.stormyMoon;
    }
    return ICONS.moon;
  }, [currentScenario, timestamp]);

  const togglePaused = () => {
    setPaused(!timePaused);
  };

  const manualScrub = (increment: boolean) => {
    if (!timePaused) togglePaused();
    setProgress((prevProgress) => {
      if (increment) {
        if (prevProgress + tickPercent > 100) {
          return 0;
        }
        return prevProgress + tickPercent;
      }
      if (prevProgress - tickPercent < 0) {
        return 100;
      }
      return prevProgress - tickPercent;
    });
  };

  const tickPercent = useMemo(() => {
    // compute diff in ms
    const diffInMs = Math.abs(moment(timeRange.start).diff(timeRange.end));
    // steps calculated based on time interval and diff in ms
    const totalSteps = diffInMs / timeIntervalInMs;
    // calculate percentage based on the steps
    return 100 / totalSteps;
  }, [timeRange.end, timeRange.start]);

  // When global timerange is changed reset progress and timestamp
  useEffect(() => {
    setProgress(0);
  }, [timeRange.start]);

  // Change the ambient light based on the progression of the day
  useEffect(() => {
    let iteration = 0;
    // To make light transitions smoother we use a js interval that will perform n amount of smooth steps between the timeintervals
    const smoothedIntervals = 10;
    const interval = setInterval(() => {
      // To make the light intensities change during the day we remap progress (0 - 100) to a exponential curve going from 1 -> 0 -> 1
      const smoothedProgress = progress + tickPercent * (iteration / smoothedIntervals);
      const ambientMultiplier = Math.abs((smoothedProgress / 100) * 2 - 1);
      // To avoid pitchblack or washed out colors we clamp the intensity
      AMBIENT_LIGHT.intensity = Math.max(Math.min(1 - ambientMultiplier ** 1.6, 5), 0.9) * 1.5;
      iteration++;
      iteration %= smoothedIntervals;

      const smoothedTimestamp = new Date(
        lerp(timeRange.start.getTime(), timeRange.end.getTime(), smoothedProgress / 100)
      );

      DIRECTIONAL_LIGHT.timestamp = smoothedTimestamp;
      DIRECTIONAL_LIGHT.intensity = Math.max(((1 - ambientMultiplier) * 1) ** 1.4, 0.2);
      // Locking the moon at a specific time looked better
      MOON_LIGHT.timestamp = timeRange.start.getTime();
      MOON_LIGHT.intensity = Math.max((ambientMultiplier * 0.6) ** 1.4, 0.1);
    }, transitionDurationMs / smoothedIntervals);

    return () => {
      clearInterval(interval);
    };
  }, [progress, tickPercent, timePaused, timeRange.end, timeRange.start, timestamp]);

  useEffect(() => {
    // TODO: start interval when map is loaded
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (timePaused) return prevProgress;
        if (prevProgress + tickPercent > 100) {
          return 0;
        }
        return prevProgress + tickPercent;
      });
    }, transitionDurationMs);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRange, timePaused]);

  useEffect(() => {
    dispatch(
      GlobalFilterAction.setTimeIntervalAction(
        timestamp,
        moment(timestamp).add(timeInterval.length, timeInterval.timeUnit).toDate(),
        timeInterval
      )
    );
  }, [dispatch, progress, timestamp]);

  useEffect(() => {
    if (idle) {
      setPaused(false);
    }
  }, [idle]);

  return (
    <Timeline
      currentIcon={memoizedCurrentIcon}
      icons={icons}
      progress={progress}
      timestamp={timestamp}
      paused={timePaused}
      onPauseClick={togglePaused}
      onScrub={manualScrub}
    />
  );
};

export default TimelineDemoContainer;
