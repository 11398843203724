import React from 'react';
import { css } from 'emotion';

import { styleConfig, cardStyle } from 'styles/demo';

const { margin } = styleConfig;

const locationReferenceBoxStyle = css`
  ${cardStyle}
  display: flex;
  position: absolute;
  top: 1rem;
  right: 0;
  height: 70px;
  margin-bottom: ${margin[4]};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0 ${margin[8]};
  align-content: center;
  strong,
  em,
  span {
    color: white;
    line-height: 70px;
  }
`;

interface Props {
  city: string;
  country: string;
}

const LocationReference: React.FC<Props> = (props) => {
  return (
    <div className={locationReferenceBoxStyle}>
      <strong>{props.city}</strong>
      <span>,&nbsp;</span>
      <em>{props.country}</em>
    </div>
  );
};

export default LocationReference;
