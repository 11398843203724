import React, { useState } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import Modal from 'components/modal/Modal';

const DisclaimerContainer: React.FC = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const handleClose = () => setVisible(!visible);
  return (
    <>
      <small>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={() => setVisible(!visible)}>{t('general.disclaimer')}</a>
      </small>
      <Modal
        title={t('infoscreen.welcome.disclaimer.title')}
        visible={visible}
        handleClose={handleClose}
        footer={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Button key="closebtn" onClick={handleClose}>
            {t('general.confirm')}
          </Button>
        }
      >
        <p dangerouslySetInnerHTML={{ __html: t('infoscreen.welcome.disclaimer.copy') }} />
      </Modal>
    </>
  );
};

export default DisclaimerContainer;
