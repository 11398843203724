import React from 'react';
import { css } from 'emotion';

import styleConfig from 'styles/demo/config';

const { colors, padding, boxShadow, fontSize, borderRadius } = styleConfig;

interface Props {
  content: JSX.Element;
  location: { x: number; y: number };
  title?: string;
  className?: string;
}

const tooltipStyle = css`
  position: absolute;
  z-index: 10;
  border-radius: ${borderRadius.sm};
  background-color: ${colors.white};
  box-shadow: ${boxShadow.sm};
  overflow: hidden;
  .title,
  .content {
    padding: ${padding[2]} ${padding[3]};
  }
  .title {
    font-weight: bold;
    background-color: ${colors.gray[200]};
    text-transform: uppercase;
    font-size: ${fontSize.xs};
    color: ${colors.gray[600]};
  }
`;
const VisualizationTooltip: React.FC<Props> = (props) => {
  return (
    <div
      className={`${tooltipStyle} ${props.className || ''}`}
      style={{ top: props.location.y, left: props.location.x }}
    >
      {props.title && <div className="title">{props.title}</div>}
      <div className="content">{props.content}</div>
    </div>
  );
};

export default VisualizationTooltip;
