import { css } from 'emotion';
import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { animated, config, useChain, useSpring, useTransition } from 'react-spring';
import { cardStyle, styleConfig } from 'styles/demo';
import { addIntervalToDate } from '../utils/timeUtils';
import GlobalFilterAction from '../actions/global-filters/GlobalFilterAction';
import LanguageAction from '../actions/language/LanguageAction';
import ScenariosAction from '../actions/scenarios/ScenariosAction';
import DockAction from '../actions/dock/DockAction';
import ActiveLayerButton from '../components/button/ActiveLayerButton';
import LanguageDropdown from '../components/language/LanguageDropdown';
import { getTimeInterval } from '../selectors/globalFiltersSelectors';
import { getAvailableLanguages, getCurrentLanguage } from '../selectors/languageSelectors';
import {
  getAvailableScenarios,
  getCurrentScenarioId,
  getScenarios,
  getScenariosShownAtLeastOnce,
} from '../selectors/scenariosSelectors';
import { Scenario } from '../models/interfaces/scenarios/scenario';
import { Language } from '../models/interfaces/languages/Language';
import { getActiveDockLayers } from '../selectors/dockSelectors';

const { colors, margin, padding, fontSize, letterSpacing } = styleConfig;

const containerStyle = css`
  ${cardStyle}
  position: absolute;
  top: 1rem;
  left: 1rem;
  overflow: hidden;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: ${padding[2]} ${padding[4]} ${padding[2]} ${padding[8]};
    background-color: ${colors.gray[700]};
    h2 {
      font-size: ${fontSize.md};
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      color: ${colors.gray[200]};
      margin: 0;
      letter-spacing: ${letterSpacing.md};
      line-height: 2rem;
    }
    .options {
      display: flex;
      flex-direction: row;
    }
    p {
      font-size: ${fontSize.xs};
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      color: ${colors.gray[200]};
      margin: 0;
      margin-right: ${margin[1]};
      letter-spacing: ${letterSpacing.md};
      line-height: 2rem;
    }
    .icon {
      font-size: ${fontSize.xs};
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      color: ${colors.gray[200]};
      border-radius: 50%;
      border: none;
      width: 2rem;
      height: 2rem;
      margin-right: ${margin[1]};
      padding: ${padding[2]};
      background-color: ${colors.gray[600]};
      svg {
        width: 1rem;
        height: 1rem;
      }
      &:hover {
        color: ${colors.black};
        cursor: pointer;
        background-color: ${colors.gray[300]};
        svg path {
          fill: ${colors.black};
        }
      }
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    padding: ${padding[2]} ${padding[4]};
    > div {
      margin: 0 ${margin[1]};
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;
const activeLayerButtonStyle = (show: boolean | undefined) => css`
  ${!show && 'opacity: 0.5;'}
  .icon {
    svg {
      height: 20px;
      width: auto;
      max-width: 24px;
      padding: 0;
      margin: 0 ${margin[2]} 0 ${margin[2]};
    }
  }
`;

function getIconForId(id: number): string {
  switch (id) {
    case 2:
      return 'icons/road-closed.svg';
    case 3:
      return 'icons/storm.svg';
    default:
      return 'icons/placeholder.svg';
  }
}

const ScenarioTriggerContainer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const containerRef = useRef<any>();
  const transRef = useRef<any>();

  const currentLayer = useSelector(getActiveDockLayers)[0];
  const scenarios = useSelector(getScenarios);
  const currentScenarioId = useSelector(getCurrentScenarioId);
  const currentTimeInterval = useSelector(getTimeInterval);
  const languages = useSelector(getAvailableLanguages);
  const currentLanguage = useSelector(getCurrentLanguage);
  const availableScenarios = useSelector(getAvailableScenarios);

  // Keep track of whether the id has also shown info
  const scenariosShownAtLeastOnce = useSelector(getScenariosShownAtLeastOnce);

  const changeTimeRange = useCallback(
    (newStart: Date, newEnd: Date) => {
      // Dispatch time setting
      dispatch(GlobalFilterAction.setDateRangeAction(newStart, newEnd));
      // Reset interval to the start
      const intervalEnd = addIntervalToDate(newStart, currentTimeInterval);
      dispatch(GlobalFilterAction.setTimeIntervalAction(newStart, intervalEnd));
    },
    [currentTimeInterval, dispatch]
  );

  const handleScenarioClick = useCallback(
    (scenario: Scenario) => {
      if (scenario.id === currentScenarioId) {
        dispatch(ScenariosAction.scenarioReset());
        return;
      }

      // Change active scenario ID
      let playCameraMovement = false;
      if (!scenariosShownAtLeastOnce[scenario.id]) {
        playCameraMovement = true;
        changeTimeRange(new Date(scenario.timePeriod.start), new Date(scenario.timePeriod.end));
      }

      dispatch(ScenariosAction.scenarioTriggered(scenario.id, playCameraMovement));
      // TODO: Start camera animation

      // switch layers
      if (currentLayer.id !== scenario.showDomain) {
        dispatch(DockAction.toggleLayerVisualization(currentLayer.id));
        dispatch(DockAction.toggleLayerVisualization(scenario.showDomain || 2)); // defaults to traffic
      }
    },
    [changeTimeRange, dispatch, scenariosShownAtLeastOnce, currentScenarioId, currentLayer]
  );

  const handleLanguageChange = useCallback(
    (language: Language) => {
      dispatch(LanguageAction.changeLanguage(language));
    },
    [dispatch]
  );

  // Animations
  const containerAnimation = useSpring({
    ref: containerRef,
    config: config.stiff,
    from: { opacity: 0, transform: 'scale(0.5)' },
    to: { opacity: 1, transform: 'scale(1)' },
  });

  const transitions = useTransition(scenarios, (item) => item.name, {
    ref: transRef,
    unique: true,
    trail: 400 / scenarios.length,
    from: { opacity: 0, transform: 'scale(0.5)' },
    enter: { opacity: 1, transform: 'scale(1)' },
  });

  const scenarioButtons = useMemo(() => {
    return transitions.map(({ item, key, props }) => {
      // exclude default scenario (id: 0)
      if (item.id === 0) return null;
      const text = t(`scenarios.name.${item.name}`);
      const active = item.id === currentScenarioId;
      const disabled = !availableScenarios.includes(item);
      return (
        <animated.div key={key} style={props}>
          <ActiveLayerButton
            key={item.id}
            icon={getIconForId(item.id)}
            text={text}
            active={active}
            disabled={disabled}
            onClick={() => handleScenarioClick(item)}
            className={activeLayerButtonStyle(item.show)}
          />
        </animated.div>
      );
    });
  }, [availableScenarios, currentScenarioId, handleScenarioClick, t, transitions]);

  useChain([containerRef, transRef], [0, 0.5]);

  // REACT COMPONENT
  //= ================
  return (
    <>
      <animated.div ref={containerRef} className={containerStyle} style={containerAnimation}>
        <div className="header">
          <h2>{t('scenarios.header.selectevent')}</h2>
          <div className="options">
            <LanguageDropdown
              languages={languages}
              selected={currentLanguage}
              onSelectLanguage={handleLanguageChange}
            />
          </div>
        </div>
        <div className="content">{scenarioButtons}</div>
      </animated.div>
    </>
  );
};

export default ScenarioTriggerContainer;
