import DebugAction, { DebugActionType } from '../actions/debug/DebugAction';

export interface DebugState {
  controlPanel: boolean;
}

const initialState: DebugState = {
  controlPanel: false,
};

// TODO: type action
const reducer = (state = initialState, action: DebugActionType): DebugState => {
  switch (action.type) {
    case DebugAction.Types.TOGGLE_CONTROL_PANEL: {
      return {
        ...state,
        controlPanel: !state.controlPanel,
      };
    }
    default:
      return state;
  }
};

export default reducer;
