import { Action } from 'redux';
import LayerType from '../../models/types/LayerType';
import { Layer } from '../../models/interfaces/api/Layer';

enum CarouselSearch {
  STARTED = 'carousel-search/STARTED',
  COMPLETED = 'carousel-search/COMPLETED',
  FAILED = 'carousel-search/FAILED',
}

export interface CarouselSearchStartAction {
  type: typeof CarouselSearch.STARTED;
  payload: { layerType: LayerType; query: string };
}

export interface CarouselSearchCompletedAction {
  type: typeof CarouselSearch.COMPLETED;
  payload: { layerType: LayerType; layers: Layer[] };
}

export interface CarouselSearchFailedAction {
  type: typeof CarouselSearch.FAILED;
  payload: Error;
  error: boolean;
}

export type CarouselSearchActionType =
  | CarouselSearchStartAction
  | CarouselSearchCompletedAction
  | CarouselSearchFailedAction;

export class CarouselSearchAction implements Action<CarouselSearch> {
  type: CarouselSearch;

  static Types = CarouselSearch;

  constructor(type: CarouselSearch) {
    this.type = type;
  }

  static startCarouselSearch = (layerType: LayerType, query: string): CarouselSearchStartAction => ({
    type: CarouselSearch.STARTED,
    payload: { layerType, query },
  });

  static carouselSearchCompleted = (layerType: LayerType, layers: Layer[]): CarouselSearchCompletedAction => ({
    type: CarouselSearch.COMPLETED,
    payload: { layerType, layers },
  });

  static carouselSeachFailed = (error: Error): CarouselSearchFailedAction => ({
    type: CarouselSearch.FAILED,
    payload: error,
    error: true,
  });
}

export default CarouselSearchAction;
