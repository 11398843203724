import { Scenario } from 'models/interfaces/scenarios/scenario';
import scenariosFile from 'metadata/scenarios.json';
import ScenariosAction, { ScenariosActionType } from '../actions/scenarios/ScenariosAction';
import AppAction, { AppActionType } from '../actions/app/AppAction';

export interface ScenariosState {
  defaultScenario: number;
  currentScenarioId: number;
  scenarios: Scenario[];
  shownAtLeastOnce: Record<number, boolean>;
}

const initialState: ScenariosState = {
  defaultScenario: 0,
  currentScenarioId: 0,
  scenarios: scenariosFile as Scenario[],
  shownAtLeastOnce: {},
};

const reducer = (state = initialState, action: ScenariosActionType | AppActionType): ScenariosState => {
  switch (action.type) {
    case ScenariosAction.Types.TRIGGERED: {
      return {
        ...state,
        currentScenarioId: action.payload.scenarioId,
        shownAtLeastOnce: { ...state.shownAtLeastOnce, [action.payload.scenarioId]: true },
      };
    }
    case AppAction.Types.RESTART:
      return {
        ...state,
        shownAtLeastOnce: {},
      };
    default:
      return state;
  }
};

export default reducer;
