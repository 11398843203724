import { apiUrl } from 'config/api';
import { get } from './common';
import LayerType from '../models/types/LayerType';
import { Layer } from '../models/interfaces/api/Layer';

export const getLayers = async (type: LayerType) => {
  return get<Layer[]>(`${apiUrl}/v1/layers/${type}`);
};

export const getLayerById = async (id: number, type: LayerType) => {
  return get<Layer>(`/v1/layers/${type}/${id}.json`);
};

export const getLayerData = (url: string) => {
  return get<JSON>(url);
};
