import { defaultTheme } from '@imec/digital-twin-ui-components';
import { boxShadow } from './css';

export default {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    gray: {
      ...defaultTheme.colors.grey,
      400: '#C5CDDF',
    },
  },
  boxShadow,
};
