import { select, put } from 'redux-saga/effects';

import { AppAction } from '../actions/app/AppAction';
import { LODMode } from '../models/types/LODMode';
import { getLODMode } from '../selectors/appSelectors';
import { getOptimalLODMode } from '../utils/lodMode';

export default function* () {
  const lodMode = yield select(getLODMode);
  if (lodMode === LODMode.HARDWARE_OPTIMIZED) {
    const newLODMode = yield getOptimalLODMode();
    yield put(AppAction.setLODMode(newLODMode));
  }
}
