import React from 'react';
import { Select, Input } from 'antd';
import { Config } from './CameraScriptControls';
import { Easing, Interpolator } from '../../models/interfaces/Camera';

const { Option } = Select;

interface Props {
  initialConfig: Config;
  handleChangeConfig: (config: Partial<Config>) => void;
}

const interpolators: { name: string; value: Interpolator }[] = [
  { name: 'Fly', value: 'flyToInterpolator' },
  { name: 'Linear', value: 'linearInterpolator' },
];

const easings: Easing[] = [
  'easeCubic',
  'easeCubicIn',
  'easeCubicOut',
  'easeLinear',
  'easeBack',
  'easeBackIn',
  'easeBackOut',
];

const CameraScriptConfig: React.FC<Props> = (props) => {
  return (
    <>
      <Select
        defaultValue="flyToInterpolator"
        style={{ width: 80 }}
        onChange={(interpolator: Interpolator) => props.handleChangeConfig({ interpolator })}
      >
        {interpolators.map(({ value, name }) => (
          <Option key={value} value={value}>
            {name}
          </Option>
        ))}
      </Select>
      <Input
        type="number"
        defaultValue={props.initialConfig.duration.toString()}
        onBlur={(e) => props.handleChangeConfig({ duration: Number(e.target.value) })}
        style={{ width: 80 }}
      />
      <Select
        defaultValue={props.initialConfig.easing}
        style={{ width: 120 }}
        onChange={(easing: Easing) => props.handleChangeConfig({ easing })}
      >
        {easings.map((e) => (
          <Option key={e} value={e}>
            {e}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default CameraScriptConfig;
