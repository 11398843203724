import { Action } from 'redux';
import { FeatureCollection } from 'geojson';

enum Suggestions {
  GET = 'search-suggestions/GET',
  GET_COMPLETED = 'search-suggestions/GET_COMPLETED',
  GET_FAILED = 'search-suggestions/GET_FAILED',
  SELECT_SUGGESTION = 'search-suggestions/SELECT',
  CLEAR_SUGGESTION = 'search-suggestions/CLEAR',
}

export interface GetSuggestionsAction {
  type: typeof Suggestions.GET;
  payload: string;
}

export interface GetSuggestionsCompletedAction {
  type: typeof Suggestions.GET_COMPLETED;
  payload: FeatureCollection[];
}

export interface GetSuggestionsFailedAction {
  type: typeof Suggestions.GET_FAILED;
  payload: Error;
  error: boolean;
}

export interface SelectSuggestionAction {
  type: typeof Suggestions.SELECT_SUGGESTION;
  payload: FeatureCollection;
}

export interface ClearSuggestionAction {
  type: typeof Suggestions.CLEAR_SUGGESTION;
}

export type SuggestionsActionType =
  | GetSuggestionsAction
  | GetSuggestionsCompletedAction
  | GetSuggestionsFailedAction
  | SelectSuggestionAction
  | ClearSuggestionAction;

export class SuggestionsAction implements Action<Suggestions> {
  type: Suggestions;

  static Types = Suggestions;

  constructor(type: Suggestions) {
    this.type = type;
  }

  static getSuggestions = (query: string): GetSuggestionsAction => ({
    type: Suggestions.GET,
    payload: query,
  });

  static getSuggestionsCompleted = (suggestions: FeatureCollection[]): GetSuggestionsCompletedAction => ({
    type: Suggestions.GET_COMPLETED,
    payload: suggestions,
  });

  static getSuggestionsFailed = (error: Error): GetSuggestionsFailedAction => ({
    type: Suggestions.GET_FAILED,
    payload: error,
    error: true,
  });

  static selectSuggestion = (suggestion: FeatureCollection): SelectSuggestionAction => ({
    type: Suggestions.SELECT_SUGGESTION,
    payload: suggestion,
  });

  static clearSuggestion = (): ClearSuggestionAction => ({
    type: Suggestions.CLEAR_SUGGESTION,
  });
}

export default SuggestionsAction;
