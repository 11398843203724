export enum LODMode {
  AUTOMATIC = 'AUTOMATIC',
  MAPBOX = 'MAPBOX',
  LOD0 = 'LOD0',
  LOD1 = 'LOD1',
  LOD2 = 'LOD2',
  HARDWARE_OPTIMIZED = 'HARDWARE_OPTIMIZED',
}

export default LODMode;
