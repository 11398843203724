import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CameraScriptControls from '../../components/camera/CameraScriptControls';
import CameraScriptAction from '../../actions/camera/CameraAction';
import { getCameraScriptRaw } from '../../selectors/cameraSelectors';
import { getViewState } from '../../selectors/mapSelectors';
import { CameraScriptRaw } from '../../models/interfaces/Camera';

const CameraScriptControlsContainer: React.FC = () => {
  const dispatch = useDispatch();
  const viewState = useSelector(getViewState);
  const cameraScript = useSelector(getCameraScriptRaw);

  const handleExampleCameraScript = () => dispatch(CameraScriptAction.getCameraScript());
  const handleUpdateCameraScript = (cS: CameraScriptRaw) => dispatch(CameraScriptAction.updateCameraScript(cS));
  const handleStartCameraScript = () => dispatch(CameraScriptAction.startCameraScript());

  const controlsViewState = viewState && {
    latitude: viewState.latitude,
    longitude: viewState.longitude,
    zoom: viewState.zoom,
    altitude: viewState.altitude,
    bearing: viewState.bearing,
    pitch: viewState.pitch,
  };

  return (
    <CameraScriptControls
      viewState={controlsViewState}
      handleUpdateCameraScript={handleUpdateCameraScript}
      handleExampleCameraScript={handleExampleCameraScript}
      handleStartCameraScript={handleStartCameraScript}
      cameraScript={cameraScript}
    />
  );
};

export default CameraScriptControlsContainer;
