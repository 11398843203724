import { css } from 'emotion';
import { darken } from 'polished';
import { colors, rounded, boxShadow, transition } from 'styles';

export const dialogButtonStyle = css`
  background-color: ${colors.white};
  border-radius: ${rounded.sm};
  border: none;
  box-shadow: ${boxShadow.sm};
  transition: ${transition};
  margin: 0.25rem;
  > .text {
    margin: 0;
  }
  :hover {
    cursor: pointer;
    background-color: ${colors.gray[300]};
    > .text {
      color: ${colors.white};
    }
  }
  :active {
    background-color: ${colors.primary.default};
    > .text {
      color: ${colors.white};
    }
  }
`;

export const baseButtonStyle = css`
  display: block;
  background-color: ${colors.white};
  border-radius: ${rounded.sm};
  border: none;
  padding: 0.5rem 1rem;
  margin: 0.25rem 0;
  transition: ${transition};
  :hover {
    cursor: pointer;
    background-color: ${colors.gray[300]};
    color: ${colors.white};
  }
  :active {
    background-color: ${darken(0.1, colors.gray[300])};
  }
`;
