import React, { useMemo } from 'react';
import { css } from 'emotion';
import SVG from 'react-inlinesvg';
import { Dropdown, Menu } from 'antd';
import { transparentize } from 'polished';
import { Link } from 'react-router-dom';

import { styleConfig } from 'styles/demo';
import { Language } from '../../models/interfaces/languages/Language';

const { colors, fontSize, padding, margin } = styleConfig;

const dropDownStyle = css`
  font-size: ${fontSize.s};
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  align-items: center;
  justify-content: space-evenly;
  color: ${colors.gray[200]};
  border-radius: 9999px;
  border: none;
  width: 3rem;
  height: 2rem;
  display: flex;
  margin-right: ${margin[1]};
  padding: ${padding[2]};
  background-color: ${colors.gray[600]};
  svg {
    width: 0.6rem;
    height: 0.6rem;
  }
  &:hover {
    color: ${colors.black};
    cursor: pointer;
    background-color: ${colors.gray[300]};
    svg path {
      fill: ${colors.black};
    }
  }
`;

const overlayStyle = css`
  ul li {
    &:hover {
      background-color: ${transparentize(0.1, colors.gray[200])} !important;
    }
  }
`;

export interface LanguageDropdownProps {
  languages: Array<Language>;
  selected: Language;
  onSelectLanguage?: (lng: Language) => void;
}

const LanguageDropdown: React.FC<LanguageDropdownProps> = (props) => {
  const { languages, selected, onSelectLanguage } = props;

  const menu = useMemo(
    () => (
      <Menu
        onClick={({ key }) => {
          if (onSelectLanguage) {
            const selectedLanguage = languages.find((lng) => key === lng.locale);
            if (selectedLanguage) onSelectLanguage(selectedLanguage);
          }
        }}
      >
        {languages.map((language) => {
          return (
            <Menu.Item key={language.locale}>
              <Link to={`/${language.locale}`}>{language.name}</Link>
            </Menu.Item>
          );
        })}
      </Menu>
    ),
    [languages, onSelectLanguage]
  );

  return (
    <Dropdown trigger={['click']} overlay={menu} overlayClassName={overlayStyle}>
      <div role="button" className={dropDownStyle}>
        {selected.name}
        <SVG src="icons/arrow-down.svg" />
      </div>
    </Dropdown>
  );
};

export default LanguageDropdown;
