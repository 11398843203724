import { combineReducers } from 'redux';
import app, { AppState } from './appReducer';
import search, { SearchState } from './searchReducer';
import map, { MapState } from './mapReducer';
import camera, { CameraState } from './cameraReducer';
import charts, { ChartDataState } from './chartsReducer';
import toolbar, { ToolbarState } from './toolbarReducer';
import tooltip, { TooltipState } from './tooltipReducer';
import layers, { LayerState } from './layersReducer';
import tags, { TagsState } from './tagsReducer';
import dock, { DockState } from './dockReducer';
import tutorial, { TutorialState } from './tutorialReducer';
import globalFilters, { GlobalFilterState } from './globalFiltersReducer';
import language, { LanguageState } from './languageReducer';
import cachedData, { CachedDataState } from './cachedDataReducer';
import scenarios, { ScenariosState } from './scenariosReducer';
import debug, { DebugState } from './debugReducer';

export interface RootState {
  app: AppState;
  search: SearchState;
  map: MapState;
  camera: CameraState;
  toolbar: ToolbarState;
  tooltip: TooltipState;
  layers: LayerState;
  tags: TagsState;
  dock: DockState;
  tutorial: TutorialState;
  globalFilters: GlobalFilterState;
  language: LanguageState;
  cachedData: CachedDataState;
  charts: ChartDataState;
  scenarios: ScenariosState;
  debug: DebugState;
}

const rootReducer = combineReducers({
  app,
  search,
  map,
  camera,
  toolbar,
  tooltip,
  layers,
  tags,
  dock,
  tutorial,
  globalFilters,
  language,
  cachedData,
  charts,
  scenarios,
  debug,
});

export default rootReducer;
