import { Coordinates } from 'viewport-mercator-project';

const segmentIds: number[] = [
  418217,
  418218,
  422618,
  441019,
  484721,
  488820,
  489614,
  492750,
  494168,
  498554,
  499618,
  506887,
  508536,
  511225,
  515065,
  516133,
  516461,
  519581,
  521737,
  522677,
  522691,
  523570,
  523789,
  524370,
  524768,
  526037,
  526407,
  527481,
  537041,
  539346,
  539396,
  546939,
  550178,
  560608,
  564109,
  571326,
  1047942,
  1054268,
  1054336,
  1054337,
  1054338,
  1054339,
  1054361,
  1054362,
  1054363,
  1054364,
  1054365,
  1054366,
  1054367,
  1054368,
  1054369,
  1054370,
  1054376,
  1054419,
  1053908,
];

const mockRoadPaths: Coordinates[][] = [
  [
    [4.440096616744995, 51.208439447915204],
    [4.439409971237183, 51.20850666284509],
    [4.438473880290985, 51.20861756726493],
    [4.437417089939117, 51.208832653863325],
    [4.4361913204193115, 51.20882929314297],
    [4.432178735733032, 51.20923257783682],
    [4.428429007530212, 51.20992487499291],
    [4.425650238990784, 51.2106003587884],
    [4.425086975097656, 51.21066084939121],
    [4.424840211868286, 51.21063732527733],
    [4.423450827598572, 51.210694455247285],
    [4.418805241584778, 51.21035503497366],
    [4.418585300445557, 51.21037183801638],
    [4.417920112609863, 51.21033151070358],
  ],
  [
    [4.417954981327057, 51.21023405288523],
    [4.418604075908661, 51.210297904582625],
    [4.418807923793793, 51.21035503497366],
  ],
  [
    [4.424840211868286, 51.210635644983014],
    [4.425052106380463, 51.210575154347154],
    [4.425534904003143, 51.21052642572156],
    [4.426095485687256, 51.210397042568935],
    [4.428353905677795, 51.20982405566618],
    [4.432119727134705, 51.2091418390886],
    [4.437119364738464, 51.20861756726493],
    [4.4400376081466675, 51.208308378519796],
  ],
  [
    [4.437423795461655, 51.20882929314297],
    [4.437308460474014, 51.20879232520264],
    [4.436770677566528, 51.20878980466017],
    [4.436668753623962, 51.20874275450885],
    [4.4366660714149475, 51.20867974083799],
    [4.437004029750824, 51.208405840413484],
    [4.437207877635956, 51.20836551137904],
    [4.437374174594879, 51.208407520789144],
    [4.4375766813755035, 51.20851170396088],
    [4.437642395496368, 51.20870410613428],
    [4.437567293643951, 51.20880072700987],
  ],
  [
    [4.438675045967101, 51.20879064484102],
    [4.437763094902039, 51.20895363963349],
    [4.437685310840607, 51.208911630721516],
  ],
  [
    [4.43772554397583, 51.20880240737113],
    [4.437658488750458, 51.20890490929207],
    [4.436169862747192, 51.209133437343624],
  ],
];

const mockRoadSigns: Coordinates[] = [
  [4.417957663536071, 51.21027438028334],
  [4.440010786056518, 51.208370552510274],
  [4.425709247589111, 51.21051298333302],
  [4.4321465492248535, 51.209222495762525],
  [4.436008930206299, 51.208819210980344],
];

export default { segmentIds, mockRoadSigns, mockRoadPaths };
