import { Action } from 'redux';
import { CameraScriptRaw } from '../../models/interfaces/Camera';

enum Camera {
  GET_SCRIPT = 'camera/GET_SCRIPT',
  GET_SCRIPT_COMPLETED = 'camera/GET_SCRIPT_COMPLETED',
  GET_SCRIPT_FAILED = 'camera/GET_SCRIPT_FAILED',
  START_SCRIPT = 'camera/START_SCRIPT',
  INTERRUPT_SCRIPT = 'camera/INTERRUPT_SCRIPT',
  STOP_SCRIPT = 'camera/STOP_SCRIPT',
  PLAY_SCRIPT = 'camera/PLAY_SCRIPT',
  UPDATE_SCRIPT = 'camera/UPDATE_SCRIPT',
}

export interface GetCameraScriptAction {
  type: typeof Camera.GET_SCRIPT;
}

export interface GetCameraScriptCompletedAction {
  type: typeof Camera.GET_SCRIPT_COMPLETED;
  payload: CameraScriptRaw;
}

export interface GetCameraScriptFailedAction {
  type: typeof Camera.GET_SCRIPT_FAILED;
  payload: Error;
  error: boolean;
}

export interface StartCameraScriptAction {
  type: typeof Camera.START_SCRIPT;
}
export interface InterruptCameraScriptAction {
  type: typeof Camera.INTERRUPT_SCRIPT;
}
export interface StopCameraScriptAction {
  type: typeof Camera.STOP_SCRIPT;
}

export interface PlayCameraScriptAction {
  type: typeof Camera.PLAY_SCRIPT;
}

export interface UpdateCameraScriptAction {
  type: typeof Camera.UPDATE_SCRIPT;
  payload: CameraScriptRaw;
}

export type CameraScriptActionType =
  | GetCameraScriptAction
  | GetCameraScriptCompletedAction
  | GetCameraScriptFailedAction
  | StartCameraScriptAction
  | InterruptCameraScriptAction
  | StopCameraScriptAction
  | PlayCameraScriptAction
  | UpdateCameraScriptAction;

export class CameraScriptAction implements Action<Camera> {
  type: Camera;

  static Types = Camera;

  constructor(type: Camera) {
    this.type = type;
  }

  static getCameraScript = (): GetCameraScriptAction => ({
    type: Camera.GET_SCRIPT,
  });

  static getCameraScriptCompleted = (script: CameraScriptRaw): GetCameraScriptCompletedAction => ({
    type: Camera.GET_SCRIPT_COMPLETED,
    payload: script,
  });

  static getCameraScriptFailed = (error: Error): GetCameraScriptFailedAction => ({
    type: Camera.GET_SCRIPT_FAILED,
    payload: error,
    error: true,
  });

  static startCameraScript = (): StartCameraScriptAction => ({
    type: Camera.START_SCRIPT,
  });

  static interruptCameraScript = (): InterruptCameraScriptAction => ({
    type: Camera.INTERRUPT_SCRIPT,
  });

  static stopCameraScript = (): StopCameraScriptAction => ({
    type: Camera.STOP_SCRIPT,
  });

  static updateCameraScript = (script: CameraScriptRaw): UpdateCameraScriptAction => ({
    type: Camera.UPDATE_SCRIPT,
    payload: script,
  });
}

export default CameraScriptAction;
