import { call, put } from 'redux-saga/effects';
import CarouselSearchAction, { CarouselSearchStartAction } from '../../actions/search/CarouselSearchAction';
import { searchCarouselLayers } from '../../api/search';
import { Layer } from '../../models/interfaces/api/Layer';

export function* carouselSearchSaga(action: CarouselSearchStartAction) {
  try {
    const response: Layer[] = yield call(searchCarouselLayers, action.payload.layerType, action.payload.query);
    yield put(CarouselSearchAction.carouselSearchCompleted(action.payload.layerType, response));
  } catch (error) {
    yield put(CarouselSearchAction.carouselSeachFailed(error));
  }
}

export default carouselSearchSaga;
