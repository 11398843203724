import React from 'react';
import { css, cx } from 'emotion';
import { colors, cardStyle, transition } from 'styles';

export interface ButtonConfig {
  icon: React.FunctionComponent | React.ReactElement;
  title: string;
  className?: string;
  onClick?: () => void;
  customStyle?: React.CSSProperties;
}

const button = css`
  ${cardStyle};
  height: 50px;
  width: 50px;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  pointer-events: auto;
  transition: ${transition};
  cursor: pointer;
  outline: none;
  &:hover {
    background-color: ${colors.gray[200]};
    > svg .icon,
    > svg path {
      fill: ${colors.primary.default};
    }
  }
  > svg {
    width: 100%;
    .icon,
    path {
      fill: ${colors.gray[600]};
    }
  }
`;

const OnScreenControlButton = (props: ButtonConfig) => {
  return (
    <button
      type="button"
      style={props.customStyle}
      className={cx(button, props.className)}
      onClick={props.onClick}
      title={props.title}
    >
      {typeof props.icon === 'object' ? props.icon : <props.icon />}
    </button>
  );
};

export default OnScreenControlButton;
