import React from 'react';
import { styleConfig, cardStyle } from 'styles/demo';
import { css } from 'emotion';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';

const { colors, margin, padding, fontSize } = styleConfig;

const appLogoWrapperStyle = css`
  ${cardStyle}
  text-transform: uppercase;
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: space-evenly;
  bottom: 0;
  left: 0;
  font-size: ${fontSize.xs};
  font-weight: bold;
  text-align: center;
  flex-direction: row;
  margin-bottom: ${margin[8]};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: ${padding[3]} ${padding[3]} ${padding[3]} ${padding[6]};
  color: ${colors.white};

  svg {
    margin-inline-start: ${margin[2]};
    width: 1rem;
    height: 1rem;
    transform: scale(-1, 1);
  }

  &:hover {
    color: ${colors.gray[300]};
    cursor: pointer;
    svg path {
      fill: ${colors.gray[300]};
    }
  }
`;

interface Props {
  onClick: () => void;
}
const RestartContainer: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <div className={appLogoWrapperStyle} onClick={props.onClick}>
      <div>{t('scenarios.header.restart')}</div>
      <SVG src="icons/reset.svg" />
    </div>
  );
};

export default RestartContainer;
