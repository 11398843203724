import React from 'react';
import { css } from 'emotion';
import { styleConfig } from 'styles/demo';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { BotAction } from 'models/interfaces/bot/BotAction';
import AppAction from 'actions/app/AppAction';

const { margin, padding } = styleConfig;

interface Props {
  actions: BotAction[];
}

const style = css`
  grid-column-start: 1;
  grid-column-end: -1;
  justify-self: center;
  padding: ${padding[2]};
  display: flex;
  flex-direction: column;
  width: 100%;

  button:not(:last-child) {
    margin-bottom: ${margin[1]};
  }
`;
const BotActionsFragment: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  return (
    <div className={style}>
      {props.actions.map((action) => (
        <Button
          block
          key={action.triggerId}
          onClick={() => {
            if (action.triggerId) {
              dispatch(AppAction.startBotTrigger(action.triggerId));
            } else {
              dispatch(AppAction.stopBotTrigger());
            }
          }}
        >
          {action.label}
        </Button>
      ))}
    </div>
  );
};

export default BotActionsFragment;
