import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { transparentize } from 'polished';
import { css } from 'emotion';

import { colors, rounded, controlsH2Style, controlsH1Style, controlsH3Style } from 'styles';
import { cameraScriptControlsEnabled } from 'config/cameraScript';
import CameraScriptControlsContainer from './CameraScriptControlsContainer';
import MapControlsContainer from './MapControlsContainer';
import DebugAction from '../../actions/debug/DebugAction';

const controlsStyle = css`
  position: absolute;
  top: 2rem;
  left: 100px;
  z-index: 30;
  background-color: ${transparentize(0.15, colors.gray[800])};
  padding: 1.5rem;
  border-radius: ${rounded.md};
  max-width: calc(100% - 240px);
  max-height: 85vh;
  overflow: auto;
  h1 {
    ${controlsH1Style}
  }
  h2 {
    ${controlsH2Style}
  }
  h3 {
    ${controlsH3Style}
  }
`;

const ControlsContainer: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const [showControls, toggleControls] = useState<boolean>(false);

  const handleUserKeyPress = (event: KeyboardEvent): void => {
    if (event.key === '.' && event.ctrlKey) {
      toggleControls(!showControls);
      dispatch(DebugAction.toggleControlPanel());
    }
  };

  /**
   * Show controls on keydown
   * @windows CTRL (azerty? + SHIFT) + .
   * @macOs CTRL + .
   */
  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showControls]);

  if (showControls) {
    return (
      <div className={controlsStyle}>
        <h1>Controls</h1>
        {cameraScriptControlsEnabled && <CameraScriptControlsContainer />}
        <h2>Map</h2>
        <h3>Force LOD level</h3>
        <MapControlsContainer />
      </div>
    );
  }
  return null;
};

export default ControlsContainer;
