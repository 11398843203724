export const StormGems = [
  {
    info: 'gems.storm.floodingRisk',
    coordinates: [4.38575, 51.19221],
    icon: 'information-1.svg',
  },
  {
    info: 'gems.storm.floodingTraffic',
    coordinates: [4.397751, 51.195779],
    icon: 'information-2.svg',
  },
  {
    info: 'gems.storm.airQuality',
    coordinates: [4.4143, 51.191139],
    icon: 'information-3.svg',
  },
];

export const ClosedRoadsGems = [
  {
    info: 'gems.closedStreet.mainAccesBlocked',
    coordinates: [4.428683, 51.20795],
    icon: 'information-1.svg',
  },
  {
    info: 'gems.closedStreet.streetCanyoning',
    coordinates: [4.43566, 51.211393],
    icon: 'information-3.svg',
  },
  {
    info: 'gems.closedStreet.airPollution',
    coordinates: [4.4243, 51.209837],
    icon: 'information-2.svg',
  },
];
