import { DirectionalLight } from '@deck.gl/core';
import { getPosition } from 'suncalc';

export default class SunLight extends DirectionalLight {
  constructor({ timestamp, intensity, ...others }) {
    super(others);

    this.timestamp = timestamp;
    this.intensity = intensity;
  }

  getProjectedLight({ layer }) {
    const { viewport } = layer.context;
    const position = getPosition(this.timestamp, viewport.latitude, viewport.longitude);

    // solar position to light direction
    this.direction = [
      Math.sin(position.azimuth + 3.14) * Math.cos(position.altitude),
      Math.cos(position.azimuth + 3.14) * Math.cos(position.altitude),
      -Math.sin(position.altitude),
    ];

    return this;
  }
}
