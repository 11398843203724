import { IconLayer } from '@deck.gl/layers';
import { Coordinates } from 'viewport-mercator-project';

const get3 = () => 3;

/**
 * @param floodingLocations Array of coordinates of where to place sign posts
 */
export default function createFloodingIconsLayer(floodingLocations: Coordinates[]) {
  // Add explanation to flooding icons
  const extendedFloodingLocations = floodingLocations.map((coord) => {
    return { info: 'gems.storm.info', coordinates: coord };
  });
  return new IconLayer({
    id: 'flooding-icons-layer',
    data: extendedFloodingLocations,
    billboard: true,
    pickable: true,
    // To avoid the icon layer from intersecting with the 3D buildings we apply a polygon offset: https://deck.gl/docs/api-reference/core/layer#getpolygonoffset
    // This will directly modify the depth buffer of WebGL to move it towards the camera a slight bit which avoids the insections in most cases
    // The number -30000 is very much a magic number, it does not have a real unit as it is dependent on WebGL camera settings.
    // It will also not work for every city e.g. New York (skyscrapers) but seems to work well for Antwerp
    getPolygonOffset: () => [0, -30000],
    getIcon: () => ({
      url: '/images/flood-marker.svg',
      width: 300,
      height: 330,
      anchorY: 330,
    }),
    sizeScale: 15,
    getPosition: (d: any) => d.coordinates,
    getSize: get3,
  });
}
