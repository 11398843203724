// TODO: remove this cloneDeep https://redux.js.org/faq/performance#do-i-have-to-deep-clone-my-state-in-a-reducer-isnt-copying-my-state-going-to-be-slow
import { cloneDeep as _cloneDeep } from 'lodash';
import ChartsAction, { ChartsActionType } from '../actions/charts/ChartsAction';
import LayersUIAction, { LayersUIActionType } from '../actions/layers/LayersUIAction';
import { SeriesGroup } from '../models/interfaces/Chart';

export interface ChartDataState {
  [layerId: number]: {
    [visualizationId: number]: {
      readonly loading: boolean;
      readonly visualizationId: number | null;
      readonly seriesGroup: SeriesGroup;
    };
  };
}

const initialState: ChartDataState = {};

const reducer = (state: ChartDataState = initialState, action: ChartsActionType | LayersUIActionType) => {
  switch (action.type) {
    case ChartsAction.Types.GET_DATA: {
      const { layerId } = action.payload;

      // if layerId does exist in the list
      if (state?.[layerId]) return state;

      let newState = _cloneDeep(state);

      newState = {
        ...newState,
        [layerId]: {},
      };

      return newState;
    }

    case ChartsAction.Types.GET_DATA_COMPLETED: {
      const { layerId, visualizationId, seriesGroup } = action.payload;
      const newState = { ...state };
      newState[layerId] = {
        ...newState[layerId],
        [visualizationId]: {
          visualizationId,
          loading: false,
          seriesGroup: {
            ...(newState[layerId][visualizationId]?.seriesGroup || {}),
            ...seriesGroup,
          },
        },
      };
      return newState;
    }

    case ChartsAction.Types.GET_DATA_FAILED: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case LayersUIAction.Types.REMOVE_LAYER: {
      // TODO: remove this cloneDeep
      const newState = _cloneDeep(state);
      const layerId = action.payload.id;
      delete newState[layerId];
      return newState;
    }

    default:
      return state;
  }
};

export default reducer;
