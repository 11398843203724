import { Layer as DeckGLLayer } from '@deck.gl/core';
import { BitmapLayer } from '@deck.gl/layers';
import { ImageHeatmapWrapper } from 'models/interfaces/api/ImageHeatmapWrapper';
import jmespath from 'jmespath';
import { CachedData } from '../../models/interfaces/api/CachedData';

interface Image {
  url: string;
  WGS84Coordinates: [number, number, number, number];
}

export default function createBitmapLayer(
  visualizationWrapper: ImageHeatmapWrapper,
  data: CachedData,
  currentStepIndex: number
): DeckGLLayer {
  const { visualization } = visualizationWrapper;
  const { mapping } = visualization.dataSources[0];
  const { rawData } = data;

  const images = jmespath.search(rawData, mapping.url) as Array<Image>;
  if (images.length === 0) {
    console.warn('[CreateImageHeatmap] The cached data contains no image data');
    return;
  }

  const currentTiff = images[Math.min(currentStepIndex, images.length - 1)];

  return new BitmapLayer({
    id: `${visualization.title}Layer${visualization.id}`,
    image: currentTiff.url,
    bounds: currentTiff.WGS84Coordinates,
  });
}
