import { call, put, select } from 'redux-saga/effects';
import moment from 'moment';
import { cachedDataRetention } from 'config/cache';
import LayersAction, { GetLayersAction } from '../actions/layers/LayersAction';
import DockAction from '../actions/dock/DockAction';
import { AddLayerAction } from '../actions/layers/LayersUIAction';
import { getLayers } from '../api/layers';
import { getDockLayerById } from '../selectors/dockSelectors';
import { getAppMode } from '../selectors/appSelectors';
import { AppMode } from '../models/types/AppMode';
import { DockLayer } from '../models/interfaces/DockLayer';
import { Layer } from '../models/interfaces/api/Layer';

/**
 * Whenever a layer is chosen and is not active =>
 * - activate the layer
 * - toggle the default visualization
 * Note: disabled for the DEMO mode
 */
export function* activateLayer(action: AddLayerAction) {
  try {
    const appMode = yield select(getAppMode);
    if (appMode === AppMode.DEMO) return;

    const { id: layerId, defaultVisualizationId } = action.payload;
    const dockLayer: DockLayer = yield select(getDockLayerById(layerId));
    if (dockLayer.active) return;

    yield put(DockAction.toggleLayerVisualization(layerId));
    yield put(LayersAction.getLayerData(layerId, defaultVisualizationId, true));
    yield put(DockAction.toggleLayerMapVisualization(layerId, defaultVisualizationId));
  } catch (error) {
    console.error('[LayerSaga]: Error: Someting went wrong while activating the layer.');
  }
}

export function* getLayersSaga(action: GetLayersAction) {
  try {
    const response: Array<Layer> = yield call(getLayers, action.payload);
    const { length, timeUnit } = cachedDataRetention;
    const expirationDate = moment().add(length, timeUnit).toDate();
    yield put(LayersAction.getLayersCompleted(response, action.payload, expirationDate));
  } catch (error) {
    yield put(LayersAction.getLayersFailed(error));
  }
}

export default getLayersSaga;
