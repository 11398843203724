import React from 'react';
import i18next from 'i18next';
import { LinearGradientLegend as APILinearGradientLegend } from '../../models/interfaces/legends/LinearGradientLegend';
import { SteppedColorRangeLegend as APISteppedColorRangeLegend } from '../../models/interfaces/legends/SteppedColorRangeLegend';
import SteppedColorRangeLegend from './SteppedColorRangeLegend';
import LinearColorRangeLegend from './LinearColorRangeLegend';
import { MapVisualizationWrapper } from '../../models/interfaces/api/VisualizationWrapper';
import LayerMapVisualizationType from '../../models/types/LayerMapVisualizationType';

export interface AdditionalParams {
  // size of a legend block
  blockSize: number;
  className?: string;
  labelLeft?: string;
  labelRight?: string;
  t: i18next.TFunction;
}

const LegendComponentFactory = (
  visualizationWrapper: MapVisualizationWrapper,
  additionalParams: AdditionalParams
): JSX.Element => {
  switch (visualizationWrapper.type) {
    case LayerMapVisualizationType.FLOW: {
      const legend = visualizationWrapper.legend as APISteppedColorRangeLegend;
      const element = SteppedColorRangeLegend({ ...additionalParams, SteppedColorRangeLegend: legend });
      if (element) return element;
      break;
    }
    case LayerMapVisualizationType.IMAGE_HEATMAP:
    case LayerMapVisualizationType.GRID: {
      const legend = visualizationWrapper.legend as APILinearGradientLegend;
      const element = LinearColorRangeLegend({ ...additionalParams, legend });
      if (element) return element;
      break;
    }
    default:
      console.error(
        `[REACTLEGENDFACTORY] ERROR: VisualizationType: ${visualizationWrapper.type} is not currently supported`
      );
      break;
  }
  // Return empty as fallback
  return <></>;
};

export default LegendComponentFactory;
