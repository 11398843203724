import { WaterExtension } from 'custom-layers';
import { GeoJsonLayer, TextLayer, IconLayer } from '@deck.gl/layers';
import { MVTLayer } from '@deck.gl/geo-layers';
import { defaultLODMode } from 'config/app';
import { NeighborhoodLabel, neighborhoodLabels, SEARCH_RESULT_LAYER, MAP_CONFIG } from 'config/map';
import { omit as _omit } from 'lodash';
import closedRoads from 'metadata/closedRoads';
import { floodIconLocations } from 'metadata/stormFlood';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentTimeIntervalAsIndex } from 'selectors/globalFiltersSelectors';
import { fontFamily } from 'styles';
import { Interpolator, ViewState } from 'models/interfaces/Camera';
import { deckGLMapLayerFactory } from '../utils/DeckGLMapLayers/deckGLMapLayerFactory';
import { AppMode } from '../models/types/AppMode';
import Map from '../components/map/Map';
import ClosedRoadSegmentsLayer from '../components/layers/ClosedRoadSegmentsLayer';
import TooltipContainer from './TooltipContainer';
import TooltipAction from '../actions/tooltip/TooltipAction';
import MapAction from '../actions/map/MapAction';
import CameraScriptAction from '../actions/camera/CameraAction';
import AppAction from '../actions/app/AppAction';
import { getAppMode, getLODMode, getActiveGems } from '../selectors/appSelectors';
import { getCachedDataMap } from '../selectors/cachedDataSelectors';
import { getCameraScript } from '../selectors/cameraSelectors';
import { getActiveDockLayers } from '../selectors/dockSelectors';
import { getBoundingBox, getInitialViewBox } from '../selectors/mapSelectors';
import { getCurrentScenarioId, getStormScenarioIsActive } from '../selectors/scenariosSelectors';
import { getSearchResult } from '../selectors/searchSelectors';
import { DeckGLPickedInfo } from '../models/interfaces/deckgl/DeckGLPickedInfo';
import { LODMode } from '../models/types/LODMode';
import createFloodingIconsLayer from '../components/layers/FloodingIconsLayer';

interface ViewStateTransition {
  transitionDuration?: number;
  transitionInterpolator?: Interpolator;
  transitionEasing?: (t: number) => void;
  onTransitionStart?: () => void;
  onTransitionInterrupt?: () => void;
  onTransitionEnd?: () => void;
}

export interface ViewStateWithTransition extends ViewState, ViewStateTransition {}

const MapContainer: React.FC = () => {
  const dispatch = useDispatch();

  const appMode = useSelector(getAppMode);
  const lodMode = useSelector(getLODMode);
  const boundingBox = useSelector(getBoundingBox);
  const initialViewBox = useSelector(getInitialViewBox);
  const searchResult = useSelector(getSearchResult);
  const activeDockLayers = useSelector(getActiveDockLayers);
  const cachedDataMap = useSelector(getCachedDataMap);
  const cameraScript = useSelector(getCameraScript);
  const currentTimeInterval = useSelector(getCurrentTimeIntervalAsIndex);
  const currentScenarioId = useSelector(getCurrentScenarioId);
  const isStormScenarioActive = useSelector(getStormScenarioIsActive);
  const activeGems = useSelector(getActiveGems);

  // FIXME: remove this check, once the data is available for road closed event
  const isClosedRoads = currentScenarioId === 2;

  const handleViewState = (vS: ViewStateWithTransition) => dispatch(MapAction.setViewState(vS));
  const handleCameraScriptFinished = () => dispatch(CameraScriptAction.stopCameraScript());
  const handleCameraScriptInterrupted = () => dispatch(CameraScriptAction.interruptCameraScript());

  const handleOnHover = (pickedInfo: DeckGLPickedInfo<object>) => {
    if (!pickedInfo.object) {
      dispatch(TooltipAction.hideTooltipAction());
      return;
    }
    dispatch(
      TooltipAction.showTooltipAction(
        { x: pickedInfo.x, y: pickedInfo.y },
        // TODO: find a more generic way to omit fields from the tooltip or improve the behaviour of the tooltip with large data
        _omit(pickedInfo.object, ['path']),
        pickedInfo.layer.props.tooltipTitle
      )
    );
  };

  // this essentially turns buildings on/off
  const handleChangeLODMode = () => {
    let newlodMode = LODMode.LOD0;
    // Toggle between LOD0 and the lodmode configured for the environment
    if (lodMode === LODMode.LOD0) {
      newlodMode = defaultLODMode;
    }
    dispatch(AppAction.setLODMode(newlodMode));
  };

  const getActiveLayers = useCallback(() => {
    return activeDockLayers.map((activeLayer) => {
      const activeVisualizations = activeLayer.visualizations.filter((vis) =>
        activeLayer.activeMapVisualizations.has(vis.visualization.id)
      );
      const allLayerCachedData = cachedDataMap.get(activeLayer.id);
      return activeVisualizations.map((visWrapper) => {
        const cachedData = allLayerCachedData?.get(visWrapper.visualization.id);
        return { cachedData, visWrapper };
      });
    });
  }, [activeDockLayers, cachedDataMap]);

  /**
   * Shows the names of neighbourhoods on top of the map
   */
  const labelsLayer = useMemo(() => {
    const fontSize = 24;
    const zCoord = 30;
    return new TextLayer({
      id: 'neighbourhood-labels-layer',
      data: neighborhoodLabels,
      fontSettings: { fontSize },
      fontFamily,
      fontWeight: '600',
      getText: (d: NeighborhoodLabel) => d.label.toUpperCase(),
      getPosition: (d: NeighborhoodLabel) => [...d.coordinates, zCoord],
      getColor: () => [95, 95, 100],
      getSize: () => 20,
      sizeScale: fontSize / 40,
    });
  }, []);

  /**
   * Visualizes the results of a search on the map
   */
  const searchResultsLayer = useMemo(() => {
    return new GeoJsonLayer({
      id: 'search-results-layer',
      data: searchResult,
      ...SEARCH_RESULT_LAYER,
    });
  }, [searchResult]);

  const iconLayer = useMemo(() => {
    return new IconLayer({
      id: 'icon-layer',
      data: activeGems,
      pickable: true,
      alphaCutoff: 0.05,
      autoHighlight: true,
      billboard: true,
      getIcon: (d: any) => ({
        url: `/icons/${d.icon}`,
        width: 300,
        height: 330,
        anchorY: 330,
      }),
      sizeScale: 13,
      getPolygonOffset: () => [0, -30000],
      getPosition: (d: any) => d.coordinates,
      getSize: () => 3,
    });
  }, [activeGems]);

  /**
   * Visualizes the flooding on the map
   */
  const stormScenarioLayers = useMemo(() => {
    if (!isStormScenarioActive) {
      return undefined;
    }

    return [
      new GeoJsonLayer({
        id: 'flooding-layer',
        data: 'https://digdemodata.blob.core.windows.net/flooding/Flooding.geojson',
        stroked: false,
        filled: true,
        getFillColor: [106, 191, 212, 100],

        normalMap: 'https://digdemodata.blob.core.windows.net/flooding/water_normal_map.jpg',
        // Water extension
        extensions: [
          new WaterExtension({
            // Base water waves layer
            getPrimaryWaterScale: 800,
            getPrimaryWaterScrollSpeed: 0.000028,
            getPrimaryWaterScrollDirection: 45,
            // Secondary water waves, this gives the water less noticeable tiling and makes it more dynamic
            getSecondaryWaterScale: 1040,
            getSecondaryWaterScrollSpeed: 0.000015,
            getSecondaryWaterScrollDirection: 135,
          }),
        ],
      }),
      createFloodingIconsLayer(floodIconLocations),
    ];
  }, [isStormScenarioActive]);

  const mapboxWaterLayer = useMemo(() => {
    return new MVTLayer({
      data: `https://a.tiles.mapbox.com/v4/mapbox.mapbox-streets-v7/{z}/{x}/{y}.vector.pbf?access_token=${MAP_CONFIG.token}`,

      // To allow data filtering on water layers we cannot use the binary flag
      // binary: true,
      stroked: false,
      filled: true,
      minZoom: 12,
      maxZoom: 15,
      extent: [0, 0, 999999, 999999],
      refinementStrategy: 'no-overlap',
      maxCacheSize: 5000,

      normalMap: 'https://digdemodata.blob.core.windows.net/flooding/water_normal_map.jpg',
      // Water extension
      extensions: [
        new WaterExtension({
          // Base water waves layer
          getPrimaryWaterScale: 15,
          getPrimaryWaterScrollSpeed: 0.000028,
          getPrimaryWaterScrollDirection: -45,
          // Secondary water waves, this gives the water less noticeable tiling and makes it more dynamic
          getSecondaryWaterScale: 20,
          getSecondaryWaterScrollSpeed: 0.000015,
          getSecondaryWaterScrollDirection: -135,
        }),
      ],

      renderSubLayers: (props: any) => {
        return new GeoJsonLayer(props, {
          // override props fields
          getFillColor: [147, 182, 200, 70],
          data: props.data.filter((data: any) => {
            return data.properties.layerName === 'water';
          }),
        });
      },
    });
  }, []);

  /**
   * Returns a flat list of all the data layers to be visualized on the map
   */
  const dataLayers = useMemo(() => {
    const activeLayers = getActiveLayers();
    const closedRoadSegmentIds = isClosedRoads ? closedRoads.segmentIds : [];

    const layers = activeLayers.map((layer) => {
      return layer.map(({ cachedData, visWrapper }) => {
        return deckGLMapLayerFactory(visWrapper, cachedData, currentTimeInterval, closedRoadSegmentIds, {
          onHover: handleOnHover,
          pickable: appMode === AppMode.DEFAULT,
        });
      });
    });
    return layers.flat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appMode, getActiveLayers, currentTimeInterval, handleOnHover]);

  /**
   * Layer for visualizing closed road segments
   */
  // TODO: this should be refactored once we implement closing roads, probably linking it to the Roads API data
  const closedRoadsLayer = useMemo(() => {
    // don't visualize road closed if it's not
    if (!isClosedRoads) return undefined;

    const activeLayers = getActiveLayers();
    return activeLayers.map((layer) => {
      return layer.map(({ cachedData }) => {
        if (!cachedData) return undefined;

        if (!closedRoads?.mockRoadPaths.length && !closedRoads?.mockRoadSigns.length) {
          console.warn(`Incorrect mock closed road data`);
          return undefined;
        }

        return ClosedRoadSegmentsLayer(closedRoads.mockRoadPaths, closedRoads.mockRoadSigns, closedRoads.segmentIds);
      });
    });
  }, [getActiveLayers, isClosedRoads]);

  // adding layers
  const memoizedLayers = useMemo(() => {
    return [
      mapboxWaterLayer,
      searchResultsLayer,
      ...dataLayers,
      ...(stormScenarioLayers || []),
      labelsLayer,
      // mapboxWaterLayer,
      ...(closedRoadsLayer || []),
      iconLayer,
    ];
  }, [searchResultsLayer, dataLayers, labelsLayer, stormScenarioLayers, mapboxWaterLayer, closedRoadsLayer, iconLayer]);

  if (boundingBox !== null && initialViewBox != null) {
    return (
      <Map
        boundingBox={boundingBox}
        initialViewBox={initialViewBox}
        lodMode={lodMode}
        layers={memoizedLayers}
        focusGeoJson={searchResult}
        cameraScript={cameraScript}
        showScreenControls={appMode === AppMode.DEFAULT}
        handleCameraScriptFinished={handleCameraScriptFinished}
        handleCameraScriptInterrupted={handleCameraScriptInterrupted}
        handleViewState={handleViewState}
        handleChangeLODMode={handleChangeLODMode}
      >
        <TooltipContainer />
      </Map>
    );
  }
  return <div />;
};

export default MapContainer;
