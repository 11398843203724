import { BitmapLayer } from '@deck.gl/layers';
import { Texture2D } from '@luma.gl/core';
import GL from '@luma.gl/constants';
import fragmentShader from './colorlookup-layer-fragment';

const DEFAULT_TEXTURE_PARAMETERS = {
  [GL.TEXTURE_MIN_FILTER]: GL.LINEAR_MIPMAP_LINEAR,
  [GL.TEXTURE_MAG_FILTER]: GL.LINEAR,
  [GL.TEXTURE_WRAP_S]: GL.CLAMP_TO_EDGE,
  [GL.TEXTURE_WRAP_T]: GL.CLAMP_TO_EDGE,
};

export default class ColorLookUpBitmapLayer extends BitmapLayer {
  updateState({ props, oldProps, changeFlags }) {
    super.updateState({ props, oldProps, changeFlags });
    if (props.colorLookUpImage !== oldProps.colorLookUpImage) {
      this.loadColorLookUpTexture(props.colorLookUpImage);
    }
  }

  shouldUpdateState({ props, oldProps, context, oldContext, changeFlags }) {
    // This is actually broken in DeckGL's shouldUpdateState, so check for the image as well as LUT
    if (props.image !== oldProps.image || props.colorLookUpImage !== oldProps.colorLookUpImage) {
      return true;
    }
    const superResult = super.shouldUpdateState({ props, oldProps, context, oldContext, changeFlags });
    return superResult;
  }

  finalizeState() {
    super.finalizeState();

    if (this.state.lookupTexture) {
      this.state.lookupTexture.delete();
    }
  }

  draw({ uniforms }) {
    super.draw({
      uniforms: { ...uniforms, colorLookUpTexture: this.state.lookupTexture },
    });
  }

  getShaders() {
    return { ...super.getShaders(), fs: fragmentShader };
  }

  loadColorLookUpTexture(image) {
    const { gl } = this.context;

    if (this.state.lookupTexture) {
      this.state.lookupTexture.delete();
    }

    if (image instanceof Texture2D) {
      this.setState({ lookupTexture: image });
    } else if (image instanceof HTMLVideoElement) {
      // Initialize an empty texture while we wait for the video to load
      this.setState({
        lookupTexture: new Texture2D(gl, {
          width: 1,
          height: 1,
          parameters: DEFAULT_TEXTURE_PARAMETERS,
          mipmaps: false,
        }),
      });
    } else if (image) {
      // Browser object: Image, ImageData, HTMLCanvasElement, ImageBitmap
      this.setState({
        lookupTexture: new Texture2D(gl, {
          data: image,
          parameters: DEFAULT_TEXTURE_PARAMETERS,
        }),
      });
    }
  }
}

ColorLookUpBitmapLayer.layerName = 'ColorLookUpBitmapLayer';
ColorLookUpBitmapLayer.defaultProps = {
  // A texture of Width N: height: 1, to use for color look up
  colorLookUpImage: { type: 'object', value: null, async: true },
};
