import TutorialAction, { TutorialActionType } from '../actions/tutorials/TutorialAction';
import TutorialType from '../models/types/TutorialType';

export type TutorialState = {
  enabledForTypeMap: Map<TutorialType, boolean>;
};

const initialState: TutorialState = {
  enabledForTypeMap: new Map([[TutorialType.DROP_ZONE_HINT, false]]),
};

const reducer = (state = initialState, action: TutorialActionType): TutorialState => {
  switch (action.type) {
    case TutorialAction.Types.TOGGLE: {
      const tutType = action.payload;
      const relevantTutState = state.enabledForTypeMap.get(tutType);
      if (relevantTutState === undefined) return state;
      const tutorials = new Map(state.enabledForTypeMap);
      tutorials.set(tutType, !relevantTutState);
      return { ...state, enabledForTypeMap: tutorials };
    }
    default:
      return state;
  }
};

export default reducer;
