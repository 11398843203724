import { css } from 'emotion';
import { darken } from 'polished';
import { colors } from 'styles/config';

export const controlsH1Style = css`
  color: ${colors.white};
`;

export const controlsH2Style = css`
  color: ${darken(0.1, colors.white)};
  text-transform: uppercase;
  font-size: 0.75rem;
  margin-top: 1rem;
`;

export const controlsH3Style = css`
  color: ${darken(0.2, colors.white)};
  font-size: 0.6rem;
  margin-top: 0.5rem;
`;
