export const get = async <T>(url: string) => wrappedFetch<T>('GET', url);
export const getImage = async (url: string) => wrappedFetchImage('GET', url);

async function wrappedFetch<T>(method: string, url: string, payload?: T): Promise<T> {
  const response = await fetch(url, {
    method,
    headers: new Headers({
      'content-type': 'application/json',
    }),
    body: payload && JSON.stringify(payload),
  });
  if (!response.ok) {
    throw await response.json();
  }
  return response.json();
}

async function wrappedFetchImage(method: string, url: string, payload?: File): Promise<Blob> {
  const response = await fetch(url, {
    method,
    headers: new Headers({
      'content-type': 'image/tiff',
    }),
    body: payload,
  });
  if (!response.ok) {
    throw await response.json();
  }
  return response.blob();
}

export const composeRequestURL = (baseURL: string, params: Record<string, string>) => {
  const searchParams = new URLSearchParams(params);
  let newBaseUrl = baseURL;
  if (baseURL.includes('?')) {
    if (baseURL.split('?')[1].length > 0) newBaseUrl += '&';
  } else if (params) newBaseUrl += '?';
  return newBaseUrl + searchParams;
};

export default get;
