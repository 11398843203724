import { call, put } from 'redux-saga/effects';
import TagsAction, { GetTagsAction } from '../actions/tags/TagsAction';
import { getTags } from '../api/tags';
import { Tag } from '../models/interfaces/Tag';

export function* getTagsSaga(action: GetTagsAction) {
  try {
    const response: Array<Tag> = yield call(getTags);
    yield put(TagsAction.getTagsCompleted(response));
  } catch (error) {
    yield put(TagsAction.getTagsFailed(error));
  }
}

export default getTagsSaga;
