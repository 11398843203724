import { css } from 'emotion';

// eslint-disable-next-line import/prefer-default-export
export const flexibleTable = css`
  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table,
  .ant-table-scroll,
  .ant-table-content {
    height: 100%;
  }
  .ant-table-content {
    position: absolute;
    width: 100%;
    > .ant-table-scroll {
      display: flex;
      flex-direction: column;
      > .ant-table-header {
        flex-shrink: 0;
      }
    }
  }
`;
