import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  kioskMode: boolean;
}

const ImecInfoScreenContent: React.FC<Props> = ({ kioskMode }) => {
  const { t } = useTranslation();
  const translationKey = kioskMode ? 'infoscreen.imec.info.kiosk' : 'infoscreen.imec.info';
  return (
    <>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: t(translationKey) }} />
    </>
  );
};

export default ImecInfoScreenContent;
