import { LayerExtension } from '@deck.gl/core';
import { Texture2D } from '@luma.gl/core';
import GL from '@luma.gl/constants';
import { angleToVector2D } from '../../utils/vectorUtils';

import Water from './Water-module';

const defaultProps = {
  normalMap: null,
};

// We need to get the time since the app started for animating the water shader, we store the start time here for later
const startTime = Date.now();

export default class WaterExtension extends LayerExtension {
  // eslint-disable-next-line class-methods-use-this
  isEnabled(layer) {
    return layer.getAttributeManager() && !layer.state.pathTesselator;
  }

  getShaders(extension) {
    if (!extension.isEnabled(this)) {
      return null;
    }

    return {
      modules: [Water].filter(Boolean),
    };
  }

  initializeState(context, extension) {
    if (!extension.isEnabled(this)) {
      return;
    }

    const attributeManager = this.getAttributeManager();

    // We set fill color as an attribute to keep it consistent with default DeckGL behaviour and in case we want to change the fill color depending on the region
    attributeManager.add({
      waterColors: {
        size: 4,
        type: GL.FLOAT,
        accessor: 'getFillColor',
        defaultValue: [1, 1, 1, 1],
        shaderAttributes: {
          waterColors: {
            divisor: 0,
          },
          instancewaterColors: {
            divisor: 1,
          },
        },
      },
    });

    this.setState({
      emptyTexture: new Texture2D(this.context.gl, {
        data: new Uint8Array(4),
        width: 1,
        height: 1,
      }),
    });
  }

  updateState({ props, oldProps }, extension) {
    if (!extension.isEnabled(this)) {
      return;
    }

    if (props.normalMap && props.normalMap !== oldProps.normalMap) {
      extension.loadTexture.call(this, props);
    }
  }

  draw(params, extension) {
    if (!extension.isEnabled(this)) {
      return;
    }

    this.setModuleParameters({
      primaryWavesScales: extension.opts.getPrimaryWaterScale,
      secondaryWavesScales: extension.opts.getSecondaryWaterScale,
      primaryWavesScrollSpeeds: angleToVector2D(
        extension.opts.getPrimaryWaterScrollDirection,
        extension.opts.getPrimaryWaterScrollSpeed
      ),
      secondaryWavesScrollSpeeds: angleToVector2D(
        extension.opts.getSecondaryWaterScrollDirection,
        extension.opts.getSecondaryWaterScrollSpeed
      ),
      normapMapTexture: this.state.normapMapTexture || this.state.emptyTexture,
      time: Date.now() - startTime,
    });
  }

  finalizeState() {
    if (this.state.normapMapTexture) {
      this.state.normapMapTexture.delete();
    }
    if (this.state.emptyTexture) {
      this.state.emptyTexture.delete();
    }
  }

  // loadTexture loads the external normal map image and converts it to Luma.gl Texture2D
  async loadTexture(props) {
    if (this.state.normapMapTexture) {
      this.state.normapMapTexture.delete();
    }
    this.setState({ normapMapTexture: null });
    let image = props.normalMap;
    if (typeof image === 'string') {
      image = await props.fetch(image, { propName: 'normalMap', layer: this });
    }

    const normapMapTexture =
      image instanceof Texture2D
        ? image
        : new Texture2D(this.context.gl, {
            data: image,
            mipmaps: true,
          });
    this.setState({ normapMapTexture });
  }
}

WaterExtension.extensionName = 'WaterExtension';
WaterExtension.defaultProps = defaultProps;
