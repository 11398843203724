import React from 'react';
import chroma from 'chroma-js';
import { last as _last } from 'lodash';
import { css, cx } from 'emotion';
import i18next from 'i18next';

import styleConfig from 'styles/demo/config';
import { getColorScaleFromLinearGradientLegend } from 'utils/DeckGLMapLayers/legendColorUtils';
import { LinearGradientLegend as APILinearGradientLegend } from 'models/interfaces/legends/LinearGradientLegend';

const { colors, fontSize } = styleConfig;

const legendWrapperStyle = css`
  .labels-top,
  .labels-bottom {
    display: flex;
    justify-content: space-between;
    color: ${colors.gray[200]};
    font-size: ${fontSize.xs};
    opacity: 0.8;
  }
  .labels-top {
    text-transform: uppercase;
    font-weight: bold;
  }
  .blocks {
    .block {
      margin: 1.5px;
      border-radius: 2px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

interface Props {
  legend: APILinearGradientLegend;
  blockSize: number;
  className?: string;
  labelLeft?: string;
  labelRight?: string;
  t: i18next.TFunction;
}

const LinearColorRangeLegend: React.FC<Props> = (props) => {
  const { t } = props;
  const { stops } = props.legend;
  const firstLabel = t(`legend.label.${stops[0].label}`);
  const lastLabel = t(`legend.label.${_last(stops)?.label}`);
  const colorScale = getColorScaleFromLinearGradientLegend(props.legend);
  const gradientColors = colorScale
    .colors(10, 'hex')
    .map((c) => chroma(c).alpha(1))
    .join(',');
  const gradientDirection = i18next.dir() === 'rtl' ? 'left' : 'right';
  const style = {
    background: `linear-gradient(to ${gradientDirection}, ${gradientColors})`,
    height: props.blockSize,
    width: '100%',
  };

  return (
    <div className={cx(legendWrapperStyle, props.className)}>
      <div className="labels-top">
        {props.labelLeft && <div>{props.labelLeft}</div>}
        {props.labelRight && <div>{props.labelRight}</div>}
      </div>
      <div className="blocks">
        <div key="legend" className="block" style={style} />
      </div>
      <div className="labels-bottom">
        <div>{firstLabel}</div>
        {lastLabel && <div>{lastLabel}</div>}
      </div>
    </div>
  );
};

export default LinearColorRangeLegend;
