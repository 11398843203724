import { css } from 'emotion';
import { bodyStyle, rounded, colors, boxShadow } from '.';

export const cardStyle = css`
  background-color: ${colors.white};
  box-shadow: ${boxShadow.sm};
  border-radius: ${rounded.md};
  border: 1px solid ${colors.gray[300]};
`;

export const linkStyle = css`
  ${bodyStyle};
  text-decoration: none;
  color: ${colors.primary.default};
  &:hover {
    color: ${colors.primary.default};
  }
`;

export const redFilterStyle = css`
  filter: invert(59%) sepia(77%) saturate(4776%) hue-rotate(319deg) brightness(102%) contrast(99%);
`;

export const transparentButton = css`
  &[ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
  }

  cursor: pointer;
  background: none;
  box-shadow: none;
  height: auto;
  border: 0;
  line-height: normal;
  padding: 0;
  &:hover {
    ${colors.primary.default};
  }
  &:focus {
    outline: none;
  }
`;
