import i18n from 'i18n';
import { select, put } from 'redux-saga/effects';
import { getCurrentLanguageLocale } from 'selectors/languageSelectors';
import LanguageAction, { ChangeLanguageAction } from '../actions/language/LanguageAction';

export function* changeLanguageSaga(action: ChangeLanguageAction) {
  try {
    const locale: string = yield select(getCurrentLanguageLocale);
    if (action.payload.locale !== locale) {
      yield put(LanguageAction.languageChanged(action.payload));
      yield i18n.changeLanguage(action.payload.locale);
    }

    // change direction (rtl - ltr) based on the currently set language
    document.body.dir = i18n.dir();
  } catch (error) {
    console.error('Error changing language', error);
  }
}

export default changeLanguageSaga;
