import { select, put } from 'redux-saga/effects';
import ToolbarAction from '../actions/toolbar/ToolbarAction';
import { ToggleCarousel } from '../actions/app/AppAction';
import { getShowCarousel } from '../selectors/appSelectors';
import LayerType from '../models/types/LayerType';

export function* setDefaultToolbarActionOnCarouselShow(action: ToggleCarousel) {
  const showCarousel = yield select(getShowCarousel);
  if (!showCarousel) {
    yield put(ToolbarAction.clearSelectedToolbarLayerTypeAction());
  } else {
    yield put(ToolbarAction.setSelectedToolbarLayerTypeAction(LayerType.data));
  }
}

export default setDefaultToolbarActionOnCarouselShow;
