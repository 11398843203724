import { createSelector } from 'reselect';
import { RootState } from '../reducers/rootReducer';

export const getScenariosState = (state: RootState) => state.scenarios;
export const getScenarios = createSelector(getScenariosState, (state) => state.scenarios);
export const getDefaultScenarioId = createSelector(getScenariosState, (state) => state.defaultScenario);
export const getCurrentScenarioId = createSelector(getScenariosState, (state) => state.currentScenarioId);
export const getCurrentScenario = createSelector(getScenariosState, (state) => {
  const { currentScenarioId, scenarios } = state;
  const scenario = scenarios.find((s) => s.id === currentScenarioId);
  return scenario;
});
export const getAvailableScenarios = createSelector(getScenariosState, (state) =>
  state.scenarios.filter((scenario) => scenario.show)
);
export const getScenariosShownAtLeastOnce = createSelector(getScenariosState, (state) => state.shownAtLeastOnce);
export const getStormScenarioIsActive = createSelector(getScenariosState, (state) => state.currentScenarioId === 3);
