import jmespath from 'jmespath';

type JMESPath = string;

export const mapValues = (data: object, mapping: JMESPath): any | null => {
  const values = jmespath.search(data, mapping);
  return values;
};

export const mapChartValues = (data: object, mappingX: JMESPath, mappingY: JMESPath) => {
  const xValues: any[][] = jmespath.search(data, mappingX);
  const yValues: any[][] = jmespath.search(data, mappingY);
  if (!xValues?.length || !yValues?.length) return { x: [], y: [] };
  return { x: xValues, y: yValues };
};
