import { css } from 'emotion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import OnScreenMapControlButton from './OnScreenMapControlButton';
import CompassButton from './CompassButton';
// FIX this import shouldn't be here
import SearchContainer from '../../containers/SearchContainer';

export interface OnScreenMapControlsProps {
  threeD: boolean;
  bearing?: number;
  onZoom: (value: number) => void;
  onResetBearing: () => void;
  on3DToggle: () => void;
}

const controls = css`
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  width: auto;
  position: absolute;
  z-index: 2;
  margin: 2rem 1rem;
  align-items: flex-end;
  .zoom {
    display: flex;
    flex-direction: column;
    align-items: center;
    > button {
      padding: 1rem;
      &:first-of-type {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: -1px;
      }
      &:last-of-type {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: 0;
      }
    }
  }
  button {
    border: none;
    &.compass {
      width: 4rem;
      height: 4rem;
      margin-right: -7px;
      padding: 1rem 0.5rem;
      border-radius: 100%;
    }
  }
`;

const OnScreenMapControls: React.FC<OnScreenMapControlsProps> = ({
  onZoom,
  onResetBearing,
  on3DToggle,
  threeD,
  bearing,
}) => {
  const { t } = useTranslation();
  return (
    <div className={controls}>
      <SearchContainer />
      <div className="zoom">
        <OnScreenMapControlButton
          icon={<SVG src="icons/plus.svg" />}
          title={t('toolbars.controls.zoom.in.alt')}
          onClick={() => onZoom(1)}
          className="zoom-in"
        />
        <OnScreenMapControlButton
          icon={<SVG src="icons/min.svg" />}
          title={t('toolbars.controls.zoom.out.alt')}
          onClick={() => onZoom(-1)}
          className="zoom-out"
        />
      </div>
      <CompassButton
        icon={<SVG src="icons/pointer.svg" />}
        title={t('toolbars.controls.tilt.alt')}
        onClick={onResetBearing}
        className="compass"
        bearing={bearing || 0}
      />
      <OnScreenMapControlButton
        icon={threeD ? <SVG src="icons/2d.svg" /> : <SVG src="icons/3d.svg" />}
        title={t('toolbars.controls.3D.alt')}
        onClick={on3DToggle}
        className="2d-3d"
      />
    </div>
  );
};

export default OnScreenMapControls;
