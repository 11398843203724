export function getTideFactorForTimeStep(timeStep: number): number {
  return TIDE_FACTOR_BY_TIME_STEP[timeStep] || 0;
}

export const TIDE_FACTOR_BY_TIME_STEP: { [key: number]: number } = {
  0: 0,
  1: 1 / 12,
  2: 2 / 12,
  3: 3 / 12,
  4: 4 / 12,
  5: 5 / 12,
  6: 6 / 12,
  7: 7 / 12,
  8: 8 / 12,
  9: 9 / 12,
  10: 10 / 12,
  11: 11 / 12,
  12: 1,
  13: 11 / 12,
  14: 10 / 12,
  15: 9 / 12,
  16: 8 / 12,
  17: 7 / 12,
  18: 6 / 12,
  19: 5 / 12,
  20: 4 / 12,
  21: 3 / 12,
  22: 2 / 12,
  23: 1 / 12,
  // time step index 0 and 24 are both used sometimes
  24: 0,
};
