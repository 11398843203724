import React from 'react';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { useDispatch, useSelector } from 'react-redux';
import AppAction from '../../actions/app/AppAction';
import { getLODMode } from '../../selectors/appSelectors';
import LODMode from '../../models/types/LODMode';

const MapControlsContainer: React.FC = () => {
  const dispatch = useDispatch();

  const lodMode = useSelector(getLODMode);

  const setLODMode = (e: RadioChangeEvent) => dispatch(AppAction.setLODMode(e.target.value));

  return (
    <Radio.Group onChange={setLODMode} defaultValue={lodMode} buttonStyle="solid">
      <Radio.Button value={LODMode.AUTOMATIC}>{LODMode.AUTOMATIC}</Radio.Button>
      <Radio.Button value={LODMode.LOD0}>{LODMode.LOD0}</Radio.Button>
      <Radio.Button value={LODMode.LOD1}>{LODMode.LOD1}</Radio.Button>
      <Radio.Button value={LODMode.LOD2}>{LODMode.LOD2}</Radio.Button>
      <Radio.Button value={LODMode.MAPBOX}>{LODMode.MAPBOX}</Radio.Button>
      <Radio.Button value={LODMode.HARDWARE_OPTIMIZED}>{LODMode.HARDWARE_OPTIMIZED}</Radio.Button>
    </Radio.Group>
  );
};

export default MapControlsContainer;
