import ActiveLayerButton from 'components/button/ActiveLayerButton';
import React, { useState, useEffect } from 'react';

export interface ActiveDomainTabLayer {
  id: number;
  name: string;
  icon: string;
  legend: JSX.Element;
  loading?: boolean;
}

export const ActiveDomainTabContent: React.FC<{
  layers?: ActiveDomainTabLayer[];
  selectedIndex?: number;
  interactive?: boolean; // interactivity of the layers
  onClick?: (id: number) => void;
}> = (props) => {
  const [selectedLayerId, setSelectedLayerIndex] = useState<number | undefined>(props.selectedIndex);
  const [currentLegend, setCurrentLegend] = useState<JSX.Element | undefined>(undefined);

  useEffect(() => {
    setSelectedLayerIndex(props.selectedIndex);
    setCurrentLegend(props.layers?.find((layer) => layer.id === props.selectedIndex)?.legend);
    return () => setSelectedLayerIndex(undefined);
  }, [props, props.layers, props.selectedIndex]);

  return (
    <>
      <div className="active-layers">
        {props.layers?.map((layer) => (
          <ActiveLayerButton
            key={layer.id}
            icon={layer.icon}
            text={layer.name}
            loading={layer.loading}
            active={layer.id === selectedLayerId}
            onClick={() => {
              if (props.interactive !== false) {
                setSelectedLayerIndex(layer.id);
                props.onClick && props.onClick(layer.id);
                setCurrentLegend(layer.legend);
              }
            }}
          />
        ))}
      </div>
      {currentLegend && currentLegend}
    </>
  );
};

export default ActiveDomainTabContent;
