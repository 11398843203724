import { Action } from 'redux';

enum Tooltip {
  TOGGLE = 'tooltip/TOGGLE',
  SHOW = 'tooltip/SHOW',
  HIDE = 'tooltip/HIDE',
}

export interface ToggleTooltipAction {
  type: typeof Tooltip.TOGGLE;
  payload: {
    title?: string;
    location: {
      x: number;
      y: number;
    };
    properties: any;
  };
}

export interface EnableTooltipAction {
  type: typeof Tooltip.SHOW;
  payload: {
    title?: string;
    location: {
      x: number;
      y: number;
    };
    properties: any;
  };
}
export interface DisableTooltipAction {
  type: typeof Tooltip.HIDE;
}

export type TooltipActionType = ToggleTooltipAction | EnableTooltipAction | DisableTooltipAction;

export class TooltipAction implements Action<Tooltip> {
  type: Tooltip;

  static Types = Tooltip;

  constructor(type: Tooltip) {
    this.type = type;
  }

  static toggleTooltipAction = (
    location: {
      x: number;
      y: number;
    },
    properties?: { [key: string]: any },
    title?: string
  ): ToggleTooltipAction => ({
    type: Tooltip.TOGGLE,
    payload: { title, location, properties },
  });

  static showTooltipAction = (
    location: {
      x: number;
      y: number;
    },
    properties?: { [key: string]: any },
    title?: string
  ): EnableTooltipAction => ({
    type: Tooltip.SHOW,
    payload: { title, location, properties },
  });

  static hideTooltipAction = () => ({
    type: Tooltip.HIDE,
  });
}

export default TooltipAction;
