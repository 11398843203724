import DockAction, { DockActionType } from '../actions/dock/DockAction';
import LayersUIAction, { LayersUIActionType } from '../actions/layers/LayersUIAction';
import { DockLayer } from '../models/interfaces/DockLayer';

export interface DockState {
  readonly layers: Map<number, DockLayer>;
  readonly sortedLayers: number[];
}

const initialState: DockState = {
  layers: new Map<number, DockLayer>(),
  sortedLayers: [],
};

const reducer = (state: DockState = initialState, action: LayersUIActionType | DockActionType): DockState => {
  // Ensure immutability of the initialState by making new items for the changes
  switch (action.type) {
    case DockAction.Types.TOGGLE_MAP_VISUALIZATION: {
      const layers = new Map(state.layers);
      const currentLayer = layers.get(action.payload.id);
      if (!currentLayer?.visualizations.find((wrapper) => wrapper.visualization.id === action.payload.visualizationId))
        return state; // exit early
      if (!currentLayer?.visualizations) return state; // exit early
      const newLayer = { ...currentLayer };
      const viz = new Set(currentLayer.activeMapVisualizations);
      if (!viz.has(action.payload.visualizationId)) {
        viz.add(action.payload.visualizationId);
      } else {
        viz.delete(action.payload.visualizationId);
      }
      newLayer.activeMapVisualizations = viz;

      // replace layer object with new layer object
      layers.set(action.payload.id, newLayer);
      return { ...state, layers };
    }
    case DockAction.Types.REORDER_LAYERS: {
      const { payload } = action;
      if (payload !== state.sortedLayers) {
        return {
          ...state,
          sortedLayers: [...payload],
        };
      }
      return state;
    }
    case DockAction.Types.TOGGLE_LAYER_VISUALIZATION: {
      const updatedLayer = state.layers.get(action.payload);
      if (!updatedLayer) return state;
      const newLayers = new Map(state.layers);
      newLayers.set(action.payload, { ...updatedLayer, active: !updatedLayer.active });
      return {
        ...state,
        layers: newLayers,
      };
    }
    case LayersUIAction.Types.REMOVE_LAYER: {
      if (state.layers.has(action.payload.id)) {
        const newLayers = new Map(state.layers);
        newLayers.delete(action.payload.id);
        const newOrder = state.sortedLayers.filter((id) => id !== action.payload.id);
        return {
          ...state,
          layers: newLayers,
          sortedLayers: newOrder,
        };
      }
      return state;
    }
    case LayersUIAction.Types.ADD_LAYER: {
      if (!state.layers.has(action.payload.id)) {
        const newLayers = new Map(state.layers);
        const newOrder = [...state.sortedLayers, action.payload.id];
        newLayers.set(action.payload.id, {
          active: false,
          loading: false,
          ...action.payload,
          activeMapVisualizations: new Set(),
        });
        return { ...state, layers: newLayers, sortedLayers: newOrder };
      }
      return state;
    }
    default:
      return state;
  }
};

export default reducer;
