import { indexForIntervalInRange } from 'utils/timeUtils';
import { createSelector } from 'reselect';
import { RootState } from '../reducers/rootReducer';

export const getGlobalFiltersState = (state: RootState) => state.globalFilters;
export const getPropertySelection = createSelector(getGlobalFiltersState, (state) => state.propertySelection);
export const getDateRangeFilter = createSelector(getGlobalFiltersState, (state) => state.dateRange);

export const getCurrentTimeInterval = createSelector(getGlobalFiltersState, (state) => state.currentInterval);
export const getCurrentTimeIntervalStart = createSelector(getCurrentTimeInterval, (interval) => interval.start);
export const getCurrentTimeIntervalEnd = createSelector(getCurrentTimeInterval, (interval) => interval.end);

export const getTimeInterval = createSelector(getGlobalFiltersState, (state) => state.timeInterval);
export const getSelectedProperties = createSelector(getGlobalFiltersState, (state) => state.propertySelection);

export const getCurrentTimeIntervalAsIndex = createSelector(
  [getDateRangeFilter, getCurrentTimeInterval, getTimeInterval],
  ({ start: rangeStart }, { start: intervalStart }, timeInterval) =>
    indexForIntervalInRange(rangeStart, intervalStart, timeInterval)
);
