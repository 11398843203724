import React from 'react';
import { css } from 'emotion';
import { cardStyle, styleConfig } from 'styles/demo';
import { activeBotTriggerId, botIsActive } from 'selectors/appSelectors';
import { useDispatch, useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import BotScreenFragment from '../components/bot/BotScreenFragment';
import { BotAction } from '../models/interfaces/bot/BotAction';
import BotStory from '../components/bot/BotStory';
import AppAction from '../actions/app/AppAction';
import { getCurrentScenarioId } from '../selectors/scenariosSelectors';

const { colors, margin } = styleConfig;

const widgetHeight = `20rem`;

const widgetContainer = css`
  ${cardStyle}
  position: absolute;
  bottom: calc(${margin[8]} + ${margin[8]} + ${margin[4]});
  left: 0;
  width: 238px;
  margin: ${margin[2]};

  .content {
    color: ${colors.white};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${margin[2]};
    height: ${widgetHeight};
  }
  .content * {
    overflow-y: auto;
  }

  @media (max-height: calc(${widgetHeight} + 10rem)) {
    display: none;
  }
`;

// depending on the trigger id, the appropriate screen fragment is shown
const getActiveFragment = (id: number) => {
  switch (id) {
    case 1:
      return <BotStory key="story 0" story={0} />;
    case 2:
      return <BotStory key="story 2" story={2} />;
    case 3:
      return <BotStory key="story 3" story={3} />;
    case 6:
      return <BotStory key="story 6" story={1} />;
    case 5: {
      const actions: BotAction[] = [{ label: 'Continue', triggerId: 1 }, { label: 'Stop mayor' }];
      return (
        <div className={widgetContainer}>
          <div className="content">
            <BotScreenFragment text="The Quick Brown Fox jumps over the lazy dog." actions={actions} />
          </div>
        </div>
      );
    }
    default:
      throw new Error(`Unknown bot trigger id ${id}`);
  }
};

const botStyle = css`
  ${cardStyle}
  position: absolute;
  top: calc(${styleConfig.margin[16]} + ${styleConfig.margin[16]} + ${styleConfig.margin[6]});
  left: calc(4 * ${styleConfig.margin[2]});
  margin: ${styleConfig.margin[2]};

  transform: scale(2.5);
  border-radius: 50%;
  border: none;
  padding: 4px;

  &:hover {
    cursor: pointer;
  }

  &:not(.active) {
    color: ${colors.black};
    background-color: ${colors.gray[300]};
  }

  &.active path {
    fill: white !important;
  }
`;

const BotButton: React.FC = () => {
  const dispatch = useDispatch();
  const activeBot = useSelector(botIsActive);
  const currentScenarioId = useSelector(getCurrentScenarioId);
  const onBotClicked = () => {
    switch (currentScenarioId) {
      case 2:
      case 3:
        dispatch(AppAction.toggleBot(currentScenarioId));
        break;
      default:
        dispatch(AppAction.toggleBot(1));
    }
  };
  return (
    <div className={botStyle + (activeBot ? ' active' : '')} onClick={onBotClicked} role="button" tabIndex={0}>
      <SVG src="icons/bot.svg" />
    </div>
  );
};

const BotWidgetContainer: React.FC = () => {
  const activeTriggerId = useSelector(activeBotTriggerId);

  return (
    <>
      {activeTriggerId ? getActiveFragment(activeTriggerId) : null}
      <BotButton />
    </>
  );
};

export default BotWidgetContainer;
