import LayersAction, { LayersActionType } from '../actions/layers/LayersAction';
import CarouselSearchAction, { CarouselSearchActionType } from '../actions/search/CarouselSearchAction';
import { CarouselLayer } from '../models/interfaces/CarouselLayer';

export interface LayerState {
  readonly layers: {
    readonly build: CarouselLayer[];
    readonly indexes: CarouselLayer[];
    readonly data: CarouselLayer[];
  };
  readonly loading: boolean;
  readonly searchLoading: boolean;
}

const initialState: LayerState = {
  layers: {
    build: [],
    indexes: [],
    data: [],
  },
  loading: false,
  searchLoading: false,
};

const reducer = (state = initialState, action: LayersActionType | CarouselSearchActionType): LayerState => {
  switch (action.type) {
    case LayersAction.Types.GET:
      return {
        ...state,
        loading: true,
      };
    case LayersAction.Types.GET_COMPLETED: {
      const layers = action.payload.layers.map(
        (layer): CarouselLayer => {
          return {
            ...layer,
            layerType: action.payload.layerType,
          };
        }
      );

      return {
        ...state,
        layers: { ...state.layers, [action.payload.layerType]: layers },
        loading: false,
      };
    }
    case LayersAction.Types.GET_FAILED:
      return {
        ...state,
        loading: false,
      };
    case CarouselSearchAction.Types.STARTED:
      return { ...state, searchLoading: true };
    case CarouselSearchAction.Types.COMPLETED: {
      const layers = action.payload.layers.map(
        (layer): CarouselLayer => {
          return {
            ...layer,
            layerType: action.payload.layerType,
          };
        }
      );

      return {
        ...state,
        layers: { ...state.layers, [action.payload.layerType]: layers },
        searchLoading: false,
      };
    }
    case CarouselSearchAction.Types.FAILED:
      return { ...state, searchLoading: false };
    default:
      return state;
  }
};

export default reducer;
