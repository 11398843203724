import React from 'react';
import { Button } from 'antd';

interface Props {
  handleCameraScript: () => void;
}

const ExampleCameraScriptButton: React.FC<Props> = (props) => {
  return (
    <Button type="primary" size="small" onClick={props.handleCameraScript}>
      Play example
    </Button>
  );
};

export default ExampleCameraScriptButton;
