import React from 'react';
import { useSelector } from 'react-redux';
import VisualizationTooltip from '../components/tooltip/VisualizationTooltip';
import { getTooltip } from '../selectors/tooltipSelectors';

const TooltipContainer: React.FC = () => {
  const tooltip = useSelector(getTooltip);

  const renderTooltipContent = () => {
    const { properties } = tooltip;
    return (
      <>{properties ? Object.keys(properties).map((key) => <p key={key}>{`${key}: ${properties[key]}`}</p>) : null}</>
    );
  };

  if (tooltip.showing && tooltip.location) {
    return (
      <VisualizationTooltip
        title={tooltip.title || 'Missing Tooltip Title'}
        content={renderTooltipContent()}
        location={{ x: tooltip.location.x + 5, y: tooltip.location.y - 40 }}
      />
    );
  }
  return null;
};

export default TooltipContainer;
