import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles';
import { css } from 'emotion';
import { cardStyle, styleConfig } from 'styles/demo';
import { useDispatch, useSelector } from 'react-redux';
import { config, animated, useSpring, useChain } from 'react-spring';
import { transparentize } from 'polished';
import useTimeOutEffect from './useTimeOutEffect';
import AppAction from '../../actions/app/AppAction';
import { getScenarios } from '../../selectors/scenariosSelectors';
import { Scenario } from '../../models/interfaces/scenarios/scenario';
import ScenariosAction from '../../actions/scenarios/ScenariosAction';
import { StormGems, ClosedRoadsGems } from '../../config/gems';

const calmBotStyle = css`
  background-color: ${transparentize(0.9, styleConfig.colors.black)};
  border-radius: ${styleConfig.borderRadius.xl};
  box-shadow: ${styleConfig.boxShadow.md};
  position: absolute;
  top: calc(
    ${styleConfig.margin[2]} + ${styleConfig.margin[16]} + ${styleConfig.margin[16]} + ${styleConfig.margin[8]}
  );
  left: calc(${styleConfig.margin[8]} + (4 * ${styleConfig.margin[2]}));
  width: 298px;
  margin: ${styleConfig.margin[2]};

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    padding: ${styleConfig.margin[4]};
  }

  article {
    width: 100%;
    transition: all 0.5s ease-out;
  }

  .content > article:not(:last-of-type) {
    margin-bottom: ${styleConfig.margin[4]};
  }

  article > p {
    color: ${colors.text};
    white-space: pre-line;
    background-color: white;
    border-radius: ${styleConfig.borderRadius.xl};
    box-shadow: ${styleConfig.boxShadow.md};
    padding: ${styleConfig.padding[4]};
    margin: 0;
  }

  .nav > article:last-of-type > p {
    border-radius: 0;
    width: 100%;
    border-top-left-radius: ${styleConfig.borderRadius.xl};
    border-top-right-radius: ${styleConfig.borderRadius.xl};
  }

  .nav > nav {
    border-bottom-left-radius: ${styleConfig.borderRadius.xl};
    border-bottom-right-radius: ${styleConfig.borderRadius.xl};
  }

  nav {
    align-self: flex-end;
    background-color: white;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    overflow: hidden;
    margin: 0;
  }

  button {
    padding: ${styleConfig.padding[3]} ${styleConfig.padding[4]};
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    color: ${colors.primary};
    width: 100%;
    border-bottom: 1px solid ${colors.gray[300]};
  }

  button.primary {
    font-weight: bold;
  }

  button.plain {
    color: ${colors.primary};
  }
`;

const alertingBotStyle = css`
  ${cardStyle}
  position: absolute;
  top: calc(
    ${styleConfig.margin[16]} + ${styleConfig.margin[16]} + ${styleConfig.margin[16]} + ${styleConfig.margin[6]}
  );
  left: calc(11 * ${styleConfig.margin[2]});
  width: 438px;
  min-height: 6rem;
  margin: ${styleConfig.margin[2]};

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    padding: ${styleConfig.margin[4]};
  }

  .content:after {
    content: '';
    position: absolute;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid ${styleConfig.colors.black};
    transform: rotate(-45deg);
    left: -15px;
    top: -10px;
    animation: MoveArrow 1s ease infinite;
  }

  article > p {
    color: ${colors.white};
    white-space: pre-line;
  }

  button.primary {
    padding: ${styleConfig.padding[3]} ${styleConfig.padding[4]};
    border-radius: ${styleConfig.borderRadius.full};
    border: none;
    background-color: transparent;
    transition: all 100ms linear;
    overflow: hidden;
    cursor: pointer;
    outline: none;
    color: ${colors.gray[200]};
    line-height: ${styleConfig.lineHeight.md};
    font-weight: bold;

    background-color: ${colors.gray[300]};
    color: #000;
  }

  button.plain {
    background: transparent;
    border: 0;
    color: white;
    cursor: pointer;
  }

  @keyframes MoveArrow {
    0%,
    100% {
      left: -15px;
      top: -10px;
    }
    50% {
      left: -25px;
      top: -20px;
    }
  }
`;

const navStyle = css`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: ${styleConfig.margin[2]};
`;

interface Chapter {
  id: string;
  next?: string;
  buttons?: string[];
  timer?: boolean;
  inline?: boolean;
  noEscape?: boolean;
  markers?: string;
  alertingLayers?: number[];
}

interface Story {
  style: string;
  chapters: Chapter[];
}

const stories: Story[] = [
  {
    style: alertingBotStyle,
    chapters: [
      {
        id: 'bot.story.welcomeStart',
        buttons: ['bot.story.welcomeStart.learnMore'],
        next: 'bot.story.learnMore.1',
        noEscape: true,
      },
      {
        id: 'bot.story.learnMore.1',
        buttons: ['bot.story.learnMore.timeForAction'],
        next: 'bot.story.timeForAction',
        noEscape: true,
      },
      {
        id: 'bot.story.timeForAction',
        buttons: ['bot.story.timeForAction.toTheCity'],
        noEscape: true,
      },
    ],
  },
  {
    style: calmBotStyle,
    chapters: [
      {
        id: 'bot.story.welcomeStartScenario.WhichScenario',
        buttons: ['scenarios.name.roadclosure', 'scenarios.name.heavyrain'],
      },
    ],
  },
  {
    style: calmBotStyle,
    chapters: [
      {
        id: 'bot.story.hi',
        timer: true,
        next: 'bot.story.closedStreet1',
      },
      {
        id: 'bot.story.closedStreet1',
        timer: true,
        next: 'bot.story.closedStreet2',
      },
      {
        id: 'bot.story.closedStreet2',
        timer: true,
        inline: true,
        next: 'bot.story.closedStreet3',
      },
      {
        id: 'bot.story.closedStreet3',
        buttons: ['bot.story.showMe'],
        inline: true,
        next: 'bot.story.closedStreet4',
      },
      {
        id: 'bot.story.closedStreet4',
        timer: true,
        next: 'bot.story.closedStreet5',
        markers: 'closedStreet',
      },
      {
        id: 'bot.story.closedStreet5',
        timer: true,
        inline: true,
        next: 'bot.story.closedStreet6',
      },
      {
        id: 'bot.story.closedStreet6',
        inline: true,
        buttons: ['bot.story.showMeHeavyRain'],
      },
    ],
  },
  {
    style: calmBotStyle,
    chapters: [
      {
        id: 'bot.story.heavyRain1',
        timer: true,
        next: 'bot.story.heavyRain2',
      },
      {
        id: 'bot.story.heavyRain2',
        timer: true,
        next: 'bot.story.heavyRain3',
      },
      {
        id: 'bot.story.heavyRain3',
        inline: true,
        buttons: ['bot.story.showMe'],
        next: 'bot.story.heavyRain4',
      },
      {
        id: 'bot.story.heavyRain4',
        timer: true,
        next: 'bot.story.heavyRain5',
        markers: 'heavyRain',
        alertingLayers: [2],
      },
      {
        id: 'bot.story.heavyRain5',
        inline: true,
        timer: true,
        next: 'bot.story.heavyRain6',
        alertingLayers: [2],
      },
      {
        id: 'bot.story.heavyRain6',
        inline: true,
        buttons: ['bot.story.showMeClosedStreet'],
        alertingLayers: [2],
      },
    ],
  },
];

interface BotStoryProps {
  story: number;
}

const INITIAL_CHAPTER_ID = -1;

const BotStory: React.FC<BotStoryProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const scenarios = useSelector(getScenarios);
  const [chapterId, setChapterId] = useState<number>(INITIAL_CHAPTER_ID);
  const [messages, setMessages] = useState<string[]>([]);
  const [buttonIds, setButtonIds] = useState<string[] | undefined>(undefined);
  const [useTimer, setUseTimer] = useState<boolean>(false);
  const [hideEscape, setHideEscape] = useState<boolean>(false);
  const containerRef = useRef<any>();
  const cancelToken = useTimeOutEffect(timerTick, 6000);
  const story = stories[props.story];

  function timerTick() {
    if (!useTimer) {
      // skip timer tick
      return;
    }
    toNextChapter();
  }

  function loadChapter(id: number) {
    console.log(`Loading chapter ${id}`);
    const chapter = story.chapters[id];
    if (chapter.inline) {
      // add a balloon
      setMessages(messages.concat([chapter.id]));
    } else {
      // clear the screen
      setMessages([chapter.id]);
    }
    // choose how to continue
    setButtonIds(chapter.buttons);
    setUseTimer(!!chapter.timer);
    setChapterId(id);
    setHideEscape(chapter.noEscape === true);
    switch (chapter.markers) {
      case 'closedStreet':
        dispatch(AppAction.setActiveGems(ClosedRoadsGems));
        break;
      case 'heavyRain':
        dispatch(AppAction.setActiveGems(StormGems));
        break;
      default:
        break;
    }
    if (chapter.alertingLayers && chapter.alertingLayers.length) {
      dispatch(AppAction.setBotAlertingLayers(chapter.alertingLayers));
    } else {
      dispatch(AppAction.setBotAlertingLayers([]));
    }
  }

  const stopStory = () => {
    cancelToken();
    dispatch(AppAction.stopBotTrigger());
  };

  function startScenario(scenario: Scenario) {
    // TODO: Invoke the full scenario trigger logic, perhaps it should be in a saga
    dispatch(ScenariosAction.scenarioReset());
    dispatch(ScenariosAction.scenarioTriggered(scenario.id, true));
  }

  function onButtonClick(buttonId: string) {
    switch (buttonId) {
      case 'bot.story.showMeHeavyRain':
      case 'scenarios.name.heavyrain':
        startScenario(scenarios[2]);
        break;
      case 'bot.story.showMeClosedStreet':
      case 'scenarios.name.roadclosure':
        startScenario(scenarios[1]);
        break;
      case 'bot.story.timeForAction.toTheCity':
        dispatch(AppAction.startBotTrigger(6));
        break;
      default:
        toNextChapter();
    }
  }

  function toNextChapter() {
    console.log(`Next chapter for story ${props.story}, current chapter ${chapterId}.`);
    if (chapterId === INITIAL_CHAPTER_ID) {
      loadChapter(0);
    } else {
      const currentChapter = story.chapters[chapterId];
      if (!currentChapter.next) {
        stopStory();
        return;
      }
      const nextChapterId = story.chapters.findIndex((c) => c.id === currentChapter.next);
      if (nextChapterId < 0) {
        throw new Error(`Invalid next chapter ${currentChapter.next} for chapter id ${currentChapter.id}`);
      }
      loadChapter(nextChapterId);
    }
  }

  useEffect(() => {
    if (messages.length === 0) {
      toNextChapter();
    }
  });

  const containerAnimation = useSpring({
    ref: containerRef,
    config: config.stiff,
    from: { opacity: 0, transform: 'scale(0.5)' },
    to: { opacity: 1, transform: 'scale(1)' },
  });

  useChain([containerRef], [0]);

  const hasButtons = buttonIds && buttonIds.length;

  return (
    <animated.div className={story.style} ref={containerRef} style={containerAnimation}>
      <div className={`content ${hasButtons ? 'nav' : ''}`}>
        {messages.map((m) => (
          <article key={m}>
            <p dangerouslySetInnerHTML={{ __html: t(m) }} />
          </article>
        ))}
        {hasButtons ? (
          <nav className={navStyle}>
            {hideEscape ? null : (
              <button className="plain" type="button" onClick={stopStory}>
                {t('bot.exploreAlone')}
              </button>
            )}
            {buttonIds?.map((b) => (
              <button key={b} className="primary" type="button" onClick={() => onButtonClick(b)}>
                {t(b)}
              </button>
            ))}
          </nav>
        ) : null}
      </div>
    </animated.div>
  );
};

export default BotStory;
