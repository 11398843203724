import { Layer as DeckGLLayer } from '@deck.gl/core';
import { PathLayer } from '@deck.gl/layers';
import { scaleLinear } from 'd3-scale';
import * as d3 from 'd3-ease';
import { last as _last, first as _first } from 'lodash';
import { Feature, LineString } from 'geojson';
import { Coordinates } from 'viewport-mercator-project';

import { transitionDurationMs } from 'config/app';
import { DeckGLBaseLayerProperties } from 'models/interfaces/deckgl/DeckGLBaseLayerProperties';
import { DeckGLLayerEventHandler } from 'models/interfaces/deckgl/DeckGLLayerEventHandler';
import { FlowWrapper } from 'models/interfaces/api/FlowWrapper';
import jmespath from 'jmespath';
import { getColorScaleFromSteppedColorRangeLegend } from './legendColorUtils';
import { CachedData } from '../../models/interfaces/api/CachedData';

const defaultMinValue = 0;
const defaultMaxValue = 100;

export interface PathData {
  path: Coordinates[];
  intensity: number;
  segmentId: number;
}

export default function createFlowMap(
  visualizationWrapper: FlowWrapper,
  cachedData: CachedData,
  currentStepIndex: number,
  closedSegments?: number[],
  layerProperties?: DeckGLBaseLayerProperties<DeckGLLayerEventHandler>
): DeckGLLayer {
  const { visualization, legend } = visualizationWrapper;
  const { mapping } = visualization.dataSources[0];
  const { rawData } = cachedData;

  const geometries = jmespath.search(rawData, mapping.geometry) as Feature<LineString>[];
  const segmentIds = (jmespath.search(rawData, mapping.segmentId) as string[]).map((s) => Number(s));
  const intensities = jmespath.search(rawData, mapping.value) as number[][];

  const data = geometries.reduce((acc: PathData[], current: Feature<LineString>, idx: number) => {
    acc.push({
      path: current.geometry.coordinates as Coordinates[],
      intensity: intensities[idx][Math.min(currentStepIndex, intensities[idx].length - 1)],
      segmentId: segmentIds[idx],
    });

    return acc;
  }, []);

  const firstValue = _first(legend.ranges)?.minValue || defaultMinValue;
  const lastValue = _last(legend.ranges)?.maxValue || defaultMaxValue;

  const lineWidthScale = scaleLinear().domain([firstValue, lastValue]).range([2, 6]);

  const getWidth = (d: PathData) => {
    if (closedSegments?.includes(d.segmentId)) return 0;
    return lineWidthScale(d.intensity);
  };

  const colorScale = getColorScaleFromSteppedColorRangeLegend(legend, true);

  const getColor = (d: PathData) => {
    const color = colorScale(d.intensity).rgba();
    // chroma-js returns alpha in 0-1 range, so we need to fix that
    color[3] *= 255;
    if (closedSegments?.includes(d.segmentId)) return [];
    return color;
  };

  return new PathLayer({
    id: `${visualization.title}Layer${visualization.id}`,
    data,
    getColor,
    getWidth,
    getPath: (d: PathData) => d.path,
    transitions: {
      getColor: {
        duration: transitionDurationMs,
        easing: d3.easeCubicInOut,
      },
      getWidth: {
        duration: transitionDurationMs,
        easing: d3.easeCubicInOut,
      },
    },
    parameters: { depthTest: false },
    pickable: layerProperties?.pickable,
    pickingRadius: 30,
    autoHighlight: true,
    tooltipTitle: 'Flow segment',
    onHover: layerProperties?.onHover,
    onClick: layerProperties?.onClick,
  });
}
