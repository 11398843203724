import * as d3 from 'd3-ease';
import CameraScriptAction, { CameraScriptActionType } from '../actions/camera/CameraAction';
import { CameraScript, CameraScriptRaw } from '../models/interfaces/Camera';

export interface CameraState {
  loading: boolean;
  finished: boolean;
  rawScript: CameraScriptRaw;
  script: CameraScript;
}

const initialState: CameraState = {
  loading: false,
  finished: true,
  rawScript: {
    cameraMovements: [],
  },
  script: {
    cameraMovements: [],
  },
};

/**
 * Parses raw camera script into a currect format
 */
export const parseCameraScript = (cameraScript: CameraScriptRaw): CameraScript => {
  const cameraMovements: CameraScript['cameraMovements'] = cameraScript.cameraMovements.map(
    ({ transition, ...rest }) => {
      const defaultEasing = d3.easeCubic;
      const easing = transition.easing ? d3[transition.easing] : defaultEasing;
      return {
        ...rest,
        transition: {
          ...transition,
          easing,
        },
      };
    }
  );
  return { cameraMovements };
};

const reducer = (state = initialState, action: CameraScriptActionType): CameraState => {
  switch (action.type) {
    case CameraScriptAction.Types.GET_SCRIPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case CameraScriptAction.Types.GET_SCRIPT_COMPLETED: {
      return {
        ...state,
        loading: false,
        rawScript: action.payload,
      };
    }
    case CameraScriptAction.Types.GET_SCRIPT_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case CameraScriptAction.Types.START_SCRIPT: {
      const cameraScript = parseCameraScript(state.rawScript);
      return {
        ...state,
        finished: false,
        script: cameraScript,
      };
    }
    case CameraScriptAction.Types.INTERRUPT_SCRIPT: {
      return {
        ...state,
        finished: true,
        script: initialState.script,
      };
    }
    case CameraScriptAction.Types.STOP_SCRIPT: {
      return {
        ...state,
        finished: true,
        script: initialState.script,
      };
    }
    case CameraScriptAction.Types.UPDATE_SCRIPT: {
      return {
        ...state,
        loading: false,
        rawScript: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
