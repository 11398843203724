import React from 'react';
import { css } from 'emotion';

import { styleConfig, cardStyle } from 'styles/demo';
import SVG from 'react-inlinesvg';

const { margin, padding } = styleConfig;

const partnerLogosWrapperStyle = css`
  ${cardStyle}
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: ${margin[8]};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0 ${padding[4]};
  max-height: 4rem;
  .logos {
    display: flex;
    height: 3rem;
    max-height: 4rem;
  }
  .footer {
    height: 1rem;
    line-height: 0rem;
    padding-left: ${padding[4]};
    a {
      color: white;
      text-decoration: underline;
    }
  }
  img,
  button > svg {
    padding: 10px ${padding[4]};
    width: 100px;
    max-height: 70px;
    min-width: 90px;
    &:first-child {
      padding: ${padding[2]};
      padding-left: ${padding[4]};
      width: 50px;
    }
    &:last-child {
      padding: ${padding[2]};
      padding-right: ${padding[4]};
    }
  }
  button {
    background-color: transparent;
    border: none;
    text-decoration: none;
    transition: all 100ms linear;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

interface Props {
  logos: string[];
  button: string;
  onClick: () => void;
  footer?: JSX.Element;
}

const PartnerLogos: React.FC<Props> = (props) => {
  if (!props.logos.length) {
    console.warn('PartnerLogos: No logos provided');
    return null;
  }
  return (
    <div className={partnerLogosWrapperStyle} dir="ltr">
      <div className="logos">
        <button type="button" onClick={props.onClick}>
          <SVG src={props.button} />
        </button>
        {props.logos.map((logo) => (
          <img key={logo} src={logo} alt={logo} />
        ))}
      </div>
      {props.footer && <div className="footer">{props.footer}</div>}
    </div>
  );
};

export default PartnerLogos;
