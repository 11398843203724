import { select, put, call } from 'redux-saga/effects';
import moment from 'moment';
import { cachedDataRetention } from 'config/cache';
import parseRawCFdata from 'utils/parseRawCFdata';
import { createTimeInterval } from 'config/presetTimeIntervals';
import { customCFVisualization } from 'config/debug';
import DockAction from '../actions/dock/DockAction';
import LayersAction, { GetLayerDataAction, GetCustomLayerDataAction } from '../actions/layers/LayersAction';
import GlobalFilterAction from '../actions/global-filters/GlobalFilterAction';
import { composeRequestURL } from '../api/common';
import { getLayerData } from '../api/layers';
import { getCurrentScenarioId } from '../selectors/scenariosSelectors';
import { getBoundingBox } from '../selectors/mapSelectors';
import { getDockLayerById } from '../selectors/dockSelectors';
import { getCurrentLayerCachedData } from '../selectors/cachedDataSelectors';
import { DockLayer } from '../models/interfaces/DockLayer';
import { CachedData } from '../models/interfaces/api/CachedData';

export function* getLayerDataSaga(action: GetLayerDataAction) {
  const { visualizationId, layerId } = action.payload;
  // Select all the relevant data from the store
  const dockLayer: DockLayer = yield select(getDockLayerById(layerId));
  const currentScenarioId = yield select(getCurrentScenarioId);
  const cachedDataMap = yield select(getCurrentLayerCachedData);
  if (!dockLayer) {
    yield put(
      LayersAction.getLayerDataFailed(
        layerId,
        visualizationId,
        new Error(`Layer with id: ${layerId} does not exist in dock`)
      )
    );
    return;
  }
  const visWrapper = dockLayer.visualizations.find((item) => item.visualization.id === visualizationId);
  if (!visWrapper) {
    yield put(
      LayersAction.getLayerDataFailed(
        layerId,
        visualizationId,
        new Error(`Visualization with id ${visualizationId} does not exist in layer: ${layerId}`)
      )
    );
    return;
  }

  if (!action.payload.force) {
    // check if the cache has expired, if so, fetch again
    const cacheExpirationDate = cachedDataMap.get(visualizationId)?.cacheExpirationDate;
    const cacheIsExpired = cacheExpirationDate && moment().isAfter(cacheExpirationDate);
    if (cacheExpirationDate !== undefined && !cacheIsExpired) {
      return yield put(LayersAction.getLayerDataAlreadyCached(layerId, visualizationId));
    }
  } else {
    console.info('forced to get data');
  }

  // API Call
  try {
    const { dataSources } = visWrapper.visualization;
    const url = composeRequestURL(`${dataSources[0].url}/${currentScenarioId}.json`, {});
    const rawData = yield call(getLayerData, url);
    const resultingData: CachedData = {
      visualizationId,
      loading: false,
      valid: true,
      rawData,
    };
    const { length, timeUnit } = cachedDataRetention;
    const expirationDate = moment().add(length, timeUnit).toDate();
    yield put(LayersAction.getLayerDataCompleted(layerId, visualizationId, resultingData, expirationDate));
  } catch (error) {
    console.error(error);
    yield put(LayersAction.getLayerDataFailed(layerId, visualizationId, error));
  }
}

export function* getCustomLayerDataSaga(action: GetCustomLayerDataAction) {
  const { visualizationId, layerId, data } = action.payload;
  // Select all the relevant data from the store
  const dockLayer: DockLayer = yield select(getDockLayerById(layerId));
  const cachedDataMap = yield select(getCurrentLayerCachedData);
  const bbox = yield select(getBoundingBox);
  if (!dockLayer) {
    console.error(`Layer with id: ${layerId} does not exist in dock`);
    yield put(
      LayersAction.getLayerDataFailed(
        layerId,
        visualizationId,
        new Error(`Layer with id: ${layerId} does not exist in dock`)
      )
    );
    return;
  }
  const visWrapper = dockLayer.visualizations.find((item) => item.visualization.id === visualizationId);
  if (!visWrapper) {
    yield put(
      LayersAction.getLayerDataFailed(
        layerId,
        visualizationId,
        new Error(`zation with id ${visualizationId} does not exist in layer: ${layerId}`)
      )
    );
    return;
  }

  // check if the cache has expired, if so, fetch again
  const cacheExpirationDate = cachedDataMap.get(visualizationId)?.cacheExpirationDate;
  const cacheIsExpired = cacheExpirationDate && moment().isAfter(cacheExpirationDate);
  if (cacheExpirationDate !== undefined && !cacheIsExpired) {
    return yield put(LayersAction.getLayerDataAlreadyCached(layerId, visualizationId));
  }

  try {
    const parsedData = parseRawCFdata(data, bbox);
    const rawData = parsedData as CachedData['rawData'];
    const resultingData: CachedData = {
      visualizationId,
      loading: false,
      valid: true,
      rawData,
    };
    const { length, timeUnit } = cachedDataRetention;
    const expirationDate = moment().add(length, timeUnit).toDate();
    const timeInterval = createTimeInterval('hour', 1);
    const from = new Date(rawData.from as Date);
    const to = new Date(rawData.to as Date);
    yield put(LayersAction.getLayerDataCompleted(layerId, visualizationId, resultingData, expirationDate));
    yield put(GlobalFilterAction.setDateRangeAction(from, to));
    yield put(GlobalFilterAction.setTimeIntervalAction(from, to, timeInterval));

    // if the layer is already active, don't disable it back
    if (dockLayer.active) return;
    yield put(DockAction.toggleLayerVisualization(customCFVisualization.layerId));
    yield put(
      DockAction.toggleLayerMapVisualization(customCFVisualization.layerId, customCFVisualization.visualizationId)
    );
  } catch (error) {
    console.error(error);
    yield put(LayersAction.getLayerDataFailed(layerId, visualizationId, error));
  }
}

export default getLayerDataSaga;
