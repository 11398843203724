import { call, put } from 'redux-saga/effects';
import { CameraScriptAction } from '../actions/camera/CameraAction';
import { getCameraScript } from '../api/camera';
import { CameraScriptRaw } from '../models/interfaces/Camera';

export function* getCameraScriptSaga() {
  try {
    const response: CameraScriptRaw = yield call(getCameraScript);

    yield put(CameraScriptAction.getCameraScriptCompleted(response));
  } catch (error) {
    yield put(CameraScriptAction.getCameraScriptFailed(error));
  }
}

export function* startCameraScriptSaga() {
  yield put(CameraScriptAction.startCameraScript());
}

export default getCameraScriptSaga;
