import { Layer as DeckGLLayer } from '@deck.gl/core';

import { MapVisualizationWrapper } from 'models/interfaces/api/VisualizationWrapper';
import { DeckGLBaseLayerProperties } from 'models/interfaces/deckgl/DeckGLBaseLayerProperties';
import {
  DeckGLLayerEventHandler,
  DeckGLLayerEnrichedEventHandler,
} from 'models/interfaces/deckgl/DeckGLLayerEventHandler';
import { CachedData } from '../../models/interfaces/api/CachedData';
import { ScatterplotWrapper } from '../../models/interfaces/api/ScatterplotWrapper';
import { ImageHeatmapWrapper } from '../../models/interfaces/api/ImageHeatmapWrapper';
import { FlowWrapper } from '../../models/interfaces/api/FlowWrapper';
import { GridWrapper } from '../../models/interfaces/api/GridWrapper';
import { DeckGLPickedInfo } from '../../models/interfaces/deckgl/DeckGLPickedInfo';
import { LayerMapVisualizationType } from '../../models/types/LayerMapVisualizationType';
import createFlowMap from './createFlowMap';
import createBitmapLayer from './createBitmapLayer';
import createScatterplot from './createScatterplot';
import createGridLayer from './createGridLayer';

export const deckGLMapLayerFactory: DeckGLLayer | undefined = (
  mapVisualizationWrapper: MapVisualizationWrapper,
  cachedData: CachedData,
  currentTimeIntervalIndex: number,
  closedRoads?: number[],
  layerProperties?: DeckGLBaseLayerProperties<DeckGLLayerEnrichedEventHandler>
) => {
  if (!cachedData?.valid) return undefined;
  switch (mapVisualizationWrapper.type) {
    case LayerMapVisualizationType.FLOW:
      return createFlowMap(mapVisualizationWrapper as FlowWrapper, cachedData, currentTimeIntervalIndex, closedRoads, {
        ...layerProperties,
        onHover: enrichEvent(layerProperties?.onHover, mapVisualizationWrapper),
        onClick: enrichEvent(layerProperties?.onClick, mapVisualizationWrapper),
      });
    case LayerMapVisualizationType.IMAGE_HEATMAP:
      return createBitmapLayer(mapVisualizationWrapper as ImageHeatmapWrapper, cachedData, currentTimeIntervalIndex);
    case LayerMapVisualizationType.SCATTERPLOT: {
      return createScatterplot(mapVisualizationWrapper as ScatterplotWrapper, cachedData, currentTimeIntervalIndex, {
        ...layerProperties,
        onHover: enrichEvent(layerProperties?.onHover, mapVisualizationWrapper),
        onClick: enrichEvent(layerProperties?.onClick, mapVisualizationWrapper),
      });
    }
    case LayerMapVisualizationType.GRID: {
      return createGridLayer(mapVisualizationWrapper as GridWrapper, cachedData, currentTimeIntervalIndex);
    }
    default:
      console.error(`ERROR: VisualizationType: ${mapVisualizationWrapper.type} is not currently supported`);
  }
};

export default deckGLMapLayerFactory;

const enrichEvent = (
  onEvent: DeckGLLayerEnrichedEventHandler | undefined,
  mapVisualizationWrapper: MapVisualizationWrapper
): DeckGLLayerEventHandler => {
  return (object: DeckGLPickedInfo<object>, event: object) => {
    if (onEvent) {
      return onEvent(object, event, mapVisualizationWrapper);
    }
    return false;
  };
};
