import { call, put } from 'redux-saga/effects';
import { FeatureCollection } from 'geojson';
import SearchAction, { SearchStartAction } from '../../actions/search/SearchAction';
import { search } from '../../api/search';

export function* searchSaga(action: SearchStartAction) {
  try {
    const response: FeatureCollection[] = yield call(search, action.payload);
    yield put(SearchAction.searchCompleted(response));
  } catch (error) {
    yield put(SearchAction.searchFailed(error));
  }
}

export default searchSaga;
