import { RgbColor } from 'polished/lib/types/color';
import { css } from 'emotion';
import { darken, lighten, rgbToColorString } from 'polished';

const primary = '#4A90E2';
const secondary = '#52BDC2'; // imec secondary color

export const colors = {
  primary: {
    lighter: lighten(0.3, primary),
    light: lighten(0.1, primary),
    default: primary,
    dark: darken(0.1, primary),
    darker: darken(0.3, primary),
  },
  secondary: {
    default: secondary,
  },
  gray: {
    200: '#EDF2F7',
    300: '#CFD8DE',
    400: '#A7B1BD',
    600: '#818995',
    700: '#626A76',
    800: '#424851',
    900: '#31353C',
  },
  gradient: `linear-gradient(45deg, ${primary} 0%, ${secondary} 100%)`,
  text: '#3c3c3b', // imec dark gray color
  white: '#ffffff',
};

export const tintStyle = (color: RgbColor) => css`
  background-color: ${rgbToColorString(color)};
`;
