import { Action } from 'redux';

enum Debug {
  TOGGLE_CONTROL_PANEL = 'debug/TOGGLE_CONTROL_PANEL',
}

export interface ToggleControlPanelAction {
  type: typeof Debug.TOGGLE_CONTROL_PANEL;
}

export type DebugActionType = ToggleControlPanelAction;

export class DebugAction implements Action<Debug> {
  type: Debug;

  static Types = Debug;

  constructor(type: Debug) {
    this.type = type;
  }

  static toggleControlPanel = (): ToggleControlPanelAction => ({
    type: Debug.TOGGLE_CONTROL_PANEL,
  });
}

export default DebugAction;
