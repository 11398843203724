import ToolbarAction, { ToolbarActionType } from '../actions/toolbar/ToolbarAction';
import LayerType from '../models/types/LayerType';

export interface ToolbarState {
  toolbar: {
    primary: {
      selected?: LayerType;
    };
  };
}

const initialState: ToolbarState = {
  toolbar: {
    primary: {},
  },
};

const reducer = (state = initialState, action: ToolbarActionType): ToolbarState => {
  switch (action.type) {
    case ToolbarAction.Types.SET_SELECTED_LAYER_TYPE:
      return {
        ...state,
        toolbar: {
          primary: {
            selected: action.payload,
          },
        },
      };
    case ToolbarAction.Types.CLEAR_SELECTED_LAYER_TYPE:
      return {
        ...state,
        toolbar: {
          primary: {},
        },
      };
    default:
      return state;
  }
};

export default reducer;
