import React, { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { css } from 'emotion';

import DemoTabs, { TabOption } from 'components/tabs/DemoTabs';
import styleConfig from 'styles/demo/config';
import ActiveDomainTabContent, { ActiveDomainTabLayer } from '../components/active-domain/ActiveDomainTabContent';
import LegendComponentFactory from '../components/legend/LegendComponentFactory';
import LayersAction from '../actions/layers/LayersAction';
import DockAction from '../actions/dock/DockAction';
import { getActiveDockLayers, getDockedLayers } from '../selectors/dockSelectors';
import { getCurrentLayerCachedData } from '../selectors/cachedDataSelectors';
import { getBotAlertingLayers } from '../selectors/appSelectors';

const { margin } = styleConfig;

const legendStyle = css`
  margin-top: auto;
  margin-bottom: -${margin[1]};
`;

const tabStyle = css`
  > div:nth-of-type(2) {
    .content > .active-layers > button {
      width: 100%;
      margin: ${margin[2]} 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const ActiveDomainContainer: React.FC = () => {
  const dispatch = useDispatch();
  const currentLayer = useSelector(getActiveDockLayers)[0];
  const cachedDataMap = useSelector(getCurrentLayerCachedData);
  const allDockedLayers = useSelector(getDockedLayers);
  const alertingLayers = useSelector(getBotAlertingLayers);
  const { t } = useTranslation();

  /** Gets the active domain (layer) e.g. CF or AQ */
  const currentLayerId = currentLayer?.id || -1;

  const activeMapVisualizationId = currentLayer && Array.from(currentLayer.activeMapVisualizations)[0];
  /** Mapping of map visualizations */
  const handleChangeVisualization = useCallback(
    (visualizationId: number) => {
      if (!currentLayer) return;
      if (!currentLayer.activeMapVisualizations.has(visualizationId)) {
        dispatch(LayersAction.getLayerData(currentLayerId, visualizationId));
      }
      // toggle OFF: all other map visualizations
      currentLayer.activeMapVisualizations.forEach((mapVisualizationId) => {
        dispatch(DockAction.toggleLayerMapVisualization(currentLayerId, mapVisualizationId));
      });
      // toggle ON: the clicked map visualizations
      dispatch(DockAction.toggleLayerMapVisualization(currentLayerId, visualizationId));
    },
    [currentLayer, currentLayerId, dispatch]
  );

  const handleChangeDomain = useCallback(
    (layerId: number) => {
      // toggle OFF: the previous domain
      dispatch(DockAction.toggleLayerVisualization(currentLayerId));
      // toggle ON: the clicked domain
      dispatch(DockAction.toggleLayerVisualization(layerId));
    },
    [currentLayerId, dispatch]
  );

  const getLayerIcon = (layerId: number): string => {
    switch (layerId) {
      case 2:
        return 'icons/cf-icon.svg';
      case 3:
        return 'icons/aq-icon.svg';
      case 9:
        return 'icons/water-icon.svg';
      default:
        return 'icons/placeholder.svg';
    }
  };

  // FIXME: remove this when translated labels will be provided by the backend
  const getLayerTitle = useCallback(
    (layerId: number): string => {
      if (layerId === 2) {
        return t('legend.title.city_flows');
      }
      if (layerId === 3) {
        return t('legend.title.air_quality');
      }
      if (layerId === 9) {
        return t('legend.title.water');
      }
      return t('general.unknown');
    },
    [t]
  );

  // FIXME: remove this when translated labels will be provided by the backend
  const getVisualizationTitle = useCallback(
    (layerId: number, visualizationId: number): string => {
      if (layerId === 2) {
        if (visualizationId === 5) {
          return t('active_domain.layer.motorized');
        }
        if (visualizationId === 6) {
          return t('active_domain.layer.non-motorized');
        }
      }
      if (layerId === 3) {
        if (visualizationId === 7) return t('active_domain.layer.no2');
        if (visualizationId === 8) return t('active_domain.layer.pm10');
        if (visualizationId === 9) return t('active_domain.layer.pm25');
      }
      if (layerId === 9 && visualizationId === 11) {
        return t('active_domain.layer.flooding-risk');
      }
      return t('general.unknown');
    },
    [t]
  );

  const tabOptions: TabOption[] = useMemo(() => {
    // *** Create TabOption for all layers ***
    const result = allDockedLayers.map((layer) => {
      // ** Create a tablayer for every visualization in the layer **
      const tabLayers: ActiveDomainTabLayer[] = layer.visualizations.map((vis) => {
        const additionalParams = {
          blockSize: 17,
          className: legendStyle,
          labelLeft: t(`legend.label.low`),
          labelRight: t(`legend.label.high`),
          t,
        };
        // Create legend
        const legend = LegendComponentFactory(vis, additionalParams);
        // * Final Layer object *
        const tabLayer: ActiveDomainTabLayer = {
          id: vis.visualization.id,
          name: getVisualizationTitle(layer.id, vis.visualization.id),
          icon: `./images/${vis.visualization.title.toLowerCase().replace(/\s/g, '-')}.svg`,
          loading: cachedDataMap.get(vis.visualization.id)?.loading === true,
          legend,
        };
        return tabLayer;
      });
      // Create content for tab
      const content = (
        <ActiveDomainTabContent
          layers={tabLayers}
          selectedIndex={activeMapVisualizationId}
          onClick={handleChangeVisualization}
          interactive
        />
      );
      // ** Create TabOption **
      const tabOption: TabOption = {
        id: layer.id,
        name: getLayerTitle(layer.id),
        icon: getLayerIcon(layer.id),
        content,
        alerting: alertingLayers.includes(layer.id),
      };
      return tabOption;
    });
    // final tab options
    return result;
  }, [
    activeMapVisualizationId,
    alertingLayers,
    allDockedLayers,
    cachedDataMap,
    getLayerTitle,
    getVisualizationTitle,
    handleChangeVisualization,
    t,
  ]);

  // **** RETURN FINAL ELEMENT ****
  return <DemoTabs selected={currentLayerId} options={tabOptions} onClick={handleChangeDomain} className={tabStyle} />;
};

export default ActiveDomainContainer;
