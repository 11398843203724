import LayersUIAction, { LayersUIActionType } from '../actions/layers/LayersUIAction';
import LayersAction, { LayersActionType } from '../actions/layers/LayersAction';
import { CachedData } from '../models/interfaces/api/CachedData';

type LayerId = number;
type VisualizationId = number;

export interface CachedDataState {
  readonly dataMap: Map<LayerId, Map<VisualizationId, CachedData>>;
}

const initialState: CachedDataState = {
  // Map of layer ids: contains a sub-map of paired visualizationids: cachedData
  // dataMap: new Map<LayerId, Map<VisualizationId, CachedData>>([[210, new Map([[1969, imageHeatmapDummyData]])]])
  dataMap: new Map<LayerId, Map<VisualizationId, CachedData>>(),
};

// Empty Cached Data that's loading
const emptyLoadingCachedData: CachedData = {
  visualizationId: -1,
  loading: true,
  rawData: {},
  cacheExpirationDate: undefined,
};

const reducer = (state: CachedDataState = initialState, action: LayersActionType | LayersUIActionType) => {
  switch (action.type) {
    case LayersUIAction.Types.REMOVE_LAYER: {
      const dataMap = new Map(state.dataMap);
      if (dataMap.get(action.payload.id) === undefined) return state;
      dataMap.delete(action.payload.id);
      return { ...state, dataMap };
    }
    case LayersAction.Types.GET_DATA:
    case LayersAction.Types.GET_CUSTOM_DATA: {
      const { layerId, visualizationId } = action.payload;
      let subMap: Map<number, CachedData>;
      const currentCachedData = state.dataMap.get(layerId)?.get(visualizationId);
      if (currentCachedData) {
        subMap = createNewSubMapWithCachedDataAppended(state.dataMap, layerId, visualizationId, {
          ...currentCachedData,
          loading: true,
        });
      } else {
        subMap = createNewSubMapWithCachedDataAppended(state.dataMap, layerId, visualizationId, {
          ...emptyLoadingCachedData,
          visualizationId,
        });
      }
      const newDataMap = new Map(state.dataMap);
      newDataMap.set(layerId, subMap);
      return { ...state, dataMap: newDataMap };
    }
    case LayersAction.Types.GET_DATA_COMPLETED: {
      const { layerId, visualizationId, data, cacheExpirationDate } = action.payload;
      const subMap = createNewSubMapWithCachedDataAppended(state.dataMap, layerId, visualizationId, {
        ...data,
        valid: true,
        loading: false,
        cacheExpirationDate,
      });
      const newDataMap = new Map(state.dataMap);
      newDataMap.set(layerId, subMap);
      return {
        ...state,
        dataMap: newDataMap,
      };
    }
    case LayersAction.Types.GET_DATA_FAILED: {
      const { layerId, visualizationId } = action.payload;
      let submap = state.dataMap.get(layerId);
      if (submap) {
        submap = new Map(submap);
        // set the visualization for the id to be invalid and not loading
        if (!submap.has(visualizationId)) return state;
        submap.set(visualizationId, {
          ...emptyLoadingCachedData,
          visualizationId,
          loading: false,
          valid: false,
          cacheExpirationDate: undefined,
        });
      } else return state;
      const newDataMap = new Map(state.dataMap);
      newDataMap.set(layerId, submap);
      return { ...state, dataMap: newDataMap };
    }
    case LayersAction.Types.GET_DATA_ALREADY_CACHED: {
      const { layerId, visualizationId } = action.payload;
      const currentCachedData = state.dataMap.get(layerId)?.get(visualizationId);
      if (currentCachedData === undefined) return state;
      const subMap = createNewSubMapWithCachedDataAppended(state.dataMap, layerId, visualizationId, {
        ...currentCachedData,
        loading: false,
      });
      const newDataMap = new Map(state.dataMap);
      newDataMap.set(layerId, subMap);
      return { ...state, dataMap: newDataMap };
    }
    default:
      return state;
  }
};

// HELPER FUNCTIONS
//= ====================
const createNewSubMapWithCachedDataAppended = (
  originalDataMap: Map<number, Map<number, CachedData>>,
  layerId: number,
  visualizationId: number,
  data: CachedData
) => {
  let visMap = originalDataMap.get(layerId);
  if (visMap) {
    // Create a copy of the submap and set the data
    visMap = new Map(visMap);
    visMap.set(visualizationId, data);
  } else {
    visMap = new Map([[visualizationId, data]]);
  }
  return visMap;
};

export default reducer;
