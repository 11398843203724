/* eslint-disable no-param-reassign */
/* eslint-disable no-cond-assign */
/* eslint-disable no-return-assign */

export const cubicIn = (t: number) => {
  return t * t * t;
};

export const cubicOut = (t: number) => {
  const p: number = t - 1;
  return p * p * p + 1;
};

export const cubicInOut = (t: number) => {
  return ((t *= 2) <= 1 ? t * t * t : (t -= 2) * t * t + 2) / 2;
};

export const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a;
