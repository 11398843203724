import { css } from 'emotion';

export const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

/**
 * (one of) The parent element should have `position: relevant` property
 */
export const positionCenterStyle = css`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
`;
