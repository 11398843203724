// https://github.com/visgl/deck.gl/blob/master/docs/developer-guide/custom-layers/writing-shaders.md
// This water shader was based upon: https://learnopengl.com/Lighting/Basic-Lighting
const inject = {
  // Vertex Shader
  'vs:#decl': `
    // uniform (per primitive parameters)
    uniform vec2 primaryWavesScrollSpeeds, secondaryWavesScrollSpeeds;
    uniform float time;

    // attribute (per vertex parameter)
    #define WATER_COLOR_ATTRIB waterColors
    attribute vec4 WATER_COLOR_ATTRIB;

    // Vertex output variables
    out vec2 uv;
    out vec3 pos;

    out vec4 waterColor;
    out vec2 primaryWavesUVOffset, secondaryWavesUVOffset;
    out float primaryWavesUVScale, secondaryWavesUVScale;

    out vec3 cameraPosition;
  `,
  'vs:DECKGL_FILTER_GL_POSITION': `
    uv = geometry.worldPosition.xy;
    pos = position.xyz;
  `,
  'vs:DECKGL_FILTER_COLOR': `
    waterColor = WATER_COLOR_ATTRIB / 255.0;
    cameraPosition = project_uCameraPosition;

    primaryWavesUVOffset = time * primaryWavesScrollSpeeds;
    secondaryWavesUVOffset = time * secondaryWavesScrollSpeeds;
  `,
  // Fragment shader
  'fs:#decl': `
    // Constants
    const float shininess = 64.0;
    const float specularStrength = 0.1;
    const vec3 moonDirection = vec3(-0.22100122657010018, 0.9260455097257515, 0.3059381829247281);
    const float normalMapStrength = 1.1;

    // uniform (per primitive parameters)
    uniform sampler2D normalTexture;
    uniform vec2 uvCoordinateOrigin;
    uniform vec3 sunDirection;
    uniform vec3 sunColor, ambientColor;
    uniform float primaryWavesScales, secondaryWavesScales;

    // Fragment input variables
    in vec3 cameraPosition;
    in vec2 primaryWavesUVOffset, secondaryWavesUVOffset;
    in vec2 uv;
    in vec3 pos;
    in vec4 waterColor;
  `,
  'fs:DECKGL_FILTER_COLOR': `
      // Generate UV coordinates for waves
      vec2 primaryUV = uv * primaryWavesScales;
      vec2 secondaryUV = uv * secondaryWavesScales;

      primaryUV = primaryUV + primaryWavesUVOffset;
      secondaryUV = secondaryUV + secondaryWavesUVOffset;

      // Calculate normals
      vec3 primaryNormal =  (texture2D(normalTexture,  primaryUV).rgb * 2.0 - 1.0);
      vec3 secondaryNormal =  (texture2D(normalTexture,  secondaryUV).rgb * 2.0 - 1.0);

      vec3 normal = normalize(primaryNormal + secondaryNormal) * normalMapStrength;

      // Diffuse lighting
      float sunDiff = max(dot(normal, -sunDirection), 0.0);
      // Lighting looks a bit dull at night so we add some additional diffuse with a static 'moon' light
      float moonDiff = max(dot(normal, moonDirection), 0.0);
      vec3 sunDiffuse = (sunDiff * sunColor) / 1.0;
      vec3 moonDiffuse = (moonDiff * sunColor) / 1.0;

      // Specular lighting
      vec3 viewDir  = normalize(cameraPosition - pos);
      vec3 reflectDir = reflect(-sunDirection, normal);

      float spec = pow(max(dot(viewDir, reflectDir), 0.0), shininess);
      vec3 specular = specularStrength * spec * sunColor;

      // Combine lighting to final color
      // Some magic multiplier number below these were tweaked to improve the look
      vec3 lightColor = (ambientColor + sunDiffuse*0.3 + moonDiffuse * .5 + specular) * waterColor.rgb;
      // We use a step for the alpha as sometimes the texture is not loaded in on time giving strange flickering
      color = vec4(lightColor, waterColor.a* step(0.000000001,  texture2D(normalTexture,  primaryUV).r));
    `,
};

export default inject;
