import { Coordinates } from 'viewport-mercator-project';

// eslint-disable-next-line import/prefer-default-export
export const floodIconLocations: Coordinates[] = [
  [4.453175067901611, 51.200241851750214],
  [4.414572715759277, 51.211924412705514],
  [4.4699764251708975, 51.21365839528212],
  [4.359598159790039, 51.20871166777533],
  [4.382429122924805, 51.199838488327956],
];
