import { Layer as DeckGLLayer } from '@deck.gl/core';
import { ScatterplotLayer } from '@deck.gl/layers';
import { Coordinates } from 'viewport-mercator-project';
import { Color } from 'chroma-js';

import { ScatterplotWrapper } from 'models/interfaces/api/ScatterplotWrapper';
import { DeckGLBaseLayerProperties } from 'models/interfaces/deckgl/DeckGLBaseLayerProperties';
import { DeckGLLayerEventHandler } from 'models/interfaces/deckgl/DeckGLLayerEventHandler';
import jmespath from 'jmespath';
import { getColorScaleFromSteppedColorRangeLegend, getColorScaleFromLinearGradientLegend } from './legendColorUtils';
import { CachedData } from '../../models/interfaces/api/CachedData';
import { SteppedColorRangeLegend } from '../../models/interfaces/legends/SteppedColorRangeLegend';
import { LinearGradientLegend } from '../../models/interfaces/legends/LinearGradientLegend';

export interface ScatterPointData {
  label: string;
  location: Coordinates;
  value: number;
}

export default function createScatterplot(
  visualizationWrapper: ScatterplotWrapper,
  cachedData: CachedData,
  currentStepIndex: number,
  layerProperties?: DeckGLBaseLayerProperties<DeckGLLayerEventHandler>
): DeckGLLayer {
  const { visualization, legend } = visualizationWrapper;
  const { mapping } = visualization.dataSources[0];
  const { rawData } = cachedData;
  try {
    const labels = jmespath.search(rawData, mapping.label);
    const locations = jmespath.search(rawData, mapping.location);
    const values = jmespath.search(rawData, mapping.value);

    const data: ScatterPointData[] = labels.map((label: string, i: number) => {
      return {
        label,
        location: locations[i][currentStepIndex],
        value: values[i][currentStepIndex],
      };
    });

    // FIXME: Very dirty way of figuring out which type of legend to use
    const steppedColorLegend = legend as SteppedColorRangeLegend;
    const linearGradientLegend = legend as LinearGradientLegend;
    const useSteppedColor = steppedColorLegend.ranges !== undefined;
    let colorScale: chroma.Scale<Color>;
    if (useSteppedColor) {
      colorScale = getColorScaleFromSteppedColorRangeLegend(steppedColorLegend);
    } else {
      colorScale = getColorScaleFromLinearGradientLegend(linearGradientLegend);
    }

    // Get color from legend
    const getColor = (d: ScatterPointData) => {
      const color = colorScale(d.value).rgba();
      color[3] *= 255;
      return color;
    };

    return new ScatterplotLayer({
      id: `${visualization.title}Layer${visualization.id}`,
      data,
      pickable: true,
      pickingRadius: 30,
      radiusUnits: 'meters',
      radiusScale: 10,
      radiusMinPixels: 8,
      radiusMaxPixels: 20,
      getPosition: (d: ScatterPointData) => d.location,
      getFillColor: getColor,
      tooltipTitle: 'Data Point',
      onHover: layerProperties?.onHover,
      onClick: layerProperties?.onClick,
      autoHighlight: true,
    });
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
