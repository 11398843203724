import { css } from 'emotion';
import { transparentize } from 'polished';

import styleConfig from 'styles/demo/config';

const { colors, borderRadius, boxShadow } = styleConfig;

// eslint-disable-next-line import/prefer-default-export
export const cardStyle = css`
  background-color: ${transparentize(0.1, colors.black)};
  border-radius: ${borderRadius.xl};
  box-shadow: ${boxShadow.md};
`;
