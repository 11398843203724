import { getGPUTier } from 'detect-gpu';

import { LODMode } from '../models/types/LODMode';

export const getOptimalLODMode = async (): Promise<LODMode> => {
  try {
    // to understand the tier classification, see https://github.com/TimvanScherpenzeel/detect-gpu#usage
    const { isMobile, tier, type } = await getGPUTier();
    return type === 'BENCHMARK' && !isMobile && tier >= 2 ? LODMode.LOD2 : LODMode.MAPBOX;
  } catch (e) {
    return LODMode.MAPBOX;
  }
};

export default getOptimalLODMode;
